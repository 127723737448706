<template>
  <slot v-if="$slots.default" />

  <div
    v-if="showObjectivesList"
    :class="{ [`ol-ObjectivesList-${type}`]: type, [$attrs.class]: !!$attrs.class }"
    class="ol-ObjectivesList"
  >
    <VirtualizationList :data="objectives" :with-virtual-scroll="false" data-key="uniqueId">
      <template #default="{ item: objective }">
        <OkrElementsListItem
          :key="objective.uniqueId"
          :ref="data => setReference(objective.id, data)"
          :additional-wrapper-on-value="additionalWrapperOnValue"
          :allow-delete-action="allowDeleteAction"
          :allow-unlink-action="allowUnlinkAction"
          :allowed-depth="allowedDepth"
          :always-show-weight="alwaysShowWeight"
          :border-on-last-row="borderOnLastRow"
          :dim-all-weights="dimAllWeights"
          :dropdown-menu-type="dropdownMenuType"
          :external-actions-listener="externalActionsListener"
          :fetch-child-objectives-on-expand="fetchChildObjectivesOnExpand"
          :has-add-kr-action="hasAddKrAction"
          :has-jira-issue-actions="hasJiraIssueActions"
          :has-unlink-action="hasUnlinkAction"
          :hide-actions="hideActions"
          :hide-weight-icon="hideWeightIcon"
          :highlight-zero-depth="highlightZeroDepth"
          :id-as-link="objectiveReadable(objective)"
          :objective="objective"
          :placement="placement"
          :show-avatar-replacer="showAvatarReplacer"
          :show-current-target="showCurrentTarget"
          :show-grade="showGrade"
          :show-grade-info="showGradeInfo"
          :show-grade-update="showGradeUpdate"
          :show-groups="showGroups"
          :show-interval="showInterval"
          :show-last-comment="showLastComment"
          :show-status="showStatus"
          :show-warning-icon="showWarningIcon"
          :some-item-is-expandable="someItemIsExpandable"
          :tracking-source="trackingSource"
          :transparent-filtered="transparentFiltered"
          :type="itemType"
          :weight-is-dimmed="dimNestedItemsWeights"
          @expand-item="onExpandItem"
          @set-expand="onSetExpandForItem"
          @okr-element-deleted="onOkrElementDeleted"
          @update-elements="$emit('update-elements')"
          @edit-element="$emit('edit-element', $event)"
          @link-jira-issue="$emit('link-jira-issue', $event)"
          @create-jira-issue="$emit('create-jira-issue', $event)"
          @create-key-result="$emit('create-key-result', $event)"
          @on-menu-item-click="$emit('on-menu-item-click', $event)"
        />
      </template>
    </VirtualizationList>
  </div>

  <slot name="no-objectives" />
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent, handleError } from 'vue'

import ObjectivesInfoApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import { TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import {
  LIST_ITEM_PLACEMENTS,
  LIST_ITEM_TYPES
} from '@/utils/components-configurations/okr-elements-list-item'
import { currentUserCanReadObjective, OBJECTIVE_SORT_OPTIONS } from '@/utils/objectives'

import OkrElementsListItem from '@/components/objectives/okr-elements-list/OkrElementsListItem'
import VirtualizationList from '@/components/VirtualizationList'

const TYPES = {
  DEFAULT: 'default',
  DEFAULT_NEXT: 'default-next'
}

//       :ref="data => setReference(objective.id, data)"

export default defineComponent({
  name: 'OkrElementsList',
  components: { VirtualizationList, OkrElementsListItem },

  inheritAttrs: false,
  props: {
    objectives: {
      type: Array,
      required: true
    },

    showStatus: {
      type: Boolean
    },

    showGroups: {
      type: Boolean
    },

    showWarningIcon: {
      type: Boolean
    },

    showInterval: {
      type: Boolean
    },

    showGradeInfo: {
      type: Boolean
    },

    hasUnlinkAction: {
      type: Boolean
    },

    hasAddKrAction: {
      type: Boolean
    },

    hasJiraIssueActions: {
      type: Boolean,
      default: true
    },

    externalActionsListener: {
      type: Boolean
    },

    allowedDepth: {
      // calculated from 0 so if we need 2-levels depth we should set 1
      type: Number,
      default: Infinity,
      validator: v => v === Infinity || (Number.isSafeInteger(v) && Number.isFinite(v) && v >= 0)
    },

    fetchChildObjectivesOnExpand: {
      type: Boolean
    },

    transparentFiltered: {
      type: Boolean
    },

    hideActions: {
      type: Boolean
    },

    showGrade: {
      type: Boolean,
      default: true
    },

    noLinkId: {
      type: Boolean
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    },

    itemType: {
      type: String,
      default: LIST_ITEM_TYPES.PRIMARY,
      validator: v => Object.values(LIST_ITEM_TYPES).includes(v)
    },

    someItemIsExpandable: {
      type: Boolean
    },

    dimNestedItemsWeights: {
      type: Boolean
    },

    alwaysShowWeight: {
      type: Boolean
    },

    showCurrentTarget: {
      type: Boolean
    },

    hideWeightIcon: {
      type: Boolean
    },

    dimAllWeights: {
      type: Boolean
    },

    showAvatarReplacer: {
      type: Boolean
    },

    highlightZeroDepth: {
      type: Boolean
    },

    additionalWrapperOnValue: {
      type: Boolean
    },

    borderOnLastRow: {
      type: Boolean
    },

    // withVirtualScroll: {
    //   type: Boolean
    // },

    showLastComment: {
      type: Boolean
    },

    showGradeUpdate: {
      type: Boolean
    },

    allowUnlinkAction: {
      type: Boolean,
      default: true
    },

    allowDeleteAction: {
      type: Boolean,
      default: true
    },

    trackingSource: {
      type: String,
      default: TRACKING_UNKNOWN
    },

    placement: {
      type: String,
      default: LIST_ITEM_PLACEMENTS.UNKNOWN
    }
  },

  emits: {
    'expand-item': null,
    'update-elements': null,
    'link-jira-issue': null,
    'create-jira-issue': null,
    'on-menu-item-click': null,
    'okr-element-deleted': null,
    'edit-element': null,
    'create-key-result': null
  },

  data() {
    return {
      expandedElementsStates: {},
      references: {}
    }
  },

  computed: {
    dropdownMenuType() {
      return this.itemType === LIST_ITEM_TYPES.PRIMARY_NEXT
        ? DROPDOWN_MENU_TYPES.DEFAULT_NEXT
        : DROPDOWN_MENU_TYPES.DEFAULT
    },

    showObjectivesList() {
      return !isEmpty(this.objectives)
    },

    onlyExpandedElements() {
      return Object.fromEntries(
        Object.entries(this.expandedElementsStates).filter(([, state]) => state)
      )
    }
  },

  beforeUpdate() {
    this.references = {}
  },

  methods: {
    objectiveReadable(objective) {
      if (this.noLinkId) {
        return false
      }
      return currentUserCanReadObjective(objective)
    },

    onSetExpandForItem({ id, value }) {
      this.expandedElementsStates[id] = value
    },

    onOkrElementDeleted(elementId) {
      delete this.expandedElementsStates[elementId]
      this.$emit('okr-element-deleted')
    },

    onExpandItem(data) {
      const { parentId } = data
      if (parentId) {
        this.$emit('expand-item', data)
        this.expandedElementsStates[parentId] = true
      } else {
        this.expandedElementsStates[data] = false
      }
    },

    /** @public */
    toggleExpandAll(value = true) {
      if (!isEmpty(this.references)) {
        Object.entries(this.references).forEach(async ([id, ref]) => {
          await ref.toggleExpand(id, value)
        })
      }
    },

    setReference(elementId, reference) {
      if (reference) {
        this.references[elementId] = reference
      }
    },

    /** @public */
    async refetchExpandedElementsChildItems() {
      const idsForRefetch = Object.keys(this.onlyExpandedElements).map(id => Number(id))

      const payloads = idsForRefetch.map(parentId => {
        const targetObjective = this.objectives.find(objective => objective.id === parentId)
        return {
          parentId,
          workspaceId: this.$route.params.workspaceId,
          intervalIds:
            this.placement === LIST_ITEM_PLACEMENTS.OKR_ELEMENT_FORM
              ? undefined
              : [targetObjective.intervalId],
          searchType: SEARCH_TYPES.OKR_EXPLORER,
          order: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
          childOrder: [OBJECTIVE_SORT_OPTIONS.ORDER_ASC],
          offset: 0,
          limit: 1000
        }
      })

      if (!isEmpty(payloads)) {
        const api = new ObjectivesInfoApiHandler()

        try {
          const results = await Promise.all(
            payloads.map(async payload => {
              const { items } = await api.getChildObjectives(payload)
              return {
                parentId: payload.parentId,
                items
              }
            })
          )
          results.forEach(result => {
            this.$emit('expand-item', result)
          })
        } catch (error) {
          handleError({ error })
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.ol-ObjectivesList {
  overflow: hidden;

  &-default {
    border: 1px solid $azure-medium;
    border-radius: $border-radius-md;
  }
}
</style>
