<template>
  <nav class="amn-Nav">
    <AppMenuNavList
      :disabled="disabled"
      :items="menuItemsByPlacement"
      :layout="layout"
      @set-active-additional-menu="setActiveAdditionalMenu"
      @item-click="onMenuItemClick"
    >
      <template #list-prepend>
        <slot name="list-prepend" />
      </template>
    </AppMenuNavList>
    <transition name="slide">
      <AdditionalMenu
        v-if="showAdditionalMenu"
        :disabled="disabled"
        :item="itemForAdditionalMenu"
        @close-additional-menu="closeAdditionalMenu"
        @item-click="onAdditionalMenuItemClick"
      />
    </transition>
  </nav>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES, EVENT_SECTIONS } from '@/tracking/amplitude-helpers'
import { APP_MENU_NAV_LAYOUTS, NAV_ITEM_PLACEMENTS } from '@/utils/app-menu'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import {
  menuItemsNames,
  menuItemsNamesAllowedOnlyForPluginAdmin,
  menuItemsNamesAllowedOnlyForEverybody,
  RELEASE_UPDATES_MENU_ITEM_NAME,
  menuItemsNamesAllowedOnlyForOwner,
  SUPPORT_MENU_ITEM_NAME
} from '@/utils/menu-items'
import { HOMEPAGE_OKR_SEARCH_TYPE_IDS, OKR_VIEW_PAGES_IDS } from '@/utils/objectives'
import { FILTER_PRESETS } from '@/utils/okr-elements/filters'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import AdditionalMenu from '@/components/AppMenu/AdditionalMenu'
import AppMenuNavList from '@/components/AppMenu/AppMenuNavList'
import { DASHBOARD as DASHBOARD_PLACEMENT, FILTERS } from '@/components/AppMenu/props-validators'

const SAVED_FILTERS_DISPLAY_COUNT = 3
const SAVED_DASHBOARD_DISPLAY_COUNT = 3

const { TOP, BOTTOM } = NAV_ITEM_PLACEMENTS

export default defineComponent({
  name: 'AppMenuNav',

  components: { AdditionalMenu, AppMenuNavList },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isCrossPlatformApp: {
      from: isCrossPlatformAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    placement: {
      type: String,
      default: TOP,
      validator: v => Object.values(NAV_ITEM_PLACEMENTS).includes(v)
    },

    layout: {
      type: String,
      default: APP_MENU_NAV_LAYOUTS.VERTICAL,
      validator: v => Object.values(APP_MENU_NAV_LAYOUTS).includes(v)
    },

    disabled: {
      type: Boolean
    }
  },

  emits: { 'update:someAdditionalMenuOpened': null, 'item-click': null },

  data() {
    return {
      currentActiveAdditionalMenu: null
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    }),

    ...mapState('system', {
      userData: state => state.userData
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin,
      isOwner: state => state.isOwner
    }),

    ...mapGetters('system', {
      userIsAdmin: 'userIsAdmin',
      showDashboardItem: 'showDashboardItem',
      dashboardsVisibility: 'dashboardsVisibility',
      userGroupsByWorkspace: 'userGroupsByWorkspace',
      userRoleForTracking: 'userRoleForTracking',
      homePageLastSelectedSearchType: 'searchTypeId'
    }),

    ...mapState('dashboard', {
      favoriteDashboards: state =>
        state.favoriteList
          .filter(dashboard => dashboard.favorite)
          .map(dashboard => ({
            ...dashboard,
            // eslint-disable-next-line frabbit-vue/vue-no-unused-vuex-properties
            showNativeTooltip: true
          }))
    }),

    favoriteList() {
      const { showCustomDashboardForUsers } = this.dashboardsVisibility

      if (!showCustomDashboardForUsers) {
        return []
      }

      return this.favoriteDashboards
    },

    showMoreFiltersButton() {
      return this.normalizedFilters.length > SAVED_FILTERS_DISPLAY_COUNT
    },

    showMoreDashboardButton() {
      return this.favoriteList.length > SAVED_DASHBOARD_DISPLAY_COUNT
    },

    filtersForDisplay() {
      // return this.normalizedFilters.slice(0, SAVED_FILTERS_DISPLAY_COUNT).map(item => {
      // return {
      // ...item,
      // hasActionsMenu: true,
      // placement: FILTERS
      // }
      // })

      const filters = this.normalizedFilters.slice(0, SAVED_FILTERS_DISPLAY_COUNT).map(item => {
        return {
          ...item,
          hasActionsMenu: true,
          placement: FILTERS
        }
      })

      if (this.showMoreFiltersButton) {
        return [
          ...filters,
          {
            showMoreButton: true,
            id: 'show-more-filters',
            name: this.$t('action.load_more'),
            dropdownItems: this.restFilters,
            placement: FILTERS
          }
        ]
      }

      return filters
    },

    dashboardsForDisplay() {
      // return [
      //   ...this.favoriteList.slice(0, SAVED_DASHBOARD_DISPLAY_COUNT).map(item => {
      //     return {
      //       id: item.id,
      //       name: item.name,
      //       path: `/workspaces/${this.workspaceId}/dashboard/custom-dashboard/item/${item.id}`,
      //       hasActionsMenu: true,
      //       customDashboard: true,
      //       placement: DASHBOARD_PLACEMENT
      //     }
      //   })
      // ]

      const dashboards = [
        ...this.favoriteList.slice(0, SAVED_DASHBOARD_DISPLAY_COUNT).map(item => {
          return {
            id: item.id,
            name: item.name,
            showNativeTooltip: item.showNativeTooltip,
            path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD_ITEM}/${item.id}`,
            hasActionsMenu: true,
            customDashboard: true,
            placement: DASHBOARD_PLACEMENT
          }
        })
      ]

      if (this.showMoreDashboardButton) {
        return [
          ...dashboards,
          {
            showMoreButton: true,
            id: 'show-more-dashboards',
            name: this.$t('action.load_more'),
            dropdownItems: this.restDashboards,
            placement: DASHBOARD_PLACEMENT
          }
        ]
      }

      return dashboards
    },

    restFilters() {
      return this.normalizedFilters.slice(SAVED_FILTERS_DISPLAY_COUNT)
    },

    restDashboards() {
      return this.favoriteList.slice(SAVED_DASHBOARD_DISPLAY_COUNT)
    },

    savedFilters() {
      return this.$store.state.okrFilters.savedFilters
    },

    normalizedFilters() {
      return this.savedFilters.map(filter => {
        const { id, name, filters, hasUnexpectedCustomFields } = filter
        return {
          id,
          name,
          filters,
          hasUnexpectedCustomFields,
          customFilter: true,
          query: { [FILTER_PRESET_KEY]: `${id}` },
          showNativeTooltip: true
          // path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE}`
        }
      })
    },

    itemForAdditionalMenu() {
      const { currentActiveAdditionalMenu, menuItemsByPlacement } = this
      if (currentActiveAdditionalMenu) {
        return menuItemsByPlacement.find(item => item.name === currentActiveAdditionalMenu)
      } else {
        return {}
      }
    },

    showAdditionalMenu() {
      return this.somMenuItemHasAdditionalMenu && this.currentActiveAdditionalMenu
    },

    somMenuItemHasAdditionalMenu() {
      return this.menuItemsByPlacement.some(item => item.showChildrenLikeAdditionalMenu)
    },

    menuItems() {
      const { workspaceId } = this
      const { HOME, OBJECTIVES, DASHBOARD, SETTINGS } = menuItemsNames
      const OBJECTIVES_CHILDREN = menuItemsNames.OBJECTIVES.children
      const SETTINGS_CHILDREN = menuItemsNames.SETTINGS.children
      const MY_GROUPS_OKR_ITEM_ID = 1

      const { WORKSPACES, OBJECTIVES: OBJECTIVES_ROUTE_PATH } = ROUTE_PATHS
      const WORKSPACE_ID_PATH = `${WORKSPACES}${workspaceId}`
      const ALIGNMENT_TABLE_PATH = `${OBJECTIVES_ROUTE_PATH}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE}`
      const SETTINGS_ROUTE_PATH = `${WORKSPACES}${ROUTE_PATHS.SETTINGS}`

      const PERSONAL_SETTINGS_PAGE_PATH = `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_USER_PREFERENCES}`
      const WORKSPACES_SETTINGS_PAGE_PATH = `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_WORKSPACES}`

      const defaultMenuItems = [
        {
          id: 0,
          name: HOME.name,
          icon: 'menu-home',
          label: 'menu.home',
          animated: true,
          path: WORKSPACE_ID_PATH,
          placement: TOP
        },
        {
          id: 1,
          name: OBJECTIVES.name,
          icon: 'menu-objectives',
          label: 'menu.objectives',
          animated: true,
          path: `${WORKSPACE_ID_PATH}/${OBJECTIVES_ROUTE_PATH}`,
          placement: TOP,
          children: [
            {
              id: 0,
              name: OBJECTIVES_CHILDREN.MY_OKR.name,
              label: 'menu.my_okr',
              path: `${WORKSPACE_ID_PATH}/${ALIGNMENT_TABLE_PATH}`,
              query: { [FILTER_PRESET_KEY]: FILTER_PRESETS.MY_OKR }
            },
            {
              id: MY_GROUPS_OKR_ITEM_ID,
              name: OBJECTIVES_CHILDREN.MY_GROUP_OKR.name,
              label: 'menu.my_groups',
              path: `${WORKSPACE_ID_PATH}/${ALIGNMENT_TABLE_PATH}`,
              query: { [FILTER_PRESET_KEY]: FILTER_PRESETS.MY_GROUP }
            },
            ...this.filtersForDisplay
          ]
        },
        {
          id: 2,
          name: DASHBOARD.name,
          label: 'menu.dashboard',
          icon: 'menu-dashboard',
          animated: true,
          // hasBottomDivider: true,
          path: `${WORKSPACE_ID_PATH}/${ROUTE_PATHS.DASHBOARD}/`,
          children: this.dashboardsForDisplay,
          placement: TOP
        },
        {
          id: 3,
          name: RELEASE_UPDATES_MENU_ITEM_NAME,
          label: 'menu.whats_new',
          icon: 'menu-release-updates',
          animated: true,
          hasExternalClickListener: true,
          placement: BOTTOM
        },
        {
          id: 4,
          // name: SUPPORT.name,
          name: SUPPORT_MENU_ITEM_NAME,
          label: 'menu.support',
          icon: 'menu-support',
          animated: true,
          // path: `/${ROUTE_PATHS.SUPPORT}`,
          hasExternalClickListener: true,
          placement: BOTTOM
        },
        {
          id: 5,
          name: SETTINGS.name,
          label: 'menu.settings',
          icon: 'menu-settings',
          animated: true,
          showBadgeOnIcon: this.isPluginAdmin,
          // hasBottomDivider: true,
          showChildrenLikeAdditionalMenu: true,
          placement: BOTTOM,
          thisAdditionalMenuOpened: this.currentActiveAdditionalMenu === SETTINGS.name,
          path: this.userData?.hasAccessToWorkspaceSettingsPage
            ? WORKSPACES_SETTINGS_PAGE_PATH
            : PERSONAL_SETTINGS_PAGE_PATH,

          children: [
            {
              id: 0,
              name: SETTINGS_CHILDREN.USER_PREFERENCES.name,
              label: 'menu.personal_settings',
              icon: 'menu-personal-settings',
              path: PERSONAL_SETTINGS_PAGE_PATH
            },
            {
              id: 1,
              name: SETTINGS_CHILDREN.NOTIFICATIONS_SETTINGS.name,
              label: 'in_app_notifications.title',
              icon: 'notifications',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_NOTIFICATIONS}`,
              hasBottomDivider: true
            },
            // {
            //   id: 2,
            //   name: SETTINGS_CHILDREN.SECURITY_SETTINGS.name,
            //   label: 'menu.security',
            //   icon: 'menu-security',
            //   path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_SECURITY}`,
            //   comingSoon: true
            // },
            {
              id: 3,
              name: SETTINGS_CHILDREN.WORKSPACES.name,
              label: 'menu.workspaces',
              icon: 'menu-workspaces',
              description: 'menu.workspaces.description',
              path: WORKSPACES_SETTINGS_PAGE_PATH
            },
            {
              id: 4,
              name: SETTINGS_CHILDREN.ORGANIZATION.name,
              label: 'menu.organization',
              icon: 'menu-groups',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.ORGANIZATION}`,
              addRouterActiveClassFromRouterLinkComponent: true
            },
            {
              id: 5,
              name: SETTINGS_CHILDREN.COMPANY_SETTINGS.name,
              label: 'menu.okr_general',
              icon: 'menu-company-settings',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_COMPANY}`
            },

            {
              id: 6,
              name: SETTINGS_CHILDREN.STATUSES.name,
              label: 'filter.statuses',
              icon: 'menu-statuses',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_STATUSES}`
            },

            {
              id: 7,
              name: SETTINGS_CHILDREN.ELEMENT_TYPES.name,
              label: 'levels.header_title',
              icon: 'menu-okr-levels',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_ELEMENT_TYPES}`
            },
            {
              id: 8,
              name: SETTINGS_CHILDREN.LABELS.name,
              label: 'field.labels.title',
              icon: 'menu-labels',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_LABELS}`
            },
            {
              id: 9,
              name: SETTINGS_CHILDREN.CUSTOM_FIELDS.name,
              label: 'menu.custom_fields',
              icon: 'menu-custom-fields',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_CUSTOM_FIELDS}`
            },
            {
              id: 10,
              name: SETTINGS_CHILDREN.CHECK_INS.name,
              icon: 'menu-check-ins',
              label: 'menu.check_ins',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_CHECK_INS}`
            },
            {
              id: 11,
              name: SETTINGS_CHILDREN.SYNCHRONIZATION_SETTINGS.name,
              label: 'menu.synchronization_settings',
              icon: 'menu-sync-settings',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_SYNCHRONIZATION}`
            },

            {
              id: 12,
              name: SETTINGS_CHILDREN.BILLING.name,
              label: 'menu.billing',
              icon: 'menu-billing',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_BILLING}`,
              addRouterActiveClassFromRouterLinkComponent: true
            },

            // {
            //   id: 13,
            //   name: SETTINGS_CHILDREN.BRANDING.name,
            //   label: 'menu.branding',
            //   icon: 'menu-branding',
            //   path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_BRANDING}`
            // },

            {
              id: 14,
              name: SETTINGS_CHILDREN.INTEGRATIONS.name,
              label: 'menu.integrations',
              icon: 'menu-integrations',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.SETTINGS_INTEGRATIONS}`,
              addRouterActiveClassFromRouterLinkComponent: true
            },
            {
              id: 14,
              name: SETTINGS_CHILDREN.AUDIT_LOGS.name,
              label: 'menu.audit_logs',
              icon: 'menu-audit-logs',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.AUDIT_LOGS}`,
              addRouterActiveClassFromRouterLinkComponent: true
            },
            {
              id: 15,
              name: SETTINGS_CHILDREN.BIN.name,
              label: 'menu.bin',
              icon: 'delete-next',
              path: `${SETTINGS_ROUTE_PATH}/${ROUTE_PATHS.BIN}`
            }
          ]
            .filter(c => {
              const resolvedRouteName = this.$router.resolve(c.path).name

              // remove all routes that are leading to the missing page
              // because they are not accessible in the routes array
              return resolvedRouteName !== ROUTE_NAMES.MISSING_PAGE
            })
            .map(c => ({ ...c, animated: true }))
        }
      ]

      if (isEmpty(this.userGroupsByWorkspace(workspaceId))) {
        const objectivesMenuItem = defaultMenuItems.find(item => item.name === OBJECTIVES.name)
        if (objectivesMenuItem) {
          objectivesMenuItem.children = objectivesMenuItem.children.filter(
            item =>
              item.name !== OBJECTIVES_CHILDREN.MY_GROUP_OKR.name &&
              item.id !== MY_GROUPS_OKR_ITEM_ID
          )
        }
      }

      const filteredMenuItems = this.isPluginAdmin
        ? defaultMenuItems
        : defaultMenuItems.map(item => {
            if (item.children) {
              return {
                ...item,
                children: item.children.filter(children => {
                  if (children.name === SETTINGS_CHILDREN.WORKSPACES.name) {
                    return this.userData?.hasAccessToWorkspaceSettingsPage
                  }
                  if (children.name === SETTINGS_CHILDREN.ORGANIZATION.name) {
                    return this.userData?.hasAccessToGlobalGroupsPage
                  }
                  return !menuItemsNamesAllowedOnlyForPluginAdmin.includes(children.name)
                })
              }
            } else {
              return item
            }
          })

      const filteredMenuItemsByDashboard = this.showDashboardItem
        ? filteredMenuItems
        : filteredMenuItems.filter(item => item.name !== DASHBOARD.name)

      const filteredMenuItemsByOwner = this.isOwner
        ? filteredMenuItemsByDashboard
        : filteredMenuItemsByDashboard.map(item => {
            if (item.children && item.name === SETTINGS.name) {
              return {
                ...item,
                children: item.children.filter(
                  children => !menuItemsNamesAllowedOnlyForOwner.includes(children.name)
                )
              }
            }
            return item
          })

      return this.userIsAdmin
        ? filteredMenuItemsByOwner
        : filteredMenuItemsByOwner.map(item => {
            if (item.children && item.name === SETTINGS.name) {
              return {
                ...item,
                children: item.children.filter(children =>
                  menuItemsNamesAllowedOnlyForEverybody.includes(children.name)
                )
              }
            }
            return item
          })
    },

    menuItemsByPlacement() {
      return this.menuItems.filter(item => item.placement === this.placement)
    }
  },

  watch: {
    currentActiveAdditionalMenu: {
      handler(newValue) {
        this.$emit('update:someAdditionalMenuOpened', !!newValue)
      },

      immediate: true
    },

    $route: {
      handler() {
        if (this.somMenuItemHasAdditionalMenu) {
          this.toggleAdditionalMenu()
        }
      },

      immediate: true
    }
  },

  mounted() {
    this.toggleAdditionalMenu()
  },

  methods: {
    onFilterSelect(id) {
      this.$router.push({
        path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE}`,
        query: { [FILTER_PRESET_KEY]: id }
      })
    },

    onDashboardSelect(id) {
      this.$router.push({
        path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD_ITEM}/${id}`
      })
    },

    onAdditionalMenuItemClick(item) {
      const SETTINGS_CHILDREN = menuItemsNames.SETTINGS.children
      const trackerData = {
        category: EVENT_CATEGORIES.SETTINGS,
        subCategory: this.$t(item.label, 1, { locale: 'en' }),
        role: this.userRoleForTracking
      }

      const defaultTabByItemName = {
        [SETTINGS_CHILDREN.ORGANIZATION.name]: this.$t('filter.all_groups', 1, { locale: 'en' }),
        [SETTINGS_CHILDREN.USER_PREFERENCES.name]: this.$t('in_app_notifications.title', 1, {
          locale: 'en'
        }),

        [SETTINGS_CHILDREN.SYNCHRONIZATION_SETTINGS.name]: this.$t(
          'settings.synchronization.general_settings',
          1,
          { locale: 'en' }
        ),

        [SETTINGS_CHILDREN.INTEGRATIONS.name]: 'connection'
      }

      if (Object.keys(defaultTabByItemName).includes(item.name)) {
        trackerData.tab = defaultTabByItemName[item.name]
      }

      tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
    },

    onMenuItemClick(item) {
      const { id } = item
      if (item.placement === DASHBOARD_PLACEMENT) {
        this.onDashboardSelect(id)
      } else if (item.hasExternalClickListener) {
        this.$emit('item-click', item)
      } else {
        if (item.customFilter && Number(this.$route.query[FILTER_PRESET_KEY]) !== id) {
          this.onFilterSelect(id)
        }
      }

      const trackerData = {
        category: EVENT_CATEGORIES.MAIN_NAVIGATION,
        section: item.name
      }
      const OBJECTIVES_CHILDREN = menuItemsNames.OBJECTIVES.children
      if (trackerData.section === menuItemsNames.HOME.name) {
        const tabNames = {
          [HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES]: 'obj progress',
          [HOMEPAGE_OKR_SEARCH_TYPE_IDS.KEY_RESULTS]: 'kr progress'
        }

        trackerData.tab =
          tabNames[this.homePageLastSelectedSearchType] ||
          tabNames[HOMEPAGE_OKR_SEARCH_TYPE_IDS.OBJECTIVES]
      }

      if (
        trackerData.section === OBJECTIVES_CHILDREN.MY_OKR.name ||
        trackerData.section === OBJECTIVES_CHILDREN.MY_GROUP_OKR.name
      ) {
        trackerData.section = EVENT_SECTIONS.OBJECTIVES
        trackerData.label = this.$t(item.label, 1, { locale: 'en' })
      }

      if (trackerData.section === EVENT_SECTIONS.OBJECTIVES) {
        trackerData.view = OKR_VIEW_PAGES_IDS.ALIGNMENT
      }

      if (item.customFilter) {
        trackerData.label = item.name
        trackerData.section = EVENT_SECTIONS.OBJECTIVES
      }

      if (item.customDashboard) {
        trackerData.label = item.name
        trackerData.section = EVENT_SECTIONS.DASHBOARD
      }

      tracker.logEvent(EVENT_NAMES.SECTION_OPENED, trackerData)
    },

    async toggleAdditionalMenu() {
      await this.$router.isReady()
      const { path: currentPath } = this.$route
      const menuItemsWithChilds = this.menuItemsByPlacement.filter(
        item => item.children && item.showChildrenLikeAdditionalMenu
      )
      if (!isEmpty(menuItemsWithChilds)) {
        const activeParentMenuItem = menuItemsWithChilds.find(item => {
          return item.children.some(child => {
            return child.addRouterActiveClassFromRouterLinkComponent
              ? currentPath.includes(child.path)
              : child.path === currentPath
          })
        })
        if (activeParentMenuItem) {
          this.currentActiveAdditionalMenu = activeParentMenuItem.name
        } else {
          this.currentActiveAdditionalMenu = null
        }
      }
    },

    setActiveAdditionalMenu(menuItemName) {
      this.currentActiveAdditionalMenu = menuItemName
    },

    closeAdditionalMenu() {
      this.currentActiveAdditionalMenu = null
    }
  }
})
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: transform $menu-transition;
}

.slide-leave-active {
  transition: transform $menu-transition;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
