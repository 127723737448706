<template>
  <div class="lt-Loader">
    <div v-for="row in ROWS" :key="row.id" class="lt-Loader_Row">
      <div class="lt-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :size="XS" :width="row.name" />
      </div>

      <div class="lt-Cell">
        <SkeletonItem :size="XS" :width="row.category" />
      </div>

      <div class="lt-Cell">
        <SkeletonItem
          v-for="n in row.ws"
          :key="n"
          :appearance="SQUARE"
          :size="SM"
          border-radius="6px"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'LevelsTableLoader'
})
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { SQUARE } = SKELETON_APPEARANCES

const { XS, SM } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = [
  {
    id: 0,
    name: '75px',
    category: '61px',
    ws: 4
  },
  {
    id: 1,
    name: '120px',
    category: '61px',
    ws: 1
  },
  {
    id: 2,
    name: '80px',
    category: '61px',
    ws: 2
  },
  {
    id: 3,
    name: '260px',
    category: '41px',
    ws: 1
  },
  {
    id: 4,
    name: '20px',
    category: '41px',
    ws: 3
  }
]
</script>

<style lang="scss" scoped>
.lt-Loader_Row {
  height: 44px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.lt-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    gap: 8px;
    flex: 1 1 auto;
  }

  &:nth-child(2) {
    flex: 0 0 244px;
    width: 244px;
  }

  &:last-child {
    gap: 4px;
    width: 134px;
    flex: 0 0 134px;
    margin-right: 24px;
  }
}
</style>
