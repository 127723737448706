<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { ELEMENT_TYPES_CATEGORIES } from '@/utils/okr-levels'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ElementTypeCategorySelect'
})

defineProps({
  disabled: {
    type: Boolean
  }
})

const model = defineModel({
  type: Array,
  required: true,
  set: value => value
})

const { t } = useI18n()

const options = computed(() => {
  return Object.values(ELEMENT_TYPES_CATEGORIES).map(item => {
    return {
      ...item,
      label: t(item.name)
    }
  })
})
</script>

<template>
  <FormFieldNext
    :disabled="disabled"
    :label="t('levels.element_category')"
    data-auto-testid="manage-level-category"
  >
    <AppSelect
      v-model="model"
      :disabled="disabled"
      :item-value="OKR_LEVEL_ENTITY_KEYS.TYPE_ID"
      :offset="[0, 0]"
      :options="options"
      :type="SELECT_TYPES.MODERN"
      class="etc-Select"
    />

    <AppInfoMessage v-if="$slots.description" :type="INFO_MESSAGE_TYPES.NONE">
      <slot name="description" />
    </AppInfoMessage>
  </FormFieldNext>
</template>

<style lang="scss" scoped></style>
