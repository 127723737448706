<template>
  <div class="dob-Page">
    <div v-if="showError" class="dob-Page_Error">
      {{ $t('jira.dashboard.access.error') }}
    </div>
    <ObjectiveListPage
      v-else
      ref="list"
      :filter-values="savedConfigFilters"
      :user-can-create-objectives="false"
      :view="view"
      :workspace-id="savedConfigFilters.workspaceId"
      hide-portals
      hide-toolbar
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      stop-scroll-loader-after-first-load
      @show-error="handleError"
    >
      <ObjectiveTable
        :filter-values="savedConfigFilters"
        :initial-columns="INITIAL_COLUMNS"
        :show-filter-buttons="searchTypAlignment"
        :table-row-grade-configurable="false"
        :transparent-filtered="searchTypAlignment"
        :user-can-create-objectives="false"
        :workspace-id="savedConfigFilters.workspaceId"
        disable-horizontal-scroll
        disable-rows-drag
        disable-save-filter-values
        edit-external
        head-sticky-offset="0"
        hide-bulk-actions
        hide-expand-collapse-button
        hide-head-cells-sort-icons
        hide-okr-element-rename
        hide-table-header-actions
        load-objectives-on-start
        no-padding-bottom
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        @load-more="onLoadMore"
        @reset-search-criteria="goToConfig"
      />
    </ObjectiveListPage>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { SEARCH_TYPES } from '@/api/okr-elements'
import { COLUMNS_SETTINGS } from '@/utils/objective-table'
import { OKR_VIEW_PAGES } from '@/utils/objectives'

import ObjectiveListPage from '@/components/objectives/ObjectiveListPage'
import ObjectiveTable from '@/components/objectives/table/ObjectiveTable'

const VIEW_BY_SEARCH_TYPE = {
  [SEARCH_TYPES.OKR_EXPLORER]: OKR_VIEW_PAGES.OKREXPLORER,
  [SEARCH_TYPES.ALIGNMENT]: OKR_VIEW_PAGES.ALIGNMENT
}

const INITIAL_COLUMNS = [
  COLUMNS_SETTINGS.NAME.key,
  COLUMNS_SETTINGS.CURRENT_TARGET.key,
  COLUMNS_SETTINGS.GRADE.key
]

export default defineComponent({
  name: 'DashboardOkrBoard',
  components: {
    ObjectiveListPage,
    ObjectiveTable
  },

  props: {
    savedConfigFilters: {
      type: Object,
      default: () => ({})
    }
  },

  emits: { 'go-to-config': null },

  data() {
    return {
      showError: false
    }
  },

  computed: {
    INITIAL_COLUMNS: () => INITIAL_COLUMNS,

    view() {
      return VIEW_BY_SEARCH_TYPE[this.savedConfigFilters.searchType] || OKR_VIEW_PAGES.ALIGNMENT
    },

    searchTypAlignment() {
      return this.savedConfigFilters.searchType === SEARCH_TYPES.ALIGNMENT
    }
  },

  methods: {
    goToConfig() {
      this.$emit('go-to-config')
    },

    onLoadMore(eventData) {
      this.$refs.list.loadMore(eventData)
    },

    handleError() {
      this.showError = true
    }
  }
})
</script>

<style lang="scss" scoped>
.dob-Page {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  &_Error {
    text-align: center;
    font-size: $fs-24;
    font-weight: fw('medium');
    margin: 40px auto;
  }
}
</style>
