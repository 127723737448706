<template>
  <PersonalStatisticsOkrElementsList
    v-for="list in lists"
    :key="list.id"
    :ref="setReference"
    :edit-external="editExternal"
    :hide-actions="hideActions"
    :list-type="list.listType"
    :predefined-workspace-id="workspaceId"
    :search-type-id="searchTypeId"
    :selected-dates="selectedDates"
    :selected-group-name="selectedGroupName"
    :selected-intervals="selectedIntervals"
    :selected-user-name="selectedUserName"
    :selected-user-or-group="selectedUserOrGroup"
    :show-last-comment="showLastComment"
    :stakeholders-grade="stakeholdersGrade"
    :user-selected="userSelected"
    @okr-element-deleted="onOkrElementDeleted"
    @edit-element="$emit('edit-element', $event)"
    @link-jira-issue="linkJiraIssue($options.VIEWS.LINK_JIRA_ISSUE, $event)"
    @create-jira-issue="$emit('create-jira-issue', $event)"
    @create-key-result="$emit('create-key-result', $event)"
  />
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { USER_LIST_TYPES } from '@/utils/homepage'
import { OKR_FORM_VIEWS } from '@/utils/objectives'
import { stringOrNullProp } from '@/utils/prop-validators'

import PersonalStatisticsOkrElementsList from '@/components/personal-statistics/PersonalStatisticsOkrElementsList'

export default defineComponent({
  name: 'PersonalStatisticsOkrElements',
  components: { PersonalStatisticsOkrElementsList },
  inheritAttrs: false,
  props: {
    selectedIntervals: {
      type: Array,
      required: true
    },

    selectedDates: {
      type: Object,
      required: true
    },

    selectedUserOrGroup: {
      type: [String, Number],
      required: true
    },

    userSelected: {
      type: Boolean
    },

    workspaceId: {
      type: Number,
      default: null
    },

    editExternal: {
      type: Boolean
    },

    selectedUserName: {
      default: '',
      validator: v => stringOrNullProp(v)
    },

    selectedGroupName: {
      default: '',
      validator: v => stringOrNullProp(v)
    },

    hideActions: {
      type: Boolean
    },

    listOptions: {
      type: Array,
      default: () => []
    },

    stakeholdersGrade: {
      type: Object,
      default: () => ({})
    },

    searchTypeId: {
      type: Number,
      default: null
    },

    showLastComment: {
      type: Boolean
    }
  },

  emits: {
    'edit-element': null,
    'link-jira-issue': null,
    'create-jira-issue': null,
    'create-key-result': null,
    'update-okr-elements': null
  },

  VIEWS: OKR_FORM_VIEWS,

  data() {
    return {
      references: []
    }
  },

  computed: {
    lists() {
      const userObjectivesLists = [
        {
          id: 0,
          listType: USER_LIST_TYPES.OWNERS
        },
        {
          id: 1,
          listType: USER_LIST_TYPES.STAKEHOLDERS
        }
        // temporary commented till watchers functionality not added
        // {
        //   id: 2,
        //   listType: USER_TYPES.WATCHERS,
        // },
      ]
      const groupObjectivesLists = [
        {
          id: 3,
          listType: undefined
        }
      ]
      return isEmpty(this.listOptions)
        ? this.userSelected
          ? userObjectivesLists
          : groupObjectivesLists
        : this.listOptions
    }
  },

  beforeUpdate() {
    this.references = []
  },

  methods: {
    setReference(reference) {
      if (reference) {
        this.references.push(reference)
      }
    },

    linkJiraIssue(view, data) {
      this.$emit('link-jira-issue', { view, data })
    },

    onOkrElementDeleted() {
      this.updateOkrElements()
      this.$emit('update-okr-elements')
    },

    /** @public */
    async updateOkrElements() {
      await this.$nextTick()
      this.references.forEach(list => {
        list.updateElements()
      })
    },

    /** @public */
    async refreshData() {
      await this.$nextTick()
      this.references.forEach(list => {
        list.refreshData()
      })
    }
  }
})
</script>

<style lang="scss" scoped></style>
