<template>
  <AppSelect
    v-model="localModelValue"
    :item-label="OKR_LEVEL_ENTITY_KEYS.NAME"
    :item-value="OKR_LEVEL_ENTITY_KEYS.ID"
    :max-height="getMaxDropdownHeight()"
    :offset="[0, -40]"
    :options="options"
    :search-function="value => localSearch({ value, options: options })"
    dropdown-search
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    split-first-option
  >
    <template #button-content="{ option }">
      <OkrTypeFieldOption
        v-if="
          option &&
          option[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] !== BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS
        "
        :option="option"
      />
    </template>
    <template #option-label="{ option }">
      <OkrTypeFieldOption
        v-if="
          option &&
          option[OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION] !== BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS
        "
        :option="option"
      />
    </template>
  </AppSelect>
</template>

<script setup>
import { uniq } from 'lodash'
import { computed } from 'vue'
import { useStore } from 'vuex'

import { OKR_BULK_UPDATE_ENTITY_KEYS, OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { getMetricLevelsForAdd, getObjectiveLevelsForCreate, localSearch } from '@/utils/objectives'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  createKeepAsIsOption,
  getMaxDropdownHeight,
  getOkrBulkFieldModelValueGetter,
  getOkrBulkFiledModelValueEmitPayload
} from '@/utils/okr-elements-table-bulk-actions'

import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'OkrLevelsField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  selectedElementTypeIds: {
    type: Array,
    required: true
  }
})

const store = useStore()

const levels = computed(() => {
  return store.state.objectives.levels
})

const options = computed(() => {
  const isOnlyMetricsSelected =
    uniq(props.selectedElementTypeIds).length === 1 &&
    props.selectedElementTypeIds[0] === OBJECTIVE_TYPES.KR

  const [levelsFilterMethod] = [
    isOnlyMetricsSelected && getMetricLevelsForAdd,
    getObjectiveLevelsForCreate
  ].filter(Boolean)

  return [
    {
      ...createKeepAsIsOption({
        [OKR_BULK_UPDATE_ENTITY_KEYS.VALUE_KEY]: OKR_LEVEL_ENTITY_KEYS.ID,
        [OKR_BULK_UPDATE_ENTITY_KEYS.LABEL_KEY]: OKR_LEVEL_ENTITY_KEYS.NAME
      })
    },
    ...levelsFilterMethod(levels.value).map(level => {
      return {
        ...level,
        [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.REPLACE
      }
    })
  ]
})

const emit = defineEmits({
  'update:model-value': null
})

const localModelValue = computed({
  get: () => {
    return getOkrBulkFieldModelValueGetter({
      modelValue: props.modelValue
    })
  },
  set: newValue => {
    const payload = getOkrBulkFiledModelValueEmitPayload({
      newValue,
      options: options.value,
      valueKey: OKR_LEVEL_ENTITY_KEYS.ID
    })

    emit('update:model-value', payload)
  }
})
</script>

<style lang="scss" scoped></style>
