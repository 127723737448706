import { isEqual } from 'lodash'

import { KR_VALUE_TYPES } from '@/utils/metrics-helpers'

export const isNumberProgress = ({ element }) => {
  return element.fieldTypeId === KR_VALUE_TYPES.START_TARGET && !element.unit
}

export const isNumberWithUnitProgress = ({ element }) => {
  return element.fieldTypeId === KR_VALUE_TYPES.START_TARGET && element.unit
}

export const isBinaryProgress = ({ element }) => {
  return element.fieldTypeId === KR_VALUE_TYPES.BINARY
}

export const getTargetValue = element => {
  if (isNumberProgress({ element })) {
    return element.numberTargetValue
  }

  if (isNumberWithUnitProgress({ element })) {
    return element.percentTargetValue
  }

  return element.targetValue
}

export const isBinarySelectDisabled = modelValue => {
  return (
    modelValue &&
    modelValue.originalValue === modelValue.currentValue &&
    modelValue.originalValue === 1
  )
}

export const getOriginalValue = element => {
  if (isNumberProgress({ element })) {
    return element.numberOriginalValue
  }

  if (isNumberWithUnitProgress({ element })) {
    return element.percentOriginalValue
  }

  return element.originalValue
}

export const getCurrentValue = payload => {
  if (payload.unit && payload.fieldTypeId === KR_VALUE_TYPES.START_TARGET) {
    return payload.percentCurrentValue
  }

  const currentValueByFiledTypeId = {
    [KR_VALUE_TYPES.START_TARGET]: payload.numberCurrentValue,
    [KR_VALUE_TYPES.BINARY]: payload.binaryValue
  }

  return currentValueByFiledTypeId[payload.fieldTypeId]
}

export const valueToStringOrNull = value => (value !== null ? value.toString() : null)

export const GRADE_MODES = {
  AUTO: 1,
  MANUAL: 2
}

export const AUTO_GRADE_OPTION_VALUE = 4 // SEEMS ITS ONLY FE CUSTOM ID

export const JIRA_ISSUE_AUTO_GRADE_OPTION = {
  icon: 'jira-icon',
  label: 'jira.auto_grade',
  value: AUTO_GRADE_OPTION_VALUE
}

export const DEFAULT_RESULT_TYPE_OPTIONS = [
  {
    icon: 'result-type-start-target',
    label: 'objectives.start_target',
    value: KR_VALUE_TYPES.START_TARGET
  },
  {
    icon: 'result-type-threshold',
    label: 'objectives.threshold',
    value: KR_VALUE_TYPES.THRESHOLD,
    unavailable: true
  },
  {
    icon: 'result-type-percent',
    label: 'objectives.percent',
    value: KR_VALUE_TYPES.PERCENT,
    deprecated: true
  },
  { icon: 'result-type-binary', label: 'objectives.binary', value: KR_VALUE_TYPES.BINARY },
  {
    icon: 'result-type-number',
    label: 'objectives.number',
    value: KR_VALUE_TYPES.NUMBER,
    deprecated: true
  }
]

export const DEFAULT_TARGET_VALUE = 100
export const DEFAULT_ORIGINAL_VALUE = 0
export const DEFAULT_CURRENT_VALUE = 0
export const DEFAULT_RESULT_TYPE_VALUES = {
  fieldTypeId: null,
  binaryValue: 0,
  percentOriginalValue: '0',
  percentCurrentValue: '0',
  percentTargetValue: '100',
  numberOriginalValue: '0',
  numberCurrentValue: '0',
  numberTargetValue: '100',
  originalValue: `${DEFAULT_ORIGINAL_VALUE}`,
  currentValue: `${DEFAULT_CURRENT_VALUE}`,
  targetValue: `${DEFAULT_TARGET_VALUE}`,
  unit: ''
}

export const getResultTypeValues = (element = {}) => {
  const { originalValue, currentValue, targetValue } = element

  let result = {}

  if (isNumberProgress({ element })) {
    result.numberOriginalValue = valueToStringOrNull(originalValue)
    result.numberCurrentValue = valueToStringOrNull(currentValue)
    result.numberTargetValue = valueToStringOrNull(targetValue)
  } else if (isNumberWithUnitProgress({ element })) {
    result.percentOriginalValue = valueToStringOrNull(originalValue)
    result.percentCurrentValue = valueToStringOrNull(currentValue)
    result.percentTargetValue = valueToStringOrNull(targetValue)
  } else if (isBinaryProgress({ element })) {
    result.binaryValue = currentValue
  }
  return result
}

export const notEqualStringifiedValues = (value1, value2) => !isEqual(`${value1}`, `${value2}`)

export const getResultTypeSettingsDisabledStates = ({
  isEdit,
  editable,
  isLockedOnPercent,
  isBinarySelectDisabled,
  valuesAreReadonly
}) => {
  const mainCondition = isEdit && !editable
  const inputCondition = valuesAreReadonly || mainCondition
  return {
    select: isLockedOnPercent || mainCondition,
    isSwitch: isBinarySelectDisabled || inputCondition,
    input: inputCondition
  }
}
