import { isEmpty } from 'lodash'
import { computed, inject, ref, toValue, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { OKR_LEVEL_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  createMenuItemFromOkrLevel,
  getMetricLevelsForAdd,
  getObjectiveLevelsForCreate,
  getTaskMenuItems,
  isOkrElementClosed,
  objectiveIsJiraTask,
  OKR_ELEMENT_FORM_MENU_ACTIONS
} from '@/utils/objectives'
import {
  DEFAULT_RESULT_TYPE_OPTIONS,
  JIRA_ISSUE_AUTO_GRADE_OPTION
} from '@/utils/okr-element-values'
import { OKR_ELEMENT_KEYS } from '@/utils/view-row-actions'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

export const useElementTypes = () => {
  const store = useStore()

  const getElementTypeIdByLevelId = ({ levelId = null } = {}) => {
    const level = store.getters['objectives/getLevelItem'](OKR_LEVEL_ENTITY_KEYS.ID, levelId) || {
      [OKR_LEVEL_ENTITY_KEYS.TYPE_ID]: OBJECTIVE_TYPES.PERSONAL
    }

    return level[OKR_LEVEL_ENTITY_KEYS.TYPE_ID]
  }

  return {
    getElementTypeIdByLevelId
  }
}

export const useProgressOptions = ({ formModel = {}, isEdit = false } = {}) => {
  const store = useStore()
  const resolvedIsEdit = toValue(isEdit)
  const { t } = useI18n()

  const levelOptions = computed(() =>
    store.getters['objectives/getLevelItem'](OKR_LEVEL_ENTITY_KEYS.ID, toValue(formModel).levelId)
  )

  const isTask = computed(() => objectiveIsJiraTask(levelOptions.value))

  const getProgressOptions = computed(() => {
    const allAvailableProgressOptions = [
      ...(levelOptions.value[OKR_LEVEL_ENTITY_KEYS.FIELD_TYPE_IDS] || [])
    ]

    let progressOptionsWithConflicts = []

    const { fieldTypeId: currentSelectedFieldTypeId } = toValue(formModel)

    if (
      !isTask.value &&
      resolvedIsEdit &&
      !allAvailableProgressOptions.includes(currentSelectedFieldTypeId)
    ) {
      progressOptionsWithConflicts.push(currentSelectedFieldTypeId)
    }

    const filteredOptions = DEFAULT_RESULT_TYPE_OPTIONS.filter(option => {
      return (
        !option.deprecated &&
        !option.unavailable &&
        [...allAvailableProgressOptions, ...progressOptionsWithConflicts].includes(option.value)
      )
    }).map(option => {
      const isConflict = progressOptionsWithConflicts.includes(option.value)
      return {
        ...option,
        icon: isConflict ? 'info-error' : option.icon,
        tooltipSettings: {
          placement: 'top',
          content: isConflict ? t('okr_element.conflicts.progress_option') : null,
          theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.TEXT_CENTER}`
        }
      }
    })

    if (isTask.value) {
      return [{ ...JIRA_ISSUE_AUTO_GRADE_OPTION }, ...filteredOptions]
    }

    return filteredOptions
  })

  return {
    getProgressOptions
  }
}

export const useParentElement = ({ formModel = {}, isEdit = false } = {}) => {
  const store = useStore()
  const resolvedIsEdit = toValue(isEdit)

  const levelOptions = computed(() =>
    store.getters['objectives/getLevelItem'](OKR_LEVEL_ENTITY_KEYS.ID, toValue(formModel).levelId)
  )

  const allowWithoutParent = computed(
    () => levelOptions.value[OKR_LEVEL_ENTITY_KEYS.ALLOW_WITHOUT_PARENT] || false
  )

  const showParentRequiredErrorOnCreate = ref(false)

  const removeParentRequiredOnCreateError = () => {
    if (showParentRequiredErrorOnCreate.value) {
      showParentRequiredErrorOnCreate.value = false
    }
  }

  const validateParentElement = () => {
    const { parentId } = toValue(formModel)

    if (allowWithoutParent.value) {
      removeParentRequiredOnCreateError()
      return {
        isParentValidationPassed: true
      }
    }

    showParentRequiredErrorOnCreate.value = !parentId

    return {
      isParentValidationPassed: resolvedIsEdit || !showParentRequiredErrorOnCreate.value // always pass validation on edit (only display error but don't block saving)
    }
  }

  watch(levelOptions, () => {
    removeParentRequiredOnCreateError()
  })

  const isShowParentRequiredError = computed(() => {
    if (resolvedIsEdit) {
      const { parentId } = toValue(formModel)

      return !allowWithoutParent.value && !parentId
    }

    return showParentRequiredErrorOnCreate.value
  })

  return {
    isShowUnlinkParentButton: allowWithoutParent,
    isShowParentRequiredError,
    validateParentElement
  }
}

export const useCreateNestedElementDropdownItems = ({ modelValue = {} } = {}) => {
  const { workspaceId } = useElementForm({
    modelValue
  })

  const resolvedWorkspaceId = toValue(workspaceId)

  const { t } = useI18n()
  const store = useStore()

  const isWebApp = inject(isWebAppInjectionKey)
  const isJiraApp = inject(isJiraAppInjectionKey)
  const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)
  const isJiraConnected = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
  )
  const hasAccessToJira = computed(() => store.state.system.userData?.hasAccessToJira || false)

  const currentWorkspaceLevelsByElementTypes = computed(() => {
    const allWorkspaceLevels = store.getters['objectives/getLevelsByWorkspaceId'](
      Number(resolvedWorkspaceId)
    )

    return {
      objectiveLevels: getObjectiveLevelsForCreate(allWorkspaceLevels),
      metricLevels: getMetricLevelsForAdd(allWorkspaceLevels)
    }
  })

  const createAddNestedElementMenuItem = ({ levels = [], template = {} } = {}) => {
    if (isEmpty(levels)) return null

    const isOnlyOneLevel = levels.length === 1

    const [firstLevel] = levels

    const { id } = firstLevel

    const result = {
      ...template,
      levelId: id
    }

    if (isOnlyOneLevel) {
      return {
        ...result,
        ...createMenuItemFromOkrLevel(firstLevel),
        group: null,
        icon: null,
        id: template.id,
        [OKR_LEVEL_ENTITY_KEYS.NAME]: firstLevel[OKR_LEVEL_ENTITY_KEYS.NAME]
      }
    }

    return result
  }

  const createNestedElementDropdownItems = computed(() => {
    const CREATE_OKR_ELEMENT_ACTIONS = [
      createAddNestedElementMenuItem({
        levels: currentWorkspaceLevelsByElementTypes.value.objectiveLevels,
        template: {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_OBJECTIVE,
          name: t('action.create_objective'),
          icon: 'objective',
          typeId: OBJECTIVE_TYPES.PERSONAL
        }
      }),
      createAddNestedElementMenuItem({
        levels: currentWorkspaceLevelsByElementTypes.value.metricLevels,
        template: {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_KR,
          name: t('action.create_metric'),
          icon: 'metric-objective',
          typeId: OBJECTIVE_TYPES.KR
        }
      })
    ]

    const TASK_ACTIONS = getTaskMenuItems({
      isOkrElementClosed: isOkrElementClosed(toValue(modelValue)),
      isJiraApp,
      isWebApp,
      isCrossPlatformApp,
      isJiraConnected: isJiraConnected.value,
      hasAccessToJira: hasAccessToJira.value
    })

    return [...CREATE_OKR_ELEMENT_ACTIONS, ...TASK_ACTIONS].filter(Boolean)
  })

  const getActionById = ({ actionId = null }) => {
    return (
      createNestedElementDropdownItems.value.find(item => item.id === actionId) || {
        [OKR_ELEMENT_KEYS.LEVEL_ID]: null
      }
    )
  }

  return {
    createNestedElementDropdownItems,
    getActionById
  }
}

export const useElementForm = ({ modelValue = {} } = {}) => {
  const route = useRoute()

  const resolvedModelValue = toValue(modelValue)

  const workspaceId = computed(() => {
    if (
      resolvedModelValue &&
      resolvedModelValue.workspaceId &&
      resolvedModelValue.workspaceId !== -1
    ) {
      return resolvedModelValue.workspaceId
    }

    return route.params.workspaceId
  })

  return {
    workspaceId
  }
}
