<template>
  <div :class="wrapperClasses">
    <SkeletonItem v-if="loading" class="ai-Loader" height="100%" width="100%" />
    <div
      :class="{
        'o-input-wrapper--focused': !readonly && isFocused,
        'o-input-wrapper--empty': !modelValue
      }"
      class="o-input-wrapper"
    >
      <div v-if="icon || !prependSlotIsEmpty" class="aif-Prepend">
        <slot name="prepend">
          <AppIcon v-if="icon" :height="iconHeight" :icon-name="icon" :width="iconWidth" />
        </slot>
      </div>

      <input
        ref="input"
        :maxlength="maxLength"
        :readonly="readonly"
        :type="type"
        :value="modelValue"
        class="o-input-field"
        v-bind="bindingAttrs"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @mouseleave="onLeave"
        @mouseover="onOver"
        @keydown.enter="onKeyEnter"
        @keydown.esc="onBlur"
      />
      <div class="o-input-after">
        <AppButton
          v-show="clearable && modelValue.length > 0"
          class="aif-ClearButton"
          height="24"
          icon="cross-circle-next"
          size="sm"
          type="none"
          width="24"
          @click="resetValue"
        />
        <slot name="append-after" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { SIZES, STYLE_TYPES } from '@/utils/components-configurations/app-input'
import { slotIsEmpty } from '@/utils/slots'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

export default defineComponent({
  name: 'AppInput',

  components: { SkeletonItem, AppIcon, AppButton },
  props: {
    modelValue: {
      type: [Number, String],
      required: true
    },

    debounce: {
      type: Number,
      default: 0
    },

    icon: {
      type: String,
      default: ''
    },

    clearable: {
      type: Boolean
    },

    readonly: {
      type: Boolean
    },

    type: {
      type: String,
      default: 'text'
    },

    size: {
      type: String,
      default: 'md',
      validator: v => SIZES.includes(v)
    },

    blurOnEnter: {
      type: Boolean
    },

    styleType: {
      type: String,
      default: 'primary',
      validator: v => STYLE_TYPES.includes(v)
    },

    maxLength: {
      type: [String, Number],
      default: Infinity
    },

    iconHeight: {
      type: [String, Number],
      default: 24
    },

    iconWidth: {
      type: [String, Number],
      default: 24
    },

    isError: {
      type: Boolean
    },

    showErrorForPlaceholder: {
      type: Boolean
    },

    loading: {
      type: Boolean
    },

    // eslint-disable-next-line vue/require-prop-types
    modelModifiers: {
      default: () => ({})
    }
  },

  emits: {
    'update:modelValue': null,
    focus: null,
    blur: null,
    over: null,
    leave: null,
    'keydown-enter': null,
    'control-accept': null,
    'control-decline': null
  },

  data() {
    return {
      isFocused: false
    }
  },

  computed: {
    prependSlotIsEmpty() {
      return slotIsEmpty(this.$slots.prepend)
    },

    wrapperClasses() {
      return {
        'o-input': true,
        [`o-input-${this.size}`]: true,
        [`o-input-${this.styleType}`]: true,
        'o-input-error': this.isError,
        'o-input-placeholder-error': this.isError && this.showErrorForPlaceholder,
        'o-input-loading': this.loading,
        'o-input-readonly': this.readonly
      }
    },

    bindingAttrs() {
      const DATA_TEST_ID = 'data-testid'
      const DATA_AUTO_TEST_ID = 'data-auto-testid'
      const INPUT_ELEMENT = 'input-element'
      return {
        ...this.$attrs,
        [DATA_TEST_ID]: this.$attrs[DATA_TEST_ID]
          ? `${this.$attrs[DATA_TEST_ID]}-${INPUT_ELEMENT}`
          : null,

        [DATA_AUTO_TEST_ID]: this.$attrs[DATA_AUTO_TEST_ID]
          ? `${this.$attrs[DATA_AUTO_TEST_ID]}-${INPUT_ELEMENT}`
          : null
      }
    }
  },

  methods: {
    updateModelValue(value) {
      const { uppercase } = this.modelModifiers
      this.$emit('update:modelValue', uppercase ? value.toUpperCase() : value)
    },

    resetValue() {
      this.updateModelValue('')
      this.$refs.input.focus()
    },

    onInput(e) {
      let newValue = e.target.value

      if (this.modelModifiers.noNumbers) {
        const isValueIncludesNumbers = /\d/.test(newValue)
        if (isValueIncludesNumbers) {
          e.preventDefault()
          e.target.value = this.modelValue
          return
        }
      }

      if (!this.debounce) {
        this.updateModelValue(e.target.value)
        return
      }

      if (this.timeout) window.clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.updateModelValue(e.target.value)
      }, this.debounce)
    },

    onFocus() {
      this.isFocused = true
      this.$emit('focus')
    },

    onBlur(e) {
      this.isFocused = false
      this.$emit('blur', e.target.value)
      // this.$refs.input.blur()
    },

    onOver() {
      this.$emit('over')
    },

    onLeave() {
      this.$emit('leave')
    },

    /** @public */
    focus() {
      this.$refs.input.focus()
    },

    onKeyEnter(event) {
      this.$emit('keydown-enter', event)
      if (this.blurOnEnter) {
        this.$refs.input.blur()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.o-input {
  &-loading {
    position: relative;
    pointer-events: none;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    height: 28px;
    background-color: $grey-8;
    border: 2px solid $grey-8;
    padding-right: 6px;
    transition: background-color 0.05s ease-in-out, border-color 0.05s ease-in-out;
    border-radius: $border-radius-sm;

    &:hover {
      background-color: $grey-11;
      border-color: $grey-11;
    }

    .o-input-xmd & {
      height: 32px;
    }

    .o-input-lg & {
      height: 36px;
    }

    .o-input-xlg & {
      height: 40px;
    }

    .o-input-xxlg & {
      height: 44px;
    }
    .o-input-xxxlg & {
      height: 48px;
    }

    .o-input-primary & {
      background-color: $white;
      border: 2px solid $grey-2-next;
      border-radius: $border-radius-sm-next;
      padding: 0;
      font-family: $system-ui;
    }

    .o-input-primary.o-input-readonly & {
      background-color: var(--readonly-background-color, #{$grey-3-next});
      border-color: var(--readonly-border-color, #{$grey-3-next});
    }

    .o-input-title & {
      background-color: $white;
      border: 2px solid $white;
      border-radius: $border-radius-sm-next;
      padding: 0;
      font-family: $system-ui;
      height: var(--input-wrapper-height, 44px);

      &:hover {
        background-color: var(--input-wrapper-hover-background-color, #{$grey-3-next});
        border-color: var(--input-wrapper-hover-background-color, #{$grey-3-next});
      }
    }

    .o-input-error & {
      border-color: var(--error-border-color, #{$grade-low-color-next});
    }

    .o-input-secondary & {
      box-sizing: border-box;
      background-color: $white;
      border: 2px solid $primary-color-next;
      border-radius: $border-radius-sm-next;

      .o-input-field {
        font-family: $system-ui;

        &::placeholder {
          font-family: $system-ui;
        }
      }

      &:hover {
        background-color: $azure-medium;
      }
    }

    .o-input &--focused {
      border-color: $primary-color-next;
      background-color: $white;
    }

    &--focused {
      .o-input-title & {
        border-color: transparent;
        background-color: $white;
        &:hover {
          background-color: $white;
          border-color: transparent;
        }
      }
    }
  }

  &-field {
    display: block;
    font-family: inherit;
    font-size: var(--input-font-size, #{$fs-14});
    font-weight: var(--input-font-weight, fw('regular'));
    outline: none;
    padding: 0 8px;
    overflow-wrap: break-word;
    background-color: transparent;
    border: none;
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    .o-input-primary & {
      padding: 8px;
    }

    .o-input-title & {
      padding: 0 8px;
      color: $dark-1;
      font-style: normal;
      font-weight: fw('medium');
      font-size: var(--input-font-size, #{$fs-32});
      line-height: var(--input-line-height, 40px);
      caret-color: $primary-color-next;

      &::placeholder {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
    }

    .aif-Prepend + & {
      padding-left: var(--input-with-prepend-padding-left, 4px);
    }

    .o-input-readonly & {
      cursor: not-allowed;
      color: var(--readonly-color, #{$dark-1});
    }

    &::placeholder {
      font-size: $fs-14;
      color: $placeholder-color;

      .o-input-error.o-input-placeholder-error & {
        color: $grade-low-color-next;
      }
    }

    &:-moz-placeholder,
    &::-moz-placeholder {
      opacity: 1;
    }
  }
}

.o-input-after {
  display: flex;
  align-items: center;
}

.aif-Prepend {
  flex-shrink: 0;
  display: flex;
  padding-left: var(--prepend-padding-left, 4px);
}

.o-input-field:focus-visible {
  box-shadow: none !important;
}

.ai-Loader {
  position: absolute;
  left: 0;
  top: 0;
}

.aif-ClearButton {
  margin-right: 4px;
}
</style>
