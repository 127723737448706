<template>
  <div>
    <OkrElementCreator
      v-if="allowCreateNew && allowCreate"
      :objective-levels="objectiveLevels"
      :select-options-list-test-id="HOMEPAGE_OKR_ELEMENT_CREATOR_TEST_ID"
      dropdown-position="bottom-end"
      @on-create-okr-element="onCreateObjectiveClick"
    >
      <template #trigger="{ onTriggerClick }">
        <AppButton
          data-export-ignore
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onTriggerClick"
        >
          {{ $t('action.create_objective') }}
        </AppButton>
      </template>
    </OkrElementCreator>
  </div>

  <portal to="modal-windows">
    <ObjectiveModal
      v-if="showObjectiveModal"
      ref="objectiveModal"
      :workspace-id="resolvedWorkspaceId"
      source="button"
      @close="onObjectiveModalClose"
      @closed="showObjectiveModal = false"
    />
  </portal>
</template>

<script>
import { isNull } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import LevelApiHandler from '@/api/level'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { useElementTypes } from '@/utils/element-types'
import { handleError } from '@/utils/error-handling'
import { useExternalLinkHelper } from '@/utils/external-link-composables'
import { EXTERNAL_LINK_HANDLER_SOURCES } from '@/utils/external-link-helper'
import { appPlatformInjectionKey } from '@/utils/injection-keys'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  currentUserCanCreateObjective,
  isKR,
  OKR_FORM_VIEWS,
  OKR_TYPE_TO_FORM_VIEW,
  okrElementToLinkIssuePayload,
  ROLE_IDS
} from '@/utils/objectives'
import { getEditValueFromQuery } from '@/utils/okr-elements-forms-helpers'

import ObjectiveModal from '@/components/objectives/ObjectiveModal'
import OkrElementCreator from '@/components/objectives/OkrElementCreator'
import AppButton from '@/components/ui/AppButton/AppButton'
import { HOMEPAGE_OKR_ELEMENT_CREATOR_TEST_ID } from '@/components/ui/AppSelect/jest-helpers'

export default defineComponent({
  name: 'PersonalStatisticsOkrElementsModals',
  components: { AppButton, OkrElementCreator, ObjectiveModal },

  inject: {
    appPlatform: {
      from: appPlatformInjectionKey
    }
  },

  props: {
    intervalId: {
      type: Number,
      required: true
    },

    selectedUserOrGroup: {
      type: [String, Number],
      required: true
    },

    userSelected: {
      type: Boolean
    },

    predefinedWorkspaceId: {
      type: Number,
      default: null
    },

    createExternal: {
      type: Boolean
    },

    allowCreate: {
      type: Boolean,
      default: true
    }
  },

  emits: {
    'update-okr-elements': null
  },

  setup() {
    const { handleExternalLink } = useExternalLinkHelper()
    const { getElementTypeIdByLevelId } = useElementTypes()

    return {
      handleExternalLink,
      getElementTypeIdByLevelId
    }
  },

  data() {
    return {
      showObjectiveModal: false
    }
  },

  computed: {
    ...mapState('system', {
      userData: state => state.userData
    }),

    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    HOMEPAGE_OKR_ELEMENT_CREATOR_TEST_ID: () => HOMEPAGE_OKR_ELEMENT_CREATOR_TEST_ID,

    resolvedWorkspaceId() {
      return !isNull(this.predefinedWorkspaceId)
        ? this.predefinedWorkspaceId
        : this.$route?.params.workspaceId
    },

    allowCreateNew() {
      if (!this.$store) {
        return false
      }
      const { isPluginAdmin } = this.$store.state.pluginOptions
      return currentUserCanCreateObjective(
        // Should be with current condition this.$store? because we use this component in macros
        this.$store?.getters['workspaces/selectedWorkspace'],
        isPluginAdmin
      )
    }
  },

  watch: {
    resolvedWorkspaceId: {
      handler(newValue) {
        // new value can be null or undefined (undefined whet we left route and this param disappears)
        if (newValue) {
          this.getLevelsForFilter()
        }
      },

      immediate: true
    }
  },

  mounted() {
    if (this.$route) {
      const { query } = this.$route

      const editValue = getEditValueFromQuery({ query })

      if (editValue) {
        this.onEditElement(editValue)
      }
    }
  },

  methods: {
    ...mapActions('objectives', {
      setLevels: 'setLevels'
    }),

    async onEditElement(elementData) {
      const { typeId, id, children } = elementData
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(OKR_TYPE_TO_FORM_VIEW[typeId], {
        typeId,
        id,
        workspaceId: this.resolvedWorkspaceId
      })
      if (children) {
        const { typeId, id } = children
        this.$refs.objectiveModal.openChildForm(OKR_TYPE_TO_FORM_VIEW[typeId], {
          typeId,
          id,
          workspaceId: this.resolvedWorkspaceId
        })
      }
    },

    async onCreateObjectiveClick(levelId) {
      const typeId = this.getElementTypeIdByLevelId({ levelId })

      if (this.createExternal) {
        this.handleExternalLink({
          typeId,
          workspaceId: this.resolvedWorkspaceId,
          levelId,
          source: EXTERNAL_LINK_HANDLER_SOURCES.CONFLUENCE_MACRO,
          appPlatform: this.appPlatform
        })

        return
      }
      this.showObjectiveModal = true

      const openFormPayload = {
        levelId,
        intervalId: this.intervalId
      }

      if (this.userSelected) {
        openFormPayload.users = [
          {
            elementRoleId: ROLE_IDS.OWNER,
            accountId: this.selectedUserOrGroup
          }
        ]
      } else {
        openFormPayload.groups = [
          {
            id: this.selectedUserOrGroup
          }
        ]
        openFormPayload.users = [
          {
            elementRoleId: ROLE_IDS.OWNER,
            accountId: this.userData.userAccountId
          }
        ]
      }

      const view = isKR({
        typeId
      })
        ? OKR_FORM_VIEWS.KR
        : OKR_FORM_VIEWS.OBJECTIVE

      await this.$nextTick()
      this.$refs.objectiveModal.openForm(view, openFormPayload, 'grid')
    },

    onObjectiveModalClose(eventData) {
      const parameters = {
        keepObjectivesSize: true
      }

      const createdObject = eventData.createdObjective || eventData.createdKR

      const handleOkrElement = element => {
        parameters.blinkObjectiveIds = [
          {
            uniqueId: element.uniqueId,
            // onFirstLevel: true
            onFirstLevel: false
          }
        ]
      }

      if (createdObject) {
        handleOkrElement(createdObject)
      } else if (eventData.createdTasks) {
        eventData.createdTasks.forEach(issue => handleOkrElement(issue))
      }

      this.$emit('update-okr-elements', parameters)
    },

    async getLevelsForFilter() {
      const levelApi = new LevelApiHandler()
      try {
        const levels = await levelApi.getLevelsForFilter({
          workspaceId: this.resolvedWorkspaceId
        })
        this.setLevels({
          levels
        })
      } catch (error) {
        handleError({ error })
      }
    },

    /** @public */
    async createKR(view, parentOkrElement) {
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(
        view,
        {
          typeId: OBJECTIVE_TYPES.KR,
          intervalId: parentOkrElement.intervalId,
          parentId: parentOkrElement.id,
          workspaceId: parentOkrElement.workspaceId,
          automaticElementStartDate: parentOkrElement.automaticElementStartDate,
          automaticDueDate: parentOkrElement.automaticDueDate,
          intervalStartDate: parentOkrElement.intervalStartDate,
          intervalEndDate: parentOkrElement.intervalEndDate
          // users: data.users
        },
        ROUTE_NAMES.HOME_PAGE.toLowerCase()
      )
    },

    /** @public */
    async editObjective(view, data, payload) {
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(
        view,
        data,
        ROUTE_NAMES.HOME_PAGE.toLowerCase(),
        true,
        payload
      )
    },

    /** @public */
    async linkJiraIssue(view, data) {
      this.showObjectiveModal = true
      await this.$nextTick()
      const payload = okrElementToLinkIssuePayload(data)
      this.$refs.objectiveModal.openForm(view, payload, ROUTE_NAMES.HOME_PAGE.toLowerCase())
    }
  }
})
</script>

<style lang="scss" scoped></style>
