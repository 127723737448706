import { useGtm } from '@gtm-support/vue-gtm'
import { has } from 'lodash'

import { EVENT_KEYS } from '@/tracking/gtm-helpers'
import { replaceJwt } from '@/utils/general'

class GtmTracker {
  constructor() {}

  init({ instanceId = null, email = 'unknown' } = {}) {
    this.gtm = useGtm()
    this.instanceId = instanceId
    this.email = email
    this.isInitialized = true
  }

  logEvent(eventName, props = {}) {
    if (!this.isInitialized) return
    if (!eventName) return
    if (!this.gtm) return

    const payload = {
      event: eventName,
      instance_id: this.instanceId,
      email: this.email,
      ...props
    }

    if (has(payload, EVENT_KEYS.PAGE_URL)) {
      payload[EVENT_KEYS.PAGE_URL] = replaceJwt(payload[EVENT_KEYS.PAGE_URL])
    }

    this.gtm.trackEvent(payload)
  }
}

export const gtmTracker = new GtmTracker()
