import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import PluginApiHandler from '@/api/plugin'
import { useFetchRoles } from '@/composables/plugin-users'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { WORKSPACE_USER_ROLES } from '@/utils/objectives'

export const SETTING_PARAMETERS = {
  SHOW_GENERAL_DASHBOARD_TO_USERS: 17,
  SHOW_PERFORMANCE_DASHBOARD_TO_USERS: 18,
  SHOW_CUSTOM_DASHBOARD_TO_USERS: 19,

  HIDE_OTHER_USERS_AND_GROUPS_ON_HOME_PAGE: 4,
  ALLOW_LABEL_CREATION: 5,
  OBJECTIVE_CONTRIBUTE_BY_DEFAULT: 6,
  TASK_CONTRIBUTE_BY_DEFAULT: 7,
  THRESHOLD_BEHIND: 9,
  THRESHOLD_ON_TRACK: 10,
  ALLOW_MANAGE_GROUPS_FOR_WS_ADMINS: 13,
  USER_ROLE_BY_DEFAULT: 14,
  ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES: 16
}

export const useCompanySettings = () => {
  const resolvedRoles = ref([])

  onMounted(async () => {
    const data = await useFetchRoles()
    resolvedRoles.value = data.filter(role => {
      return role.value && role.value !== WORKSPACE_USER_ROLES.NO_ACCESS
    })
  })

  const store = useStore()
  const setSettingsParameter = payload => {
    store.dispatch('system/setSettingsParameter', payload)
  }

  const loading = ref(false)
  const saveSettingsParameter = async ({ parameter, value }) => {
    loading.value = true
    const {
      SHOW_CUSTOM_DASHBOARD_TO_USERS,
      SHOW_GENERAL_DASHBOARD_TO_USERS,
      SHOW_PERFORMANCE_DASHBOARD_TO_USERS,
      HIDE_OTHER_USERS_AND_GROUPS_ON_HOMEPAGE,
      OBJECTIVE_CONTRIBUTE_BY_DEFAULT,
      TASK_CONTRIBUTE_BY_DEFAULT,
      USER_ROLE_BY_DEFAULT,
      THRESHOLD_BEHIND,
      THRESHOLD_ON_TRACK,
      ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES
    } = COMPANY_SETTINGS_ENTITY_KEYS
    const parameterToApiId = {
      [SHOW_CUSTOM_DASHBOARD_TO_USERS]: SETTING_PARAMETERS.SHOW_CUSTOM_DASHBOARD_TO_USERS,
      [SHOW_GENERAL_DASHBOARD_TO_USERS]: SETTING_PARAMETERS.SHOW_GENERAL_DASHBOARD_TO_USERS,
      [SHOW_PERFORMANCE_DASHBOARD_TO_USERS]: SETTING_PARAMETERS.SHOW_PERFORMANCE_DASHBOARD_TO_USERS,
      [HIDE_OTHER_USERS_AND_GROUPS_ON_HOMEPAGE]:
        SETTING_PARAMETERS.HIDE_OTHER_USERS_AND_GROUPS_ON_HOME_PAGE,

      [OBJECTIVE_CONTRIBUTE_BY_DEFAULT]: SETTING_PARAMETERS.OBJECTIVE_CONTRIBUTE_BY_DEFAULT,
      [TASK_CONTRIBUTE_BY_DEFAULT]: SETTING_PARAMETERS.TASK_CONTRIBUTE_BY_DEFAULT,
      [USER_ROLE_BY_DEFAULT]: SETTING_PARAMETERS.USER_ROLE_BY_DEFAULT,
      [THRESHOLD_BEHIND]: SETTING_PARAMETERS.THRESHOLD_BEHIND,
      [THRESHOLD_ON_TRACK]: SETTING_PARAMETERS.THRESHOLD_ON_TRACK,
      [ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES]:
        SETTING_PARAMETERS.ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES
    }

    const trackingEventByParameter = {
      [COMPANY_SETTINGS_ENTITY_KEYS.SHOW_CUSTOM_DASHBOARD_TO_USERS]:
        'set visible custom dashboard setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.SHOW_GENERAL_DASHBOARD_TO_USERS]:
        'set visible general dashboard setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.SHOW_PERFORMANCE_DASHBOARD_TO_USERS]:
        'set visible performance dashboard setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.HIDE_OTHER_USERS_AND_GROUPS_ON_HOMEPAGE]:
        'set progress for ppl search setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.OBJECTIVE_CONTRIBUTE_BY_DEFAULT]: 'set obj contribute setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.TASK_CONTRIBUTE_BY_DEFAULT]:
        'set jira issue contribute setting',
      [COMPANY_SETTINGS_ENTITY_KEYS.USER_ROLE_BY_DEFAULT]: 'set default role',
      [COMPANY_SETTINGS_ENTITY_KEYS.THRESHOLD_BEHIND]: 'set threshold behind',
      [COMPANY_SETTINGS_ENTITY_KEYS.THRESHOLD_ON_TRACK]: 'set threshold on track',
      [COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES]:
        'set allow ws admin to see private objectives'
    }

    const eventName = trackingEventByParameter[parameter]

    if (!eventName) {
      throw new Error(
        `No event name for parameter ${parameter}, tracking failed, please add it to trackingEventByParameter`
      )
    }

    try {
      const api = new PluginApiHandler()

      await api.updateSettings({
        settingId: parameterToApiId[parameter],
        settingValue: value
      })
      setSettingsParameter({ parameter, value })

      const trackingData = {
        category: EVENT_CATEGORIES.SETTINGS,
        value
      }

      if (parameter === COMPANY_SETTINGS_ENTITY_KEYS.USER_ROLE_BY_DEFAULT) {
        trackingData.value =
          resolvedRoles.value.find(role => role.value === value)?.trackingName || TRACKING_UNKNOWN
      }

      tracker.logEvent(eventName, trackingData)
    } catch (error) {
      handleError({ error })
    } finally {
      loading.value = false
    }
  }

  return {
    saveSettingsParameter,
    resolvedRoles,
    loading
  }
}
