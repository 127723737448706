<script setup>
import { useI18n } from 'vue-i18n'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'

import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppInfoMessage from '@/components/ui/AppInfoMessage'

defineOptions({
  name: 'ElementTypeAllowParentControl'
})

const model = defineModel({
  type: Boolean,
  required: true,
  set: value => value
})

defineProps({
  disabled: {
    type: Boolean
  }
})

const { t } = useI18n()
</script>

<template>
  <div class="eta-Wrapper">
    <AppCheckbox v-model="model" :disabled="disabled" full-width>
      {{ t('element_types.allow_without_parent') }}
    </AppCheckbox>

    <AppInfoMessage :type="INFO_MESSAGE_TYPES.NONE">
      {{ t('element_types.allow_without_parent.description') }}
    </AppInfoMessage>
  </div>
</template>

<style lang="scss" scoped>
.eta-Wrapper {
  --info-message-text-padding-top: 6px;
}
</style>
