export default {
  "action.access_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування доступу"])},
  "action.activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активувати"])},
  "action.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
  "action.add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати адресу"])},
  "action.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати ще"])},
  "action.add_column_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати стовпець праворуч"])},
  "action.add_column_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати стовпець ліворуч"])},
  "action.add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати посилання"])},
  "action.add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати метод"])},
  "action.add_row_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати рядок нижче"])},
  "action.add_row_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати рядок вище"])},
  "action.add_to_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати в обране"])},
  "action.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до групи"])},
  "action.add_to_group.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Користувач доданий ", _interpolate(_named("break")), " до всіх робочих просторів ", _interpolate(_named("break")), ", пов'язаних з групою"])},
  "action.add_user_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Додати користувача"]), _normalize(["Додати користувачів"])])},
  "action.add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувачів"])},
  "action.add_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати відео"])},
  "action.archive_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архівувати"])},
  "action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "action.bulk_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групове редагування"])},
  "action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "action.change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити статус"])},
  "action.claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати"])},
  "action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
  "action.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фрагмент коду"])},
  "action.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])},
  "action.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключити"])},
  "action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "action.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати"])},
  "action.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
  "action.create_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити панель"])},
  "action.create_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити Jira-задачу"])},
  "action.create_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити ціль"])},
  "action.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створено"])},
  "action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "action.delete_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити стовпець"])},
  "action.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Видалити ", _interpolate(_named("entity"))])},
  "action.delete_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити рядок"])},
  "action.delete_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити таблицю"])},
  "action.delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувача"])},
  "action.deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалено"])},
  "action.disable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути врахування внеска"])},
  "action.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від'єднати"])},
  "action.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розділювач"])},
  "action.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
  "action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
  "action.edit_on_user_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати на сторінці користувача"])},
  "action.emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емодзі"])},
  "action.enable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути врахування внеска"])},
  "action.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати"])},
  "action.grant_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надати доступ до веб-додатку"])},
  "action.grant_web_access.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зверніть увагу, що це буде", _interpolate(_named("break")), " оплачуватися окремо на основі", _interpolate(_named("break")), " ліцензії веб-додатку."])},
  "action.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зображення"])},
  "action.import_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Імпортувати користувачів"])},
  "action.inactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивувати"])},
  "action.leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити відгук"])},
  "action.lets_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почнемо"])},
  "action.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
  "action.link_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язати Jira-задачу"])},
  "action.link_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зв'язати групи ", _interpolate(_named("platform"))])},
  "action.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати більше"])},
  "action.mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згадати"])},
  "action.merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об'єднати"])},
  "action.merge_or_split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об'єднати або розділити клітинки"])},
  "action.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемістити"])},
  "action.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити"])},
  "action.paste_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставити посилання"])},
  "action.paste_yt_video_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставити посилання на YouTube відео"])},
  "action.proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "action.quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цитата"])},
  "action.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "action.remove_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Видалити ", _interpolate(_named("entity"))])},
  "action.remove_from_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити з адміністраторів"])},
  "action.remove_from_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити з обраного"])},
  "action.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити з групи"])},
  "action.remove_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити з робочого простору"])},
  "action.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати"])},
  "action.reopen_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити"])},
  "action.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповісти"])},
  "action.restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити"])},
  "action.revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "action.revoke_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати доступ до веб-додатку"])},
  "action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "action.select_user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Вибрати користувача"]), _normalize(["Вибрати користувачів"])])},
  "action.select_user_or_email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Введіть електронну пошту або виберіть користувача"]), _normalize(["Введіть електронні пошти або виберіть користувачів"])])},
  "action.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поділитися"])},
  "action.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустити"])},
  "action.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблиця"])},
  "action.table_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування таблиці"])},
  "action.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-задача"])},
  "action.toggle_header_cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок клітинки"])},
  "action.toggle_header_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок стовпця"])},
  "action.toggle_header_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок рядка"])},
  "action.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відв'язати"])},
  "action.unlink_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відв'язати групи"])},
  "action.unlink_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відв'язати Jira-задачу"])},
  "action.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "action.update_license_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити статус ліцензії"])},
  "action.updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновлено"])},
  "action.youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "actions.chain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstAction")), " і ", _interpolate(_named("secondAction"))])},
  "add_users_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувач буде доданий без групи"]), _normalize(["Користувачі будуть додані без групи"])])},
  "admin.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати користувача"])},
  "admins.platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформа"])},
  "app.platform.both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обидві платформи"])},
  "app.platform.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])},
  "app.platform.web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-додаток"])},
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard"])},
  "app_name.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board для Jira"])},
  "app_name.jira_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плагін для Jira"])},
  "audit_log.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "audit_log.element_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Назва"])},
  "audit_log.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий простір"])},
  "audit_logs.all_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі типи"])},
  "audit_logs.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал аудиту надає вам історію змін."])},
  "audit_logs.elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елементи (OKRs)"])},
  "audit_logs.types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типи"])},
  "auth.session_expired.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновіть сторінку, щоб отримати актуальні дані."])},
  "auth.session_expired.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минуло чимало часу з вашої останньої активності"])},
  "badge.added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додано"])},
  "badge.already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вже додано"])},
  "badge.already_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вже пов'язано"])},
  "badge.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незабаром"])},
  "banner.join_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приєднатися зараз!"])},
  "banner.license_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії ліцензії минув. Будь ласка, поновіть її, щоб продовжити користуватися повним функціоналом плагіну."])},
  "banner.license_renew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Термін дії вашої ліцензії скоро закінчиться. Будь ласка, поновіть її протягом ", _interpolate(_named("licenseDaysLeft")), " днів, щоб продовжити використання OKR board для Jira."])},
  "banner.okr_accelerator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акселератор OKR для лідерів"])},
  "banner.trial_subscribe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваш пробний період скоро закінчиться. Будь ласка, оформіть підписку протягом ", _interpolate(_named("licenseDaysLeft")), " днів, щоб продовжити використання OKR board для Jira."])},
  "breadcrumb.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіювати посилання"])},
  "bulk_actions.delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає дозволу на видалення деяких елементів"])},
  "bulk_actions.delete_modal.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("entity"))])},
  "bulk_actions.delete_modal.title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Видалити елемент"]), _normalize(["Видалити елементи"])])},
  "bulk_actions.delete_notification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Елемент видалено"]), _normalize(["Видалено ", _interpolate(_named("count")), " елементів"])])},
  "bulk_actions.disable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнути врахування внеска"])},
  "bulk_actions.disabled_by_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви досягли максимальної кількості вибраних елементів (", _interpolate(_named("count")), ")"])},
  "bulk_actions.disabled_by_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає дозволу на редагування цього елемента"])},
  "bulk_actions.disabled_by_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви не можете редагувати елементи зі статусом ", _interpolate(_named("closedStatus")), " або ", _interpolate(_named("abandonedStatus"))])},
  "bulk_actions.disabled_for_nested_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не можете вибрати елементи із задачами з Jira"])},
  "bulk_actions.enable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути врахування внеска"])},
  "bulk_actions.select_custom_field_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вибрати ", _interpolate(_named("customFieldName"))])},
  "bulk_actions.set_custom_field_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Встановити ", _interpolate(_named("customFieldName"))])},
  "bulk_actions.set_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановити дату"])},
  "bulk_actions.set_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановити значення"])},
  "bulk_update.add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нове"])},
  "bulk_update.disabled_fields_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деякі поля недоступні, оскільки вони не можуть бути використані для всіх вибраних елементів:"])},
  "bulk_update.keep_as_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити як є"])},
  "bulk_update.notification.failed.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоден елемент не був оновлений."])},
  "bulk_update.notification.failed.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групове оновлення не вдалося"])},
  "bulk_update.notification.partial_success.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("success")), " із ", _interpolate(_named("total")), " елементів НЕ були оновлені."])},
  "bulk_update.notification.partial_success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновлення не було повністю успішним."])},
  "bulk_update.notification.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі елементи були успішно оновлені."])},
  "bulk_update.replace_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замінити все"])},
  "comments.add_a_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати коментар"])},
  "comments.edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відредаговано"])},
  "comments.more_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " відповідь"]), _normalize([_interpolate(_named("count")), " відповідей"])])},
  "comments.open_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити коментарі"])},
  "comments.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть ", "@", ", щоб посилатися на людину"])},
  "comments.placeholder.leave_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати коментар…"])},
  "comments.see_last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути останній коментар"])},
  "common.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
  "common.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["та"])},
  "common.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о"])},
  "common.complete_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " з ", _interpolate(_named("end"))])},
  "common.copy_link_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіюйте посилання на цей контент та поділіться з колегами."])},
  "common.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["користувацький"])},
  "common.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За замовчуванням"])},
  "common.direct_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пряме посилання"])},
  "common.every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кожен"])},
  "common.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На весь екран"])},
  "common.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок роботи"])},
  "common.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивний"])},
  "common.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання скопійовано в буфер обміну"])},
  "common.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає результатів"])},
  "common.on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на"])},
  "common.on_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На ", _interpolate(_named("entity"))])},
  "common.on_the_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На ", _interpolate(_named("entity"))])},
  "common.repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
  "common.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати власника"])},
  "common.select_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вибрати ", _interpolate(_named("entity"))])},
  "common.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування"])},
  "common.sideview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бічний вигляд"])},
  "common.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без назви"])},
  "common.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі"])},
  "confirm.admin.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("userName")), " з адміністраторів? Користувач не буде видалений з плагіну, якщо він доданий хоча б до одного робочого простору."])},
  "confirm.admin.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Видалити ", _interpolate(_named("userName")), " з адміністраторів"])},
  "confirm.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("entity")), "?"])},
  "confirm.discard_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відхилити"])},
  "confirm.dontLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прив'язувати"])},
  "confirm.interval_change_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Це скине значення ", _interpolate(_named("dateProp")), " до значення нового інтервалу."])},
  "confirm.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати"])},
  "confirm.objective.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити вкладені елементи"])},
  "confirm.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити ціль"])},
  "confirm.user.message": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ви впевнені, що хочете видалити користувача?"]), _normalize(["Ви впевнені, що хочете видалити користувачів?"])])},
  "confirm_interval_change.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити зміну інтервалу"])},
  "confirm_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас є незбережені дані. Ви впевнені, що хочете відмовитися від усіх незбережених змін?"])},
  "confirm_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати зміни?"])},
  "confluence.error_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, перезавантажте сторінку."])},
  "confluence.error_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось пішло не так."])},
  "confluence.okr_board_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З’єднання OKR Board"])},
  "confluence.permission_less.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви вважаєте, що це помилка, зверніться до адміністраторів JIRA або Oboard."])},
  "confluence.permission_less.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає дозволу на перегляд цієї сторінки"])},
  "confluence.settings.api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API токен"])},
  "confluence.token.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API токен, створений вашим додатком Oboard у вашому акаунті JIRA. Зверніться до Oboard Jira  адміністратора, щоб отримати API токен."])},
  "connect_modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веб-додаток успадкує внутрішні налаштування з Jira App, такі як статуси, пороги та налаштування компанії."])},
  "connect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднати Веб-додаток з Jira App"])},
  "connections.connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднано"])},
  "connections.connected_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднано з"])},
  "connections.connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднання..."])},
  "connections.connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процес з'єднання"])},
  "connections.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен скопійовано"])},
  "connections.copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіювати токен"])},
  "connections.disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від'єднано"])},
  "connections.disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднання..."])},
  "connections.disconnection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процес з'єднання"])},
  "connections.get_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати токен"])},
  "connections.guide.link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструкція з інтеграції"])},
  "connections.help.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібна допомога?"])},
  "connections.lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З'єднання втрачено"])},
  "connections.paste_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставити токен"])},
  "connections.paste_token_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставити токен з"])},
  "connections.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус з'єднання"])},
  "connections.token_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставте токен інтеграції"])},
  "create.admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати адміністратора"])},
  "create.keyResult.label.currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний"])},
  "create.keyResult.label.originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початковий"])},
  "create.keyResult.label.targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цільовий"])},
  "create.objective.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати ціль"])},
  "create.objective.btn.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])},
  "create.objective.btn.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати ціль"])},
  "create.objective.success_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте фільтри, якщо не бачите його"])},
  "create.objective.success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціль створено"])},
  "create.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову ціль"])},
  "create.okr_element.create_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити іншу"])},
  "create.task.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
  "create.task.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-задача зв'язана"])},
  "custom_fields.add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувацьке поле"])},
  "custom_fields.add_field_to_placement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Додати поле до ", _interpolate(_named("placement"))])},
  "custom_fields.add_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати для"])},
  "custom_fields.assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виконавець"])},
  "custom_fields.content_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральна панель OKR"])},
  "custom_fields.create_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити поле"])},
  "custom_fields.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "custom_fields.currency.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть валюту"])},
  "custom_fields.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "custom_fields.delete_field_confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити користувацьке поле ", _interpolate(_named("fieldName")), "? Уся інформація з цього поля буде видалена!"])},
  "custom_fields.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувацьке поле"])},
  "custom_fields.disable_create_button_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На даний момент можливо створити лише ", _interpolate(_named("limit")), " користувацьких полів."])},
  "custom_fields.disable_create_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитайте адміністратора додати нове користувацьке поле."])},
  "custom_fields.field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Встановити ", _interpolate(_named("fieldName")), "…"])},
  "custom_fields.field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва поля"])},
  "custom_fields.field_name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву поля"])},
  "custom_fields.field_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип поля"])},
  "custom_fields.field_type.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть тип поля"])},
  "custom_fields.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перше користувацьке поле"])},
  "custom_fields.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для"])},
  "custom_fields.help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підказка"])},
  "custom_fields.help_text.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть текст підказки"])},
  "custom_fields.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
  "custom_fields.link.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я для відображення"])},
  "custom_fields.long_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Великий текст"])},
  "custom_fields.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гроші"])},
  "custom_fields.multi_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Множинний вибір"])},
  "custom_fields.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає користувацьких полів"])},
  "custom_fields.notification.created_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("fieldName")), " успішно ", _interpolate(_named("action"))])},
  "custom_fields.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Число"])},
  "custom_fields.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опції"])},
  "custom_fields.options.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати опцію"])},
  "custom_fields.options.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву опції"])},
  "custom_fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язкове"])},
  "custom_fields.right_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бокова панель OKR"])},
  "custom_fields.single_line_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Однорядковий текст"])},
  "custom_fields.single_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовий вибір"])},
  "custom_fields.text_field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Додати ", _interpolate(_named("fieldName")), "…"])},
  "custom_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацькі поля"])},
  "custom_fields.warning.filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це поле не може бути відфільтровано або відсортовано"])},
  "custom_fields.warning.not_editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це поле не може бути відредаговане у майбутньому"])},
  "custom_fields.warning.options.create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви можете налаштувати опції лише під час створення нової вибірки. ", _interpolate(_named("break")), "Опції не можуть бути змінені у майбутньому."])},
  "custom_fields.warning.options.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете налаштувати опції лише під час створення нового вибіркового поля."])},
  "custom_weights.contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Враховувати"])},
  "custom_weights.impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вплив"])},
  "custom_weights.weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вага"])},
  "dashboard.access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ заборонений"])},
  "dashboard.across_all_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по всій групі"])},
  "dashboard.across_all_the_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По всіх групах"])},
  "dashboard.add_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувацьку панель"])},
  "dashboard.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати ще"])},
  "dashboard.add_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати людей"])},
  "dashboard.all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
  "dashboard.assigned_to_all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["призначено для всіх груп"])},
  "dashboard.assigned_to_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["призначено для групи"])},
  "dashboard.average_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середнє"])},
  "dashboard.avg_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середній прогрес"])},
  "dashboard.by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По групі"])},
  "dashboard.choose_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть користувацьку панель"])},
  "dashboard.created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Створено ", _interpolate(_named("userName"))])},
  "dashboard.dashboard_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Панель: ", _interpolate(_named("dashboardName")), " успішно видалена"])},
  "dashboard.days_left_in_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Днів до завершення циклу"])},
  "dashboard.duplicate_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублювати панель"])},
  "dashboard.enter_the_main_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть головну мету"])},
  "dashboard.export_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати у PDF"])},
  "dashboard.horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Горизонтальна"])},
  "dashboard.in_this_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["у цьому циклі"])},
  "dashboard.in_this_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цій групі"])},
  "dashboard.in_this_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цьому робочому просторі"])},
  "dashboard.interval_passed_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cyclePassedPercent")), "% завершено"])},
  "dashboard.jira_issue_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-задача"])},
  "dashboard.last_qty_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Останні ", _interpolate(_named("qty")), " днів"])},
  "dashboard.layout_orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орієнтація макету"])},
  "dashboard.linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пов'язано"])},
  "dashboard.menu.custom_dashboard": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувацька"]), _normalize(["Користувацькі"])])},
  "dashboard.menu.general_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна"])},
  "dashboard.menu.performance_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продуктивність"])},
  "dashboard.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий"])},
  "dashboard.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще немає груп"])},
  "dashboard.no_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформаційна панель вимагає принаймні одного відкритого інтервалу"])},
  "dashboard.no_intervals_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший інтервал, щоб почати"])},
  "dashboard.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає панелі, яка відповідає встановленим фільтрам."])},
  "dashboard.no_objectives_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає цілей у групі"])},
  "dashboard.no_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще немає власників"])},
  "dashboard.no_owners_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає власників, що відповідають цьому запиту"])},
  "dashboard.no_report_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть першу користувацьку панель"])},
  "dashboard.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає користувацьких панелей"])},
  "dashboard.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілі"])},
  "dashboard.reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити"])},
  "dashboard.share.public.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Усі публічні панелі видимі для всіх користувачів та доступні для дублювання.", _interpolate(_named("break")), _interpolate(_named("break")), " Зробіть панель приватною, якщо хочете поділитися нею з певними людьми або якщо вона містить конфіденційні дані."])},
  "dashboard.share_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поділитися панеллю"])},
  "dashboard.share_with_same_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поділитися з тими ж людьми"])},
  "dashboard.start_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати презентацію"])},
  "dashboard.stop_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зупинити презентацію"])},
  "dashboard.vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вертикальна"])},
  "date.custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацька дата"])},
  "date.date_with_word_break": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " ", _interpolate(_named("break")), " — ", _interpolate(_named("end"))])},
  "datepicker.next": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["наступні ", _interpolate(_named("days")), " дні"])},
  "datepicker.previous": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["попередні ", _interpolate(_named("days")), " дні"])},
  "datepicker.select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати дату"])},
  "datepicker.select_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати діапазон дат"])},
  "datepicker.without_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без дати"])},
  "debug.calculate_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахувати оцінки"])},
  "debug.calculate_grades_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запуск перерахунку оцінок"])},
  "debug.claim_admin-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати права адміністратора"])},
  "debug.clean_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити"])},
  "debug.clean_up_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити пошкоджені посилання на Jira-задачі"])},
  "debug.confirm_migration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція не може бути автоматично скасована, не починайте її, якщо вас не попросила служба підтримки."])},
  "debug.confirm_migration_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете розпочати міграцію?"])},
  "debug.debug_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація для налагодження"])},
  "debug.delete_deactivated_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити деактивованих користувачів"])},
  "debug.download_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити інформацію для налагодження"])},
  "debug.download_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
  "debug.download_logs_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити журнали"])},
  "debug.export_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати до .zip"])},
  "debug.grade_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерахувати"])},
  "debug.grades_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінки оновлено"])},
  "debug.i_understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я розумію"])},
  "debug.import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка імпорту"])},
  "debug.import_file_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архів занадто великий, зверніться до нас <a href=\"mailto:support", "@", "oboard.io\">support", "@", "oboard.io</a> для збільшення ліміту."])},
  "debug.import_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Імпортувати з .zip"])},
  "debug.instance_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція аккаунта"])},
  "debug.migrate_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція атрибутів"])},
  "debug.migrate_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція атрибутів Jira-задач"])},
  "debug.migrate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенести електронну пошту"])},
  "debug.migrate_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція електронної пошти"])},
  "debug.migrate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція інтервалів"])},
  "debug.migrate_intervals_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція інтервалів"])},
  "debug.migrate_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція тегів"])},
  "debug.migrate_labels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція тегів"])},
  "debug.migrate_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція Jira-задач"])},
  "debug.migrate_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграція Jira-задач"])},
  "debug.migrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міграції"])},
  "debug.remove_duplicated_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити дубльовані інтервали"])},
  "debug.remove_okr_board_global_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити глобальні дозволи OKR board"])},
  "debug.remove_users_without_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувачів без груп"])},
  "debug.restore_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити атрибути"])},
  "debug.restore_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити атрибути елементів"])},
  "debug.restore_interval_dates_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити дати інтервалів для елементів"])},
  "debug.restore_jira_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити деталі Jira"])},
  "debug.restore_task_chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити ланцюжок Jira-задач"])},
  "debug.restore_users_and_groups_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити користувачів і групи для елементів"])},
  "debug.rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права"])},
  "debug.start_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розпочати міграцію"])},
  "debug.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка налагодження"])},
  "debug.update_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновлення інформації про користувачів"])},
  "debug.update_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновлення інформації про користувачів"])},
  "debug.upload_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
  "debug.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередження"])},
  "delete_confirm_interval_input_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Введіть ", _interpolate(_named("keyword")), " для підтвердження"])},
  "delete_confirm_interval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити інтервал"])},
  "delete_confirm_issue_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете відв'язати Jira-задачу:"])},
  "delete_confirm_token_message_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасований API токен не можна повернути"])},
  "delete_confirm_token_message_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете назавжди скасувати свій API токен"])},
  "delete_confirm_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати API токен"])},
  "delete_confirm_workspace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити робочий простір"])},
  "delete_user.count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Видалити ", _interpolate(_named("count")), " користувачів"])},
  "delete_user.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Видалити ", _interpolate(_named("userName"))]), _normalize(["Видалити користувачів"])])},
  "delete_user_from_app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("userName")), " з ", _interpolate(_named("appName")), "?"])},
  "delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("userName")), " з робочого простору: ", _interpolate(_named("workspaceName")), "?"])},
  "description.clear_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити форматування"])},
  "description.heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 1"])},
  "description.heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 2"])},
  "description.heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 3"])},
  "description.heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 4"])},
  "description.heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 5"])},
  "description.heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок 6"])},
  "description.normal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звичайний текст"])},
  "description.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати опис…"])},
  "description.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позиція:"])},
  "description.strikethrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закреслити"])},
  "description.subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижній індекс"])},
  "description.superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верхній індекс"])},
  "description.type_mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть ", "@", ", щоб згадати і сповістити когось."])},
  "description.underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підкреслити"])},
  "disconnect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це призведе до наступних змін"])},
  "disconnect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від'єднати Веб-додаток від Jira App"])},
  "disconnect_modal_events_list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всіх користувачів, які належать тільки Jira, з Веб-додатку"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всі зв'язки груп Jira у обох додатках"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити Jira-задачі з усіх видів у Веб-додатку"])},
    
  ],
  "dropdown.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублювати"])},
  "dropdown.export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт у CSV"])},
  "dropdown.link_new_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прив'язати нові групи ", _interpolate(_named("platform"))])},
  "dropdown.restore_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити за замовчуванням"])},
  "dropdown.sync_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронізувати зараз"])},
  "dropdown.unlink_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Відв'язати групи ", _interpolate(_named("platform"))])},
  "dropdown.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глядач"])},
  "dropdown.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацька вага"])},
  "edit_later.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви зможете відредагувати їх пізніше"])},
  "element.set_new_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Змінити статус ", _interpolate(_named("okr")), " на ", _interpolate(_named("status"))])},
  "element.type.jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-задача"])},
  "element.type.key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
  "entity.element": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["елемент"]), _normalize([_interpolate(_named("count")), " елементів"])])},
  "expired_license.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придбати ліцензію"])},
  "expired_license.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися детальні ціни"])},
  "expired_license.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви не адміністратор JIRA, попросіть його надати доступ"])},
  "expired_license.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваша ліцензія OKR Board для ", _interpolate(_named("platform")), " більше не дійсна"])},
  "feedback.modal.message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що у плагіні ви знаходите найбільш складним або потребує покращення?"])},
  "feedback.modal.rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як би ви нас оцінили?"])},
  "feedback.modal.review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це допоможе нам рости швидше та робити продукт кращим."])},
  "feedback.modal.review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми були б вдячні за ваш відгук на маркетплейсі"])},
  "feedback.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допоможіть нам створити кращий продукт"])},
  "field.assignee.stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписники"])},
  "field.assignee.stakeholders.bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати підписника"])},
  "field.assignee.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник"])},
  "field.contribute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Враховувати оцінку у батьківську ціль"])},
  "field.group.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
  "field.group.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "field.groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
  "field.interval_wrong_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірне ключове слово"])},
  "field.issue.jql_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть JQL-запит"])},
  "field.issue.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук Jira-задачі за назвою або повним ID"])},
  "field.labels.bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати тег"])},
  "field.labels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
  "field.labels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
  "field.name_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибачте, ця назва зарезервована"])},
  "field.not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не встановлено"])},
  "field.owner.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати власника…"])},
  "field.parentItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Батьківський елемент"])},
  "field.parentObjective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Батьківська ціль"])},
  "field.period.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період"])},
  "field.quoter.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановити інтервал…"])},
  "field.quoter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервал"])},
  "field.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язкове поле"])},
  "field.select.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження"])},
  "field.select.nooptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає варіантів"])},
  "field.status.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "field.workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий простір"])},
  "field.workspace_key_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ повинен бути 1-3 символів"])},
  "field.workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва має бути мінімум 2 символи"])},
  "fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язкові поля"])},
  "figma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figma"])},
  "file_upload.allowed_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, BMP, PNG, GIF або WEBP, менше 10 МБ"])},
  "file_upload.choose_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати зображення"])},
  "file_upload.drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетягніть зображення сюди або"])},
  "file_upload.drop_image_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетягніть зображення сюди"])},
  "filed.assignee.stakeholders.plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписники"])},
  "filter.A_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А-Я"])},
  "filter.Z_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я-А"])},
  "filter.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
  "filter.add_another_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати ще одне поле"])},
  "filter.all_are_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі цілі відфільтровані"])},
  "filter.all_frequencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі частоти"])},
  "filter.all_grade_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі статуси"])},
  "filter.all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі групи"])},
  "filter.all_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі мітки"])},
  "filter.all_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі власники"])},
  "filter.all_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі платформи"])},
  "filter.all_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі ролі"])},
  "filter.all_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі підписники"])},
  "filter.all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі користувачі"])},
  "filter.at_least_comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Щонайменше ", _interpolate(_named("count")), " коментар"]), _normalize(["Щонайменше ", _interpolate(_named("count")), " коментарі"])])},
  "filter.avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середнє"])},
  "filter.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити"])},
  "filter.clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити все"])},
  "filter.clear_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити обране"])},
  "filter.clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити вибір"])},
  "filter.collapse_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згорнути все"])},
  "filter.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіювати посилання в буфер обміну"])},
  "filter.creating_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["створення нового OKR."])},
  "filter.custom_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацький діапазон"])},
  "filter.custom_range.before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
  "filter.custom_range.between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Між"])},
  "filter.custom_range.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від"])},
  "filter.due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершення"])},
  "filter.expand_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розгорнути все"])},
  "filter.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
  "filter.hide_filtered_items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Приховати ", _interpolate(_named("count")), " відфільтрований елемент"]), _normalize(["Приховати ", _interpolate(_named("count")), " відфільтровані елементи"])])},
  "filter.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивний"])},
  "filter.is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порожній"])},
  "filter.is_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не порожній"])},
  "filter.last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останній коментар"])},
  "filter.last_grade_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата оновлення оцінки"])},
  "filter.less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менше ніж"])},
  "filter.limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обмеження"])},
  "filter.manage_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування інтервалами"])},
  "filter.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші"])},
  "filter.more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші фільтри"])},
  "filter.more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше ніж"])},
  "filter.never_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ніколи не коментовано"])},
  "filter.no_objective_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший OKR, щоб розпочати"])},
  "filter.no_objective_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Спробуйте змінити критерії пошуку або ", _interpolate(_named("creating"))])},
  "filter.no_objective_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає OKR у цьому інтервалі"])},
  "filter.only_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки це"])},
  "filter.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власники"])},
  "filter.show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати всі"])},
  "filter.show_filtered_items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Показати ", _interpolate(_named("count")), " відфільтрований елемент"]), _normalize(["Показати ", _interpolate(_named("count")), " відфільтровані елементи"])])},
  "filter.show_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати їх"])},
  "filter.sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортувати"])},
  "filter.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку"])},
  "filter.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "filter.statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статуси"])},
  "filter.this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цього місяця"])},
  "filter.this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цього тижня"])},
  "filter.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
  "filters.custom_fields.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережений фільтр має користувацьке поле, яке більше недоступне."])},
  "filters.filter_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Фільтр: ", _interpolate(_named("filterName")), " видалено"])},
  "filters.save_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти як..."])},
  "filters.save_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти фільтр як"])},
  "filters.update_dashboard_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити панель як"])},
  "filters.update_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити фільтр"])},
  "filters.update_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити фільтр як"])},
  "filters_saver.filter_renamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Фільтр: ", _interpolate(_named("filterName")), " успішно перейменовано"])},
  "filters_saver.filter_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Фільтр: ", _interpolate(_named("filterName")), " успішно збережено"])},
  "filters_saver.filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Фільтр: ", _interpolate(_named("filterName")), " успішно оновлено"])},
  "global_groups.add_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати групи"])},
  "global_groups.add_groups_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати групи до робочого простору"])},
  "global_groups.add_selected_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Додати ", _interpolate(_named("count")), " вибрану групу"]), _normalize(["Додати ", _interpolate(_named("count")), " вибрані групи"])])},
  "global_groups.add_sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати підгрупу"])},
  "global_groups.audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал аудиту"])},
  "global_groups.available_only_in_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно лише в плагіні Jira"])},
  "global_groups.delete_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("groupName")), "? Група буде видалена з усіх цілей і робочих просторів!"])},
  "global_groups.delete_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувачів із робочих просторів (якщо користувачі не належать до інших груп у робочому просторі)."])},
  "global_groups.keep_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити користувачів у робочих просторах після видалення групи."])},
  "global_groups.manage_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керувати групою"])},
  "global_groups.manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керувати користувачами"])},
  "global_groups.merge_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об'єднання груп вплине на робочі простори"])},
  "global_groups.merge_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, виберіть принаймні дві групи для об’єднання"])},
  "global_groups.merge_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Об'єднати групи"])},
  "global_groups.no_users.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте користувачів до цієї групи, щоб використовувати їх у робочих просторах"])},
  "global_groups.no_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цій групі ще немає користувачів"])},
  "global_groups.no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не знайдено користувачів, що відповідають вашому запиту"])},
  "global_groups.parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Батьківська група"])},
  "global_groups.remove_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Видалити ", _interpolate(_named("userName")), " з групи"]), _normalize(["Видалити ", _interpolate(_named("userName")), " з груп"])])},
  "global_groups.remove_from_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити групу з робочого простору"])},
  "global_groups.remove_from_workspace.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("groupName")), " з робочого простору?"])},
  "global_groups.select_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати батьківську групу"])},
  "global_groups.sub_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підгрупи"])},
  "global_groups.unavailable_for_add_to_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви повинні бути адміністратором усіх робочих просторів, до яких призначена група. Будь ласка, попросіть адміністратора плагіна надати права."])},
  "global_groups.unavailable_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не маєте прав на керування цією групою"])},
  "global_groups.users_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання або видалення користувачів може вплинути на робочі простори"])},
  "global_groups.will_be_removed_from_ws": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувач також може бути видалений з робочих просторів"]), _normalize(["Користувач також може бути видалений з робочих просторів"])])},
  "global_groups.without_parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без батьківської групи"])},
  "grade_info.filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відфільтровані елементи"])},
  "grade_info.total_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна оцінка"])},
  "group.group_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групувати за"])},
  "group.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва групи"])},
  "group.select_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати робочий простір"])},
  "group.table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табличний вигляд"])},
  "group.user_will_be_added_to_the_workspaces": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувач буде доданий до робочих просторів:"]), _normalize(["Користувачі будуть додані до робочих просторів:"])])},
  "groups.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити групу"])},
  "groups.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити групу"])},
  "groups.new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова група"])},
  "groups.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає груп, які відповідають пошуку"])},
  "groups.no_groups_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає груп у цьому робочому просторі."])},
  "groups.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати групу"])},
  "groups.unlink.delete_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувачів"])},
  "groups.unlink.delete_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Видалити користувачів з ", _interpolate(_named("group")), " після відв'язання групи ", _interpolate(_named("platform")), "."])},
  "groups.unlink.keep_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити користувачів"])},
  "groups.unlink.keep_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Залишити користувачів у ", _interpolate(_named("group")), " після відв'язання групи ", _interpolate(_named("platform")), "."])},
  "groups.unlink.strategy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть опцію"])},
  "homepage.export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати *.pdf"])},
  "homepage.group_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди в групі"])},
  "homepage.notify.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не вдалося знайти вибраного ", _interpolate(_named("role"))])},
  "homepage.notify.role.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["користувача"])},
  "homepage.objective_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ціль"]), _normalize(["Цілі"])])},
  "homepage.objectives.owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цілі ", _interpolate(_named("userName"))])},
  "homepage.objectives.stakeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " є підписником"])},
  "homepage.objectives.watcher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " стежить"])},
  "homepage.objectives_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupName")), " цілі"])},
  "homepage.okr_elements_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Немає цілей або результатів для ", _interpolate(_named("role"))])},
  "homepage.progress.last_week_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зміна за минулий тиждень"])},
  "homepage.progress.switch.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогрес цілей"])},
  "homepage.role.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["група є власником"])},
  "homepage.role.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви власник"])},
  "homepage.role.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви підписник"])},
  "homepage.role.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви спостерігач"])},
  "homepage.user_select.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підрозділи компанії"])},
  "homepage.user_select.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Члени команди"])},
  "homepage.user_summary.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групи ", _interpolate(_named("userName"))])},
  "img.error_loading_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка завантаження зображення."])},
  "img.failed_to_read_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося прочитати файл"])},
  "img.file_is_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл занадто великий"])},
  "img.image_is_large_than_allowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зображення перевищує допустимі розміри (більше ніж ", _interpolate(_named("maxWidth")), " x ", _interpolate(_named("maxHeight")), " пікселів)"])},
  "img.not_valid_img_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недійсний формат зображення"])},
  "in_app_notifications.button.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас є непрочитані сповіщення"])},
  "in_app_notifications.category.older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раніше"])},
  "in_app_notifications.category.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
  "in_app_notifications.no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз у вас немає сповіщень"])},
  "in_app_notifications.notification.commented.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " прокоментував ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.replied.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " відповів вам у ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати менше"])},
  "in_app_notifications.notification.status.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " змінив статус"])},
  "in_app_notifications.notification.updates.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+ ", _interpolate(_named("qty")), " оновлень від ", _interpolate(_named("userName")), " та інших"])},
  "in_app_notifications.notification_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " згадав вас у ", _interpolate(_named("element"))])},
  "in_app_notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення"])},
  "installation_app.installation_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інсталяція завершена"])},
  "installation_app.installation_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інсталяція в процесі..."])},
  "installation_app.reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновіть сторінку"])},
  "integration_settings.connection_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здається, плагін Jira був видалений. Відновіть плагін Jira або від'єднайте його."])},
  "integration_settings.jira.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб від'єднати, натисніть кнопку, щоб відкрити Веб-додаток і перейти до відповідного облікового запису."])},
  "integration_settings.jira.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть кнопку, щоб скопіювати токен інтеграції. Термін дії токена становить 30 хвилин і він може бути використаний лише один раз."])},
  "integration_settings.jira.step2.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для встановлення з'єднання потрібно мати обліковий запис Веб-додатку. Натисніть кнопку, щоб відкрити сторінку Веб-додатку.", _interpolate(_named("break")), "Увійдіть, виберіть потрібний обліковий запис та перейдіть до Налаштування/Інтеграції/Плагін Jira."])},
  "integration_settings.web_app.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо у вас вже є токен, перейдіть до кроку 2. Інакше переконайтеся, що у вас є обліковий запис Jira. Відкрийте Atlassian Marketplace, встановіть плагін OKR board для Jira. Перейдіть у плагін і відкрийте Налаштування/Інтеграції/Веб-додаток."])},
  "integration_settings.web_app.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати токен плагіну Jira"])},
  "integration_settings.web_app.step2.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставте токен інтеграції плагіна Jira у відповідне поле. Натисніть підключити. Процес з'єднання може зайняти деякий час."])},
  "interval.label.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершення"])},
  "interval.label.time_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період"])},
  "intervals.create_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити інтервал"])},
  "intervals.edit_interval": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Редагувати інтервал ", _interpolate(_named("intervalName"))])},
  "intervals.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва інтервалу"])},
  "intervals.new_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий інтервал"])},
  "intervals.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервали не знайдено"])},
  "intervals.status.archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архівований"])},
  "intervals.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкритий"])},
  "jira.auto_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматична оцінка"])},
  "jira.auto_grade.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматична оцінка базується на статусі Jira-задачі або прогресі її підзадач"])},
  "jira.dashboard.access.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає доступу до робочої області."])},
  "jira.dashboard.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ієрархія"])},
  "jira.dashboard.hierarchy_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляд ієрархії"])},
  "jira.dashboard.okrs_displayed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволені числа від 1 до 100"])},
  "jira.manual_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручна оцінка"])},
  "kr.add_nested_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати вкладений елемент"])},
  "kr.move_from_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемістити з усіх робочих просторів."])},
  "kr.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемістити вкладені елементи. Тільки з поточного робочого простору."])},
  "kr.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкладені елементи"])},
  "labels.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити тег"])},
  "labels.delete_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити тег ", _interpolate(_named("labelName")), " з усіх цілей та результатів?"])},
  "labels.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити тег"])},
  "labels.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва тега"])},
  "labels.new_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий тег"])},
  "labels.no_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегів ще немає"])},
  "labels.no_labels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший тег, щоб почати"])},
  "labels.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегів не знайдено"])},
  "labels.toast.create_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег створений"])},
  "levels.add_to_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до всіх робочих просторів"])},
  "levels.add_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Додати робочий простір"]), _normalize(["Додати робочі простори"])])},
  "levels.add_workspaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Додати робочий простір до ", _interpolate(_named("levelName"))]), _normalize(["Додати робочі простори до ", _interpolate(_named("levelName"))])])},
  "levels.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["і"])},
  "levels.color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір"])},
  "levels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іконка"])},
  "levels.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["інше"])},
  "levels.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (1-3)"])},
  "levels.unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідома помилка"])},
  "limitations.create_button.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для вашої підписки можливо створити лише ", _interpolate(_named("limit")), " ", _interpolate(_named("entity")), "."])},
  "limitations.entity.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви досягли максимальної кількості (", _interpolate(_named("limit")), ") для ", _interpolate(_named("entity")), "."])},
  "link_jira_issue.check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити результат у новій вкладці"])},
  "link_jira_issue.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви хочете прив'язати Jira-задачу, що сприяє виконанню результату."])},
  "link_jira_issue.import_count_jira_issues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ми імпортуємо лише перші ", _interpolate(_named("count")), " Jira-задач"])},
  "link_jira_issue.it_means": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це означає:"])},
  "link_jira_issue.jira_issues_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Знайдено Jira-задач: ", _interpolate(_named("count")), "."])},
  "link_jira_issue.jql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL"])},
  "link_jira_issue.jql_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некоректний запит JQL"])},
  "link_jira_issue.jql_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування запиту вичерпано"])},
  "link_jira_issue.link_another_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язати ще одну Jira-задачу"])},
  "link_jira_issue.link_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язати все одно?"])},
  "link_jira_issue.linked_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язана Jira-задача:"])},
  "link_jira_issue.list_item_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінка буде розраховуватися автоматично залежно від статусу Jira-задачі."])},
  "link_jira_issue.list_item_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не зможете змінити оцінку вручну, поки не від'єднаєте задачу або не вимкнете опцію рахування."])},
  "link_jira_issue.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити задачу в Jira"])},
  "lock_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви не маєте прав на редагування цього ", _interpolate(_named("element"))])},
  "lock_message.entity_parent_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Батьківський ", _interpolate(_named("parentOkr")), " є ", _interpolate(_named("statusName")), ", і його оцінка не буде оновлена"])},
  "lock_message.grade_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цей елемент є ", _interpolate(_named("status")), ". Оцінки не оновлюються для ", _interpolate(_named("status")), " елементів."])},
  "lock_message.grade_closed.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб редагувати або оновити оцінки, будь ласка, відкрийте елемент."])},
  "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "menu.activity_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал активності"])},
  "menu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратори"])},
  "menu.api_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API токени"])},
  "menu.audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал аудиту"])},
  "menu.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежі"])},
  "menu.branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брендування"])},
  "menu.check_ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновлення прогресу"])},
  "menu.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "menu.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарі"])},
  "menu.custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацькі поля"])},
  "menu.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
  "menu.integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтеграції"])},
  "menu.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілі"])},
  "menu.okr_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
  "menu.organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи та користувачі"])},
  "menu.organization.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальні групи та користувачі"])},
  "menu.personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особисті"])},
  "menu.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])},
  "menu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування"])},
  "menu.show_other_views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати інші види"])},
  "menu.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка"])},
  "menu.synchronization_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронізація"])},
  "menu.tooltip.collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згорнути"])},
  "menu.tooltip.expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розгорнути"])},
  "menu.tooltip.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися"])},
  "menu.whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що нового"])},
  "menu.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочі простори"])},
  "menu.workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування та інтервали"])},
  "miro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miro"])},
  "missing_page.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На головну"])},
  "missing_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливо, щось пішло не так або сторінка більше не існує."])},
  "missing_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибачте, ми не можемо знайти цю сторінку"])},
  "move.copy_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати вкладені цілі"])},
  "move.no_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає батьківського елемента"])},
  "move.select_assignee_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не знайшли відповідного власника в цільовому робочому просторі, будь ласка, виберіть вручну."])},
  "move.select_groups_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не знайшли відповідних груп у цільовому робочому просторі, будь ласка, виберіть вручну."])},
  "move.success_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно переміщено"])},
  "notification.contact_ws_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, зверніться до адміністратора робочого простору."])},
  "notifications.administrator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратор доданий."])},
  "notifications.comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар видалено"])},
  "notifications.comment_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "notifications.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
  "notifications.excel_export_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт готується і буде надіслано на вашу електронну пошту, щойно він буде готовий."])},
  "notifications.excel_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звіт скоро буде завантажено."])},
  "notifications.groups_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групу видалено з робочого простору."])},
  "notifications.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання скопійовано."])},
  "notifications.lost_group_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схоже, ви втратили доступ до цієї групи. Зверніться до свого адміністратора"])},
  "notifications.oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс…"])},
  "notifications.plugin_version_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версія вашого плагіна скопійована."])},
  "notifications.role_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль змінено."])},
  "notifications.role_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль успішно оновлено."])},
  "notifications.user_deleted": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувача видалено."]), _normalize(["Користувачів видалено."])])},
  "objective.a_moment_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щойно"])},
  "objective.activity_log_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журналу активності ще немає"])},
  "objective.btn.add_nested_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати вкладену ціль"])},
  "objective.btn.custom_weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацька вага"])},
  "objective.btn.no_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає вкладених елементів"])},
  "objective.btn.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви маєте права тільки для перегляду"])},
  "objective.by_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За статусом"])},
  "objective.cant_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не можете перемістити ціль"])},
  "objective.chart.daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоденно"])},
  "objective.chart.downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити CSV"])},
  "objective.chart.download_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити .xlsx"])},
  "objective.chart.fullSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повний розмір"])},
  "objective.chart.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На весь екран"])},
  "objective.chart.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щомісяця"])},
  "objective.chart.no_items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вибрано жодного елемента"])},
  "objective.chart.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогрес"])},
  "objective.chart.quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоквартально"])},
  "objective.chart.weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щотижня"])},
  "objective.chart.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роки"])},
  "objective.chart_table.split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розділити за вагою"])},
  "objective.clear_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити групу"])},
  "objective.columns_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування стовпців"])},
  "objective.columns_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стовпці"])},
  "objective.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зроблено"])},
  "objective.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублікат:"])},
  "objective.hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["годин тому"])},
  "objective.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не зроблено"])},
  "objective.make_okr_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зробити видимим лише для власників і підписників"])},
  "objective.make_okr_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зробити видимим для всіх"])},
  "objective.modal_title.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву цілі"])},
  "objective.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемістити вкладені елементи"])},
  "objective.move_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перемістити ", _interpolate(_named("displayId")), " до іншого робочого простору"])},
  "objective.move_objective_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете перемістити ціль:"])},
  "objective.nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкладені цілі"])},
  "objective.no_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без власника"])},
  "objective.obj_cant_move": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ціль не може бути переміщена з вкладеними елементами, оскільки один із вкладених елементів має статус ", _interpolate(_named("closedStatus")), " або ", _interpolate(_named("abandonedStatus"))])},
  "objective.obj_cant_move_because_kr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ціль не може бути переміщеною, оскільки один із вкладених елементів має статус ", _interpolate(_named("closedStatus")), " або ", _interpolate(_named("abandonedStatus"))])},
  "objective.search_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук за повним ID або назвою"])},
  "objective.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати власника"])},
  "objective.select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть групу"])},
  "objective.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортувати"])},
  "objective.sorting_drag_blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(перетягування недоступне)"])},
  "objective.sorting_switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включаючи вкладені елементи"])},
  "objective.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вага:"])},
  "objective.you_cant_make_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не можете зробити ціль приватною.\nВ ньому має бути власник або підписник"])},
  "objectives.binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двійковий"])},
  "objectives.days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днів тому"])},
  "objectives.due_date.end_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершення"])},
  "objectives.due_date.start_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку"])},
  "objectives.last_count_items_shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано останні ", _interpolate(_named("count")), " ", _interpolate(_named("entity")), ". Щоб переглянути більше, виконайте пошук, використовуючи повний ID або ім’я."])},
  "objectives.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Числовий"])},
  "objectives.percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсоток"])},
  "objectives.period_mode.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим періоду"])},
  "objectives.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Передбачено ", _interpolate(_named("symbol"))])},
  "objectives.status_indicator.description.grade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("grade")), " – поточна оцінка"])},
  "objectives.status_indicator.description.passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("passed")), " – минуло часу"])},
  "objectives.status_indicator.description.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("predicted")), " – Прогнозована оцінка (", _interpolate(_named("grade")), "/", _interpolate(_named("passed")), ")*100"])},
  "objectives.table_header_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник"])},
  "objectives.table_header_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарі"])},
  "objectives.table_header_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Враховувати"])},
  "objectives.table_header_currentTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний / Цільовий"])},
  "objectives.table_header_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати"])},
  "objectives.table_header_duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершення"])},
  "objectives.table_header_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
  "objectives.table_header_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінка"])},
  "objectives.table_header_gradeUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінку оновлено"])},
  "objectives.table_header_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
  "objectives.table_header_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервал"])},
  "objectives.table_header_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мітки"])},
  "objectives.table_header_lastGradeUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останнє оновлення оцінки"])},
  "objectives.table_header_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватність"])},
  "objectives.table_header_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зацікавлені особи"])},
  "objectives.table_header_startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку"])},
  "objectives.table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "objectives.table_header_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вага"])},
  "objectives.type_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанія"])},
  "objectives.type_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "objectives.type_PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особисті"])},
  "objectives.unavailable_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не маєте доступу до цієї цілі. Зверніться до адміністратора Jira."])},
  "okr_element.rename.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть нову назву"])},
  "okr_elements.objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціль"])},
  "okr_elements.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілі"])},
  "onboarding.admins.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте більше адміністраторів"])},
  "onboarding.demo.product_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо продукту"])},
  "onboarding.demo.product_demo.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронюйте безкоштовну демо-сесію та відкрийте для себе ефективне впровадження OKR на різних рівнях."])},
  "onboarding.demo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зустріч з одним з наших експертів"])},
  "onboarding.existed_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратори OKR Board"])},
  "onboarding.invite_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити адміністратора OKR Board"])},
  "onboarding.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримайте огляд ваших OKR і підвищіть рівень знань з OKR з найкращими тренерами."])},
  "onboarding.setup_name.input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть своє ім’я"])},
  "onboarding.setup_name.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо в Oboard!"])},
  "onboarding.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крок"])},
  "onboarding.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпишіться на інформаційне оповіщеня Oboard з основними оновленнями продукту, промо та анонсами майбутніх подій і вебінарів. Приблизно 1 раз на місяць, без спаму."])},
  "onboarding.tutorial.admin_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посібник для налаштування адміністратора"])},
  "onboarding.tutorial.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляньте відеопосібник"])},
  "onboarding.tutorial.user_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посібник користувача"])},
  "onboarding.welcome.rerun_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це виконавча роль, яка дозволяє вам керувати процесом OKR. Ви тепер можете встановлювати цілі, налаштовувати цикли та інші деталі, керувати дозволами та ролями користувачів."])},
  "onboarding.welcome.rerun_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам призначено роль адміністратора!"])},
  "onboarding.welcome.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми раді вітати вас у нашому додатку. Розпочнемо нашу подорож разом."])},
  "onboarding.welcome.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ласкаво просимо, ", _interpolate(_named("userName"))])},
  "organization.create_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва організації буде видно тільки вашій команді. ", _interpolate(_named("break")), "Ви можете змінити її у майбутньому."])},
  "organization.create_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте свою організацію"])},
  "organization.invite_users.email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть одну або кілька адрес електронної пошти, розділених комой або пробілом"])},
  "organization.invite_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити користувачів"])},
  "organization.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва організації"])},
  "organization.name.updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва організації успішно оновлена"])},
  "pagination.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["записів"])},
  "pagination.of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з"])},
  "pagination.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["власників"])},
  "pagination.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати"])},
  "period_mode.auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичний"])},
  "period_mode.auto.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дати початку та завершення призначаються ", _interpolate(_named("break")), " на основі вкладених елементів"])},
  "period_mode.manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручний"])},
  "period_mode.manual.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дати початку та завершення ", _interpolate(_named("break")), " призначаються вручну"])},
  "placeholder.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає результатів"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
  "placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результати пошуку"])},
  "placeholder.search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук користувача"])},
  "release_updates.footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для отримання додаткових оновлень відвідайте:"])},
  "release_updates.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкрити журнал змін"])},
  "reminders.time_update_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час оновити ваш прогрес..."])},
  "roadmap.backlog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорожня карта недоступна для інтервалу \"Черга\""])},
  "roadmap.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
  "roadmap.export.max_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна ширина для зображення:"])},
  "roadmap.export.max_width_item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["• ", _interpolate(_named("app")), " — ", _interpolate(_named("width")), " пікс."])},
  "roadmap.export.safari_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Браузер Safari не повністю підтримує функцію експорту. Будь ласка, використовуйте Chrome або Firefox."])},
  "roadmap.export.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ширина однієї частини:"])},
  "roadmap.export_beta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт дорожньої карти (бета)"])},
  "roadmap.export_option.full.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уся дорожня карта"])},
  "roadmap.export_option.multiple.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати як кілька зображень"])},
  "roadmap.export_option.visible.hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати лише те, що ви бачите на екрані"])},
  "roadmap.export_option.visible.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видима область"])},
  "roadmap.invalid_dates_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку повинна бути меншою за дату завершення"])},
  "roadmap.quarter_shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
  "roadmap.start_and_due_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку та завершення"])},
  "roadmap.updated_timeline_success_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateProp")), " успішно оновлено."])},
  "roadmap.views.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяці"])},
  "roadmap.views.quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартали"])},
  "roadmap.views.weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тижні"])},
  "roles.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратор"])},
  "roles.group_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лідер групи"])},
  "roles.not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вибрано"])},
  "roles.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник"])},
  "roles.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читач"])},
  "roles.suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Призупинений"])},
  "roles.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач"])},
  "roles.user_no_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач/без права видалення"])},
  "roles.workspace_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратор робочого простору"])},
  "saving_indicator.saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережено"])},
  "saving_indicator.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігання…"])},
  "schedule.by_end_of_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до кінця дня"])},
  "schedule.day_before_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День перед терміном виконання"])},
  "schedule.delete_schedule.confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити розклад ", _interpolate(_named("scheduleName")), "?"])},
  "schedule.due_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День виконання"])},
  "schedule.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший розклад для чекінів"])},
  "schedule.filters.frequencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота"])},
  "schedule.frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота"])},
  "schedule.frequency.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота чекінів"])},
  "schedule.frequency.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановіть день, коли всі чекіни мають бути повторені та подані"])},
  "schedule.frequency.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота циклу чекінів і кінцевий термін"])},
  "schedule.frequency_common_combination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Повторювати ", _interpolate(_named("frequency")), " на ", _interpolate(_named("term")), " до кінця дня"])},
  "schedule.frequency_custom_combination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На ", _interpolate(_named("frequency")), " повторювати кожні ", _interpolate(_named("term1")), " на ", _interpolate(_named("term2")), " до кінця дня"])},
  "schedule.groups.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі користувачі, що належать до цих Груп, будуть призначені для участі в чекінах як Власник або Зацікавлена особа."])},
  "schedule.name.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця назва з’явиться в нагадуванні. \nЗробіть її приємною, щоб мотивувати команду"])},
  "schedule.next_step": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Наступний крок: ", _interpolate(_named("stepName"))])},
  "schedule.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає розкладу чекінів"])},
  "schedule.notifications.in_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У додатку (за замовчуванням)"])},
  "schedule.notifications.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть, де ви хочете отримувати сповіщення. Ви можете вибрати кілька варіантів."])},
  "schedule.notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення"])},
  "schedule.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учасники"])},
  "schedule.reached_schedule_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви досягли максимального числа розкладів"])},
  "schedule.reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нагадування"])},
  "schedule.reminder.before_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до кінцевого терміну"])},
  "schedule.reminder.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати нагадування про оновлення"])},
  "schedule.reminder.range.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("unit")), " діапазон від ", _interpolate(_named("min")), " до ", _interpolate(_named("max"))])},
  "schedule.reminders.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виходячи з вибраної частоти, встановіть день, коли всі чекіни мають бути подані"])},
  "schedule.reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нагадування про крайній термін чекінів"])},
  "schedule.schedule_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва розкладу"])},
  "schedule.start_from.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День, з якого почнуться чекіни"])},
  "schedule.subgroups.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підгрупи успадковуватимуть розклад"])},
  "schedule_day_in_month_ordinals": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перший"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другий"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третій"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертий"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останній"])},
    
  ],
  "schedule_frequencies": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щотижнево"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз на два тижні"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щомісяця"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибірковий"])},
    
  ],
  "schedule_ordinals": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перший"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другий"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третій"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертий"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П’ятий"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останній"])},
    
  ],
  "schedules.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити розклад"])},
  "schedules.display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва розкладу"])},
  "schedules.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати розклад"])},
  "schedules.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає розкладів, що відповідають застосованим фільтрам"])},
  "schedules.start_form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чекіни починаються з"])},
  "schedules.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чекіни"])},
  "search_criteria.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути критерії пошуку"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вибрано"])},
  "settings.billing.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежі"])},
  "settings.company.adjust_thresholds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштувати пороги, що представляють статуси, перетягуючи точки на лінії."])},
  "settings.company.alignment_settings_switch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дозволити ", _interpolate(_named("objective")), " і ", _interpolate(_named("keyResult")), " зв'язувати з результатом"])},
  "settings.company.dashboard_switch_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показати ", _interpolate(_named("dashboardName")), " користувачам"])},
  "settings.company.dashboard_visibility_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панелі, що не відмічені, будуть приховані для всіх, окрім адміністраторів."])},
  "settings.company.dashboard_visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ панелі керування"])},
  "settings.company.default_states_okr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінки вкладених цілей рахуються у батьківські."])},
  "settings.company.default_states_okr_switch_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Увімкнути розрахунок для ", _interpolate(_named("entity"))])},
  "settings.company.default_states_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінки вкладених Jira-задач рахуються у батьківські."])},
  "settings.company.default_states_tasks_switch_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Увімкнути розрахунок для ", _interpolate(_named("entity"))])},
  "settings.company.default_states_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стани за замовчуванням"])},
  "settings.company.default_user_role_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час ручного додавання користувача можна вибрати роль, а також змінити її в будь-який час за необхідності."])},
  "settings.company.default_users_role_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль користувача в просторі за замовчуванням"])},
  "settings.company.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування компанії"])},
  "settings.company.homepage_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Можливість перегляду прогресу буде відключена для звичайних користувачів. ", _interpolate(_named("break")), "Лише адміністратор робочого простору та лідери груп зможуть переглядати прогрес."])},
  "settings.company.homepage_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокувати перегляд прогресу інших людей"])},
  "settings.company.okr_privacy_settings_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратори робочого простору можуть керувати приватними елементами в доступних робочих просторах."])},
  "settings.company.ws_admins_allowed_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["усі робочі простори"])},
  "settings.company.ws_admins_manage_permission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дозволити адміністраторам робочої області керувати користувачами та групами в ", _interpolate(_list(0)), "."])},
  "settings.get_email_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувати оновлення електронною поштою про активність OKR"])},
  "settings.learn_more_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнатися більше про сповіщення на електронну пошту"])},
  "settings.private_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватні простори"])},
  "settings.public_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публічні простори"])},
  "settings.roles_in_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі в просторах"])},
  "settings.synchronization.add_custom_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувацьке налаштування"])},
  "settings.synchronization.add_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувацькі налаштування для проєктів"])},
  "settings.synchronization.custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувацькі налаштування"])},
  "settings.synchronization.delete_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувацькі налаштування"])},
  "settings.synchronization.general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основні налаштування"])},
  "settings.synchronization.jira_project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва проєкту Jira"])},
  "settings.synchronization.jira_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проєкти Jira"])},
  "settings.synchronization.jira_replace_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira замінює Oboard"])},
  "settings.synchronization.new_custom_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове користувацьке налаштування"])},
  "settings.synchronization.no_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає користувацьких налаштувань"])},
  "settings.synchronization.not_synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не синхронізовано"])},
  "settings.synchronization.oboard_replace_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard замінює Jira"])},
  "settings.synchronization.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проєкт"])},
  "settings.synchronization.save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування синхронізації"])},
  "settings.synchronization.select.end_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле дати завершення"])},
  "settings.synchronization.select.end_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть поле дати завершення"])},
  "settings.synchronization.select.start_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле дати початку"])},
  "settings.synchronization.select.start_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть поле дати початку"])},
  "settings.synchronization.select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати проєкт"])},
  "settings.synchronization.select_synchronisation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть тип синхронізації"])},
  "settings.synchronization.sync_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронізація триває…"])},
  "settings.synchronization.synchronization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви хочете видалити користувацькі налаштування синхронізації для ", _interpolate(_named("projectName")), "?"])},
  "setup_groups.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Це структура організації за замовчуванням. ", _interpolate(_named("break")), "Ви можете налаштувати її пізніше в розділі налаштувань."])},
  "setup_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте групи"])},
  "setup_workspace.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви зможете перейменувати робочий простір пізніше й додавати більше по мірі розвитку."])},
  "setup_workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте свій робочий простір"])},
  "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
  "stand_with_ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримуйте Україну"])},
  "status.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черга"])},
  "status.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає"])},
  "status.switch_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичний"])},
  "status.switch_auto_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто статус"])},
  "status.switch_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Однакова"])},
  "status.switch_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручний"])},
  "status.switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми змінюватимемо статус на основі прогресу та термінів"])},
  "statuses.close_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закриті статуси"])},
  "statuses.close_statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закриті статуси блокують об'єкт і запобігають редагуванню та зміні оцінки"])},
  "statuses.notifications.renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус успішно перейменовано"])},
  "statuses.page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменуйте статуси для кращого узгодження з вашим робочим процесом."])},
  "statuses.progress_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активні статуси"])},
  "statuses.rename_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати статус"])},
  "statuses.status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва статусу"])},
  "step_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Крок ", _interpolate(_named("count")), "."])},
  "subscription.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "subscription.address_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса, рядок 1"])},
  "subscription.address_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса, рядок 2"])},
  "subscription.amount_due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сума до сплати сьогодні ", _interpolate(_named("value"))])},
  "subscription.annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щорічно"])},
  "subscription.annually_save_count_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щорічно ЗАОЩАДЖУЙТЕ 15%"])},
  "subscription.applied_balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Застосований баланс ", _interpolate(_named("value"))])},
  "subscription.apply_discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Застосувати знижку ", _interpolate(_named("percent"))])},
  "subscription.back_to_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до виставлення рахунків"])},
  "subscription.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса для виставлення рахунку"])},
  "subscription.billing_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цикл виставлення рахунків"])},
  "subscription.billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата виставлення рахунку"])},
  "subscription.bills_on_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рахунок на ", _interpolate(_named("date")), " за 1 місяць"])},
  "subscription.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записатися на зустріч"])},
  "subscription.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізнес імейл"])},
  "subscription.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати підписку"])},
  "subscription.choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати план"])},
  "subscription.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місто"])},
  "subscription.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва компанії"])},
  "subscription.company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмір компанії"])},
  "subscription.compare_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порівняти плани"])},
  "subscription.contact_oboard_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зверніться до команди Oboard"])},
  "subscription.contact_your_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зверніться до свого менеджера"])},
  "subscription.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
  "subscription.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний план"])},
  "subscription.current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний рівень"])},
  "subscription.days_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днів залишилось"])},
  "subscription.discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Знижка ", _interpolate(_named("percent"))])},
  "subscription.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понизити"])},
  "subscription.downgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понизити до річного"])},
  "subscription.drop_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Написати повідомлення"])},
  "subscription.enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
  "subscription.essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential"])},
  "subscription.expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення"])},
  "subscription.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закінчується:"])},
  "subscription.final_amount_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Остаточна сума буде визначена при оформленні"])},
  "subscription.from_to_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " із ", _interpolate(_named("to")), " користувачів"])},
  "subscription.get_your_price_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримайте цінову пропозицію"])},
  "subscription.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
  "subscription.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія рахунків"])},
  "subscription.is_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розпочати підписку"])},
  "subscription.leave_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишитися в будь-якому випадку"])},
  "subscription.leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкода, що ви залишаєте нас"])},
  "subscription.license_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліцензія неактивна"])},
  "subscription.manage_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керувати планом"])},
  "subscription.manage_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керувати рівнем"])},
  "subscription.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щомісячно"])},
  "subscription.next_bill_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата наступного рахунку"])},
  "subscription.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступний платіж"])},
  "subscription.next_payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата наступного платежу"])},
  "subscription.number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість користувачів"])},
  "subscription.number_of_users_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете додавати більше користувачів у будь-який час. За цих додаткових користувачів буде виставлено рахунок автоматично у наступному періоді підписки."])},
  "subscription.payment_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата не завершена. Перевірте баланс або оновіть спосіб оплати."])},
  "subscription.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спосіб оплати"])},
  "subscription.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на місяць"])},
  "subscription.per_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за користувача"])},
  "subscription.per_user_billed_monthly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " користувачів, $", _interpolate(_named("price")), " на користувача, виставляється щомісяця"])},
  "subscription.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону"])},
  "subscription.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярне"])},
  "subscription.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштовий код"])},
  "subscription.professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
  "subscription.receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квитанція"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити"])},
  "subscription.save_count_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Заощаджуйте ", _interpolate(_named("count")), "%"])},
  "subscription.schedule_free_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заплануйте безкоштовну демонстраційну сесію та дізнайтеся, як правильно впровадити OKR на різних рівнях."])},
  "subscription.select_subscription_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть цикл підписки"])},
  "subscription.select_user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати рівень користувача"])},
  "subscription.select_user_tier_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете оновити рівень користувачів у будь-який час. Якщо ви оновите рівень, з вашого облікового запису буде знято пропорційну суму на основі частки періоду виставлення рахунків, що залишилася на момент оновлення рівня."])},
  "subscription.state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штат"])},
  "subscription.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписатися"])},
  "subscription.subscription_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша підписка скасована"])},
  "subscription.subscription_free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безоплатний період"])},
  "subscription.subscription_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписка успішно оновлена"])},
  "subscription.subscription_wasnt_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписка не була оновлена"])},
  "subscription.subscription_will_be_downgraded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Підписка буде понижена до ", _interpolate(_named("name")), " ", _interpolate(_named("cycle")), " в кінці циклу."])},
  "subscription.switch_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити план"])},
  "subscription.switch_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти на річний"])},
  "subscription.switch_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти на місячний"])},
  "subscription.tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідентифікаційний номер платника податків"])},
  "subscription.tier_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість користувачів перевищує обмеження рівня. Оновіть свій рівень або видаліть користувачів"])},
  "subscription.tier_upper_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верхня межа рівня не може бути меншою за поточну кількість користувачів."])},
  "subscription.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підписка"])},
  "subscription.total_pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Загальна сума: ", _interpolate(_named("value"))])},
  "subscription.try_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чому б не спробувати річний план? Заощаджуйте 15%"])},
  "subscription.up_sale_promocode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Будь ласка, розгляньте можливість отримання знижки ", _interpolate(_named("discount")), ", щоб залишитися з нами"])},
  "subscription.up_to_count_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["До ", _interpolate(_named("count")), " користувачів"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "subscription.upgrade_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити рівень"])},
  "subscription.upgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підняти до річного"])},
  "subscription.upgrade_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підняти до місячного"])},
  "subscription.upgrade_your_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновіть свій рівень"])},
  "subscription.upgrade_your_tier_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Кількість користувачів перевищує обмеження рівня. Залишилося ", _interpolate(_named("day")), " день на оновлення рівня або видалення користувачів."]), _normalize(["Кількість користувачів перевищує обмеження рівня. Залишилося ", _interpolate(_named("day")), " днів на оновлення рівня або видалення користувачів."])])},
  "subscription.user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рівень користувачів"])},
  "subscription.user_tier_billed_annually": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рівень користувачів ", _interpolate(_named("label")), ", виставляється щорічно"])},
  "subscription.web_app_only_delete_from_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі лише веб-додатку будуть видалені з Oboard"])},
  "subscription.your_current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш поточний план"])},
  "subscription.your_current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш поточний рівень"])},
  "subscription.your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ім'я"])},
  "support.btn_write_to_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Написати в підтримку"])},
  "support.consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консультації"])},
  "support.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язатися з нами"])},
  "support.define_your_okr.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцініть готовність вашої організації, культуру та здатність ефективно впроваджувати OKR."])},
  "support.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр допомоги"])},
  "support.help_center.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдіть відповіді на загальні питання."])},
  "support.help_center.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відвідати центр"])},
  "support.help_center.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдіть відповіді на загальні питання в нашому центрі допомоги Confluence."])},
  "support.instance_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID вашого аккаунта:"])},
  "support.okr_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR консультація"])},
  "support.okr_consulting.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланувати консультацію"])},
  "support.okr_consulting.link.text.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безкоштовний огляд"])},
  "support.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покращте своє навчання OKR за допомогою тренінгів та воркшопів від консультантів Oboard."])},
  "support.okr_review.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити демо"])},
  "support.onboarding_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онбордінг"])},
  "support.onboarding_call.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланувати дзвінок"])},
  "support.onboarding_call.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почніть свій перший 90-денний цикл OKR з повною підтримкою продукту."])},
  "support.plugin_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версія вашого плагіна:"])},
  "support.response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми намагаємося відповідати на всі запити підтримки протягом 1 робочого дня. Oboard знаходиться в Центральній Європі, і наші офіційні"])},
  "support.security_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програма безпеки в хмарі"])},
  "support.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми також вітаємо пропозиції щодо нашого плагіна, ідеї для функцій та загальні коментарі"])},
  "support.support.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Створіть запит на підтримку тут або надішліть нам листа на ", _interpolate(_named("emailAddress"))])},
  "support.support_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["години підтримки ", _interpolate(_named("hours")), ", Понеділок - П'ятниця (GMT+1)"])},
  "support.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка підтримки"])},
  "support.video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відеоінструкція"])},
  "support.video_guides.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відтворити відео"])},
  "support.video_guides.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляньте наші відеоінструкцію, щоб повноцінно використовувати наш додаток"])},
  "table.no_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає елементів"])},
  "table.no_nested_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає вкладених задач"])},
  "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
  "text_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопійовано в буфер обміну"])},
  "token.copy.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API токен"])},
  "token.copy.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язково скопіюйте ваш новий токен API. Ви не зможете знову побачити цей токен."])},
  "token.copy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш новий токен API"])},
  "token.create.label_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть коротку, запам'ятовувану назву, яку вам буде легко пригадати."])},
  "token.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити токен API"])},
  "token.minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хвилин тому"])},
  "token.toast.revoke_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш токен API було скасовано"])},
  "tokens.create_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити токен"])},
  "tokens.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви можете використовувати токен API для аутентифікації в додатку OKR Board. ", _interpolate(_named("break")), "Ви повинні поводитися з токенами API так само обережно, як із будь-яким іншим паролем. Ви використовували ", _interpolate(_named("usedTokensCount")), " із 10 токенів."])},
  "tokens.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва токена"])},
  "tokens.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви досягли максимальної кількості (", _interpolate(_named("limit")), ") токенів API."])},
  "tokens.no_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени відсутні"])},
  "tokens.no_tokens_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший публічний токен API"])},
  "tokens.token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
  "tour.got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зрозуміло!"])},
  "tour.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "tour.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустити тур"])},
  "tour.step1.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете створити нову ціль."])},
  "tour.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть нову ціль"])},
  "tour.step3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити оцінку"])},
  "tour.step4.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте членів групи та керуйте групами."])},
  "tour.step4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати членів групи"])},
  "tree.fit_content_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристосувати до розміру вмісту"])},
  "tree.legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легенда"])},
  "tree.legend.hide_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сховати відфільтровані елементи"])},
  "tree.legend.not_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не враховувати оцінку в батьківський елемент"])},
  "tree.legend.show_child_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати вкладені елементи"])},
  "tree.legend.show_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати відфільтровані елементи"])},
  "tree.switch_to_compact_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемкнути на компактний вигляд картки"])},
  "tree.switch_to_detailed_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемкнути на детальний вигляд картки"])},
  "units.day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["День"]), _normalize(["Дні"])])},
  "units.hour": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Година"]), _normalize(["Години"])])},
  "units.month": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Місяць"]), _normalize(["Місяці"])])},
  "units.px": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["px"])},
  "units.week": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Тиждень"]), _normalize(["Тижні"])])},
  "unlink_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відв'язати Jira-задачу"])},
  "update.keyResult.result_type_lock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцінку не можна змінити, оскільки вона розраховується на основі вкладених елементів."])},
  "update.keyResult.result_type_lock_message_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб повернутися до ручного режиму, ", _interpolate(_named("action")), " всіх вкладених елементів"])},
  "update.keyResult.result_type_lock_message_description_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вимкніть рахування оцінки "])},
  "user.group.sync.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Група не може бути обрана, оскільки вона імпортує користувачів з пов'язаних груп ", _interpolate(_named("platform")), "."])},
  "user.personal_settings.about_qty_every_months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приблизно ", _interpolate(_named("qty")), " кожні ", _interpolate(_named("repeat")), " місяців"])},
  "user.personal_settings.about_qty_per_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приблизно ", _interpolate(_named("qty")), " на місяць"])},
  "user.personal_settings.dont_have_email_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не маємо доступу до вашої електронної пошти"])},
  "user.personal_settings.mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "mentions"])},
  "user.personal_settings.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші"])},
  "user.personal_settings.please_contact_our": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зверніться до ", _interpolate(_named("contact")), " для отримання додаткової інформації"])},
  "user.personal_settings.product_case_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поради щодо співпраці, кейс-стаді, навчальні матеріали та багато цікавих статей."])},
  "user.personal_settings.product_promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонс акцій, майбутніх подій і вебінарів."])},
  "user.personal_settings.product_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми надішлемо вам новини з оголошенням основних оновлень продукту в Oboard."])},
  "user.personal_settings.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["відповідає"])},
  "user.personal_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особисті налаштування"])},
  "user.personal_settings.when_someone_reply_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли хтось згадує і відповідає вам"])},
  "user.personal_settings.you_are_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви власник"])},
  "user.personal_settings.you_are_stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви підписник"])},
  "user.preferences.change_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
  "user.preferences.default_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Група за замовчуванням"]), _normalize(["Групи за замовчуванням"])])},
  "user.preferences.no_groups_for_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не належите до жодної групи в робочому просторі"])},
  "user.preferences.user.email.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
  "user.preferences.user.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я користувача"])},
  "user.selected_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрані групи"])},
  "user_details.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до іншого робочого простору"])},
  "user_details.add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до робочого простору"])},
  "user_details.add_user.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Додати користувача до робочого простору ", _interpolate(_named("break")), " зі специфічною групою"])},
  "user_details.delete_user_from_synced_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб видалити користувача, видаліть його з відповідної синхронізованої групи ", _interpolate(_named("platform")), "."])},
  "user_details.delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб видалити користувача з ", _interpolate(_named("workspaceName")), ", видаліть його з відповідної синхронізованої групи ", _interpolate(_named("platform")), "."])},
  "user_details.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей користувач не перебуває у жодному робочому просторі"])},
  "user_details.platform_profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Профіль ", _interpolate(_named("platform"))])},
  "user_details.select_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати групи"])},
  "user_menu.create_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову організацію"])},
  "user_menu.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
  "user_menu.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
  "user_menu.switch_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити організацію"])},
  "users.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати ще одного користувача"])},
  "users.add_to_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Додати користувача до групи"]), _normalize(["Додати користувачів до груп"])])},
  "users.add_to_group_userName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Додати ", _interpolate(_named("userName")), " до групи"]), _normalize(["Додати ", _interpolate(_named("userName")), " до груп"])])},
  "users.add_to_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Додати користувача до робочого простору"]), _normalize(["Додати користувачів до робочого простору"])])},
  "users.add_to_workspace_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Користувача буде додано без жодної групи"]), _normalize(["Користувачів буде додано без жодної групи"])])},
  "users.add_users_to_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Додати вибраного користувача до робочого простору"]), _normalize(["Додати ", _interpolate(_named("count")), " вибраних користувачів до робочого простору"])])},
  "users.add_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати робочий простір"])},
  "users.all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі простори"])},
  "users.bulk_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групове додавання"])},
  "users.change_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити групу за замовчуванням"])},
  "users.change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити роль"])},
  "users.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати користувача"])},
  "users.delete_user_from_plugin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб видалити обраних користувачів, видаліть їх з відповідної синхронізованої групи ", _interpolate(_named("platform")), "."])},
  "users.import_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Імпортувати користувачів з групи Jira один раз. Імпортованих користувачів можна видалити вручну в будь-який час"])},
  "users.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити користувача"])},
  "users.link_jira_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати групи Jira"])},
  "users.link_jira_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пов'язати групу Jira з OKR board. Усі зміни групи Jira автоматично з'являться в групі OKR board. Пов'язаних користувачів не можна видалити вручну"])},
  "users.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає користувачів, які відповідають застосованим фільтрам."])},
  "users.roles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі"])},
  "users.roles.total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("qty")), " ролей"])},
  "users.select_import_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати опції імпорту"])},
  "users.select_jira_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть групи із Jira"])},
  "users.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
  "users.table_header_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
  "validation.input_number.allowed_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дозволено ", _interpolate(_named("integerMaxLength")), " символа"])},
  "validation.input_number.allowed_max_characters_before_dot": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дозволено ", _interpolate(_named("integerMaxLength")), " символів перед крапкою"])},
  "validation.input_number.allowed_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволені числа, крапка, кома"])},
  "validation.input_number.allowed_one_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволена одна крапка"])},
  "validation.input_number.allowed_one_minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволено один мінус"])},
  "validation.input_number.integer_begins_with_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціле число починається з нуля"])},
  "view_select.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ієрархія"])},
  "view_select.explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список"])},
  "view_select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список"])},
  "view_select.roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорожня карта"])},
  "weights.objective.cant_change_weight_by_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб використовувати власні ваги, вам потрібно мати принаймні один вкладений елемент."])},
  "weights.objective.cant_change_weight_by_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тарифному плані Professional або вище."])},
  "weights.objective.cant_change_weight_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви не можете змінити ваги в ", _interpolate(_named("status")), " цілі. \nВідкройте ціль для редагування."])},
  "weights.objective.manual_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручний режим."])},
  "workspace.change_role": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Змінити роль для користувача"]), _normalize(["Змінити роль для ", _interpolate(_named("qty")), " користувачів"])])},
  "workspace.label.add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати опис"])},
  "workspace.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "workspace.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
  "workspace.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва робочого простору"])},
  "workspace.select_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть часовий пояс"])},
  "workspace.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовий пояс"])},
  "workspaces.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дія"])},
  "workspaces.add_users_to_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Додати користувачів до ", _interpolate(_named("group"))])},
  "workspaces.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити простір"])},
  "workspaces.create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити групу"])},
  "workspaces.delete_user_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви впевнені, що хочете видалити ", _interpolate(_named("userName")), " з групи ", _interpolate(_named("groupName")), "?"])},
  "workspaces.edit_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати робочий простір"])},
  "workspaces.groups_tab.linked_groups.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи, звідки ми отримуємо список користувачів для додавання в плагін."])},
  "workspaces.groups_tab.linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пов'язані групи ", _interpolate(_named("platform"))])},
  "workspaces.intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервали"])},
  "workspaces.linked_user_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пов'язаних користувачів не можна видалити вручну"])},
  "workspaces.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керувати робочими просторами"])},
  "workspaces.manage_workspace.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управління доступом до робочого простору та ролями для всіх користувачів"])},
  "workspaces.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва робочого простору"])},
  "workspaces.new_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий робочий простір"])},
  "workspaces.no_groups.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть групу, щоб додати користувачів у робочий простір"])},
  "workspaces.no_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поки що немає груп"])},
  "workspaces.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочих просторів не знайдено"])},
  "workspaces.no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас немає прав на створення нового робочого простору. Зверніться до адміністратора"])},
  "workspaces.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочих просторів ще немає"])},
  "workspaces.no_workspaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створіть перший робочий простір, щоб розпочати"])},
  "workspaces.page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть робочий простір для додавання користувачів, груп та інтервалів."])},
  "workspaces.private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватний"])},
  "workspaces.public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публічний"])},
  "workspaces.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль у просторі"])},
  "workspaces.select_linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Виберіть пов'язані групи ", _interpolate(_named("platform"))])},
  "workspaces.select_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Виберіть групи ", _interpolate(_named("platform"))])},
  "workspaces.table.cell.groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " група"]), _normalize([_interpolate(_named("count")), " групи"])])},
  "workspaces.table.cell.intervals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " інтервал"]), _normalize([_interpolate(_named("count")), " інтервалів"])])},
  "workspaces.table.cell.users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " користувач"]), _normalize([_interpolate(_named("count")), " користувачів"])])},
  "workspaces.users_from_platform": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Користувачі з пов'язаних груп ", _interpolate(_named("platform"))])},
  "workspaces.visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
  "workspaces.workspace_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий простір створено."])},
  "workspaces.workspace_field_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " успішно оновлено"])},
  "workspaces.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочі простори"])},
  "wysiwyg.menu_list.blockquote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цитата"])},
  "wysiwyg.menu_list.bullet_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркований список"])},
  "wysiwyg.menu_list.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фрагмент коду"])},
  "wysiwyg.menu_list.format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат"])},
  "wysiwyg.menu_list.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
  "wysiwyg.menu_list.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зображення"])},
  "wysiwyg.menu_list.insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставити"])},
  "wysiwyg.menu_list.numbered_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нумерований список"])},
  "wysiwyg.menu_list.subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підзаголовок"])},
  "wysiwyg.menu_list.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблиця"])},
  "wysiwyg.menu_list.task_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список Jira-задач"])}
}