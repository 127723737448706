<template>
  <FormFieldNext
    v-if="isBinary"
    :disabled="resultTypeSettingsDisabledStates.switch"
    :label="$t('field.status.title')"
  >
    <AppRadioGroup
      :disabled="resultTypeSettingsDisabledStates.switch"
      :model-value="formModel.binaryValue"
      :name="`binary-type-${uniqueId}`"
      :options="getBinaryOptions"
      align-width
      class="ljiBinarySwitch"
      full-width
      size="large"
      style="--fill: #dfe1e6; --option-padding: 4px 12px 4px 6px"
      type="primary-next"
      @update:model-value="onBinaryValueUpdate"
    >
      <template #item-label="{ item }">
        <AppRadioGroupNextOptionWithIcon :option="item" centered gap="8px" />
      </template>
    </AppRadioGroup>
  </FormFieldNext>

  <template v-else>
    <AppInputNumberNext
      :key="`original-value-field-${fieldTypeId}`"
      :digit-max-length="12"
      :fraction="2"
      :label="$t('create.keyResult.label.originalValue')"
      :model-value="getOriginalValue"
      :readonly="resultTypeSettingsDisabledStates.input"
      :suffix="getSuffix"
      allow-negative
      data-auto-testid="original-value-field"
      data-testid="original-value-field"
      return-value-as-string
      @blur="onOriginalValueBlur"
      @update:model-value="onOriginalValueUpdate"
    />

    <AppInputNumberNext
      v-if="isEdit"
      :key="`current-value-field-${fieldTypeId}`"
      ref="current"
      :digit-max-length="12"
      :fraction="2"
      :label="$t('create.keyResult.label.currentValue')"
      :model-value="getCurrentValue"
      :readonly="resultTypeSettingsDisabledStates.input"
      :suffix="getSuffix"
      allow-negative
      data-auto-testid="current-value-field"
      return-value-as-string
      @blur="onCurrentValueBlur"
      @update:model-value="onCurrentValueUpdate"
    />

    <AppInputNumberNext
      :key="`target-value-field-${fieldTypeId}`"
      :digit-max-length="12"
      :fraction="2"
      :label="$t('create.keyResult.label.targetValue')"
      :model-value="getTargetValue"
      :readonly="resultTypeSettingsDisabledStates.input"
      :suffix="getSuffix"
      allow-negative
      data-auto-testid="target-value-field"
      data-testid="target-value-field"
      return-value-as-string
      @blur="onTargetValueBlur"
      @update:model-value="onTargetValueUpdate"
    />
  </template>
</template>

<script setup>
import { isNull } from 'lodash'
import { computed, nextTick, onMounted, ref, useId } from 'vue'

import { BINARY_OPTIONS } from '@/utils/components-configurations/okr-grade'
import { KR_VALUE_TYPES } from '@/utils/metrics-helpers'
import {
  DEFAULT_CURRENT_VALUE,
  DEFAULT_ORIGINAL_VALUE,
  DEFAULT_TARGET_VALUE,
  getResultTypeSettingsDisabledStates
} from '@/utils/okr-element-values'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'

defineOptions({
  name: 'ResultTypeSettingsDefaultRightSide',
  inheritAttrs: false
})
const props = defineProps({
  isBinary: {
    type: Boolean,
    default: false
  },

  isEdit: {
    type: Boolean,
    default: false
  },

  editable: {
    type: Boolean,
    default: false
  },

  valuesAreReadonly: {
    type: Boolean,
    default: false
  },

  isLockedOnPercent: {
    type: Boolean,
    default: false
  },

  isBinarySelectDisabled: {
    type: Boolean,
    default: false
  },

  formModel: {
    type: Object,
    default: () => ({})
  },

  originalValue: {
    type: [Number, String],
    default: 0
  },

  currentValue: {
    type: [Number, String],
    default: 0
  },

  targetValue: {
    type: [Number, String],
    default: 0
  },

  isAutoMode: {
    type: Boolean,
    default: false
  },

  fieldTypeId: {
    type: [String, Number],
    default: KR_VALUE_TYPES.START_TARGET
  },

  unit: {
    type: String,
    default: ''
  }
})

const uniqueId = useId()

const emit = defineEmits({
  'update:binaryValue': null,
  'update:originalValue': null,
  'blur:originalValue': null,
  'update:currentValue': null,
  'blur:currentValue': null,
  'update:targetValue': null,
  'blur:targetValue': null
})

onMounted(() => {
  if (props.isEdit && !props.valuesAreReadonly && !props.isBinary && !props.isAutoMode) {
    setFocusOnCurrent()
  }
})
const current = ref(null)
const setFocusOnCurrent = () => {
  // nextTick and setTimeout(both!) are needed to make input always focused on modal window
  // opening because modal window(o-modal) has transition of opacity with 0.2s
  nextTick(() => {
    setTimeout(() => {
      if (current.value) {
        current.value.selectAll()
      }
    }, 100)
  })
}

const getOriginalValue = computed(() => {
  return isNull(props.originalValue) ? DEFAULT_ORIGINAL_VALUE : props.originalValue // after link jira-issue in autoMode we get null from backend
})
const getCurrentValue = computed(() => {
  return isNull(props.currentValue) ? DEFAULT_CURRENT_VALUE : props.currentValue // after link jira-issue in autoMode we get null from backend
})
const getTargetValue = computed(() => {
  return isNull(props.targetValue) ? DEFAULT_TARGET_VALUE : props.targetValue // after link jira-issue in autoMode we get null from backend
})
const onBinaryValueUpdate = value => {
  emit('update:binaryValue', value)
}
const onOriginalValueUpdate = value => {
  emit('update:originalValue', value)
}
const onOriginalValueBlur = () => {
  emit('blur:originalValue')
}
const onCurrentValueUpdate = value => {
  emit('update:currentValue', value)
}
const onCurrentValueBlur = () => {
  emit('blur:currentValue')
}
const onTargetValueUpdate = value => {
  emit('update:targetValue', value)
}
const onTargetValueBlur = value => {
  emit('blur:targetValue', value)
}

const resultTypeSettingsDisabledStates = computed(() => {
  const { select, isSwitch, input } = getResultTypeSettingsDisabledStates({
    isEdit: props.isEdit,
    editable: props.editable,
    isLockedOnPercent: props.isLockedOnPercent,
    isBinarySelectDisabled: props.isBinarySelectDisabled,
    valuesAreReadonly: props.valuesAreReadonly
  })
  return {
    select,
    switch: isSwitch,
    input
  }
})

const getBinaryOptions = computed(() => {
  return BINARY_OPTIONS
})

const getSuffix = computed(() => {
  if (props.isLockedOnPercent) {
    return '%'
  }

  if (props.unit) {
    // adding escape character for each character in unit because it can contain special characters
    return `${props.unit.replace(/./g, '\\$&')}`
  }

  return ''
})
</script>

<style scoped></style>
