<script setup>
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'OkrElementWarningIcon'
})

defineProps({
  okrElement: {
    type: Object,
    required: true
  }
})
</script>

<template>
  <AppIcon
    v-if="!!okrElement.warningIds?.length"
    v-tippy="{
      content: $t('okr_element.conflicts'),
      placement: 'top',
      theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.TEXT_CENTER}`
    }"
    class="oew-WarningIcon"
    height="24"
    icon-name="info-error"
    width="24"
  />
</template>

<style lang="scss" scoped>
.oew-WarningIcon {
  flex: 0 0 auto;
  margin: var(--warning-icon-margin, 0 0 0 auto);
}
</style>
