<template>
  <AppDroplist
    v-if="!readonly"
    :dropdown-min-width="540"
    :model-value="openDialog"
    :to-selector="toSelector"
    append-to="body"
    max-width="640px"
    position="bottom"
    show-on-init
    trigger="manual"
    @close="onClose"
    @hidden="onHidden"
    @open="onOpen"
    @opened="onDialogOpened"
  >
    <div v-click-away="onClickOutsideContent" class="gs-Content">
      <FormFieldNext v-if="isAutoMode && isTask">
        <div class="gs-AutoModeInfoWrapper">
          <div class="gs-AutoModeLeftSide">
            <AppIcon class="lji-JiraIcon" height="48" icon-name="jira-icon" width="48" />

            <span class="gs-AutoModeLeftSide_Text">
              {{ $t('jira.auto_grade.description') }}
            </span>
          </div>
          <div class="gs-AutoModeRightSide" />
        </div>
      </FormFieldNext>
      <div
        :class="{ 'gs-ContentWrapper-withoutContentValues': !isVisibleContentValues }"
        class="gs-ContentWrapper"
      >
        <div v-if="isVisibleContentValues" class="gs-ValuesWrapper">
          <div class="gs-Content_Values">
            <!--          <AppInputNumberNext-->
            <!--            ref="completeInput"-->
            <!--            v-model="localGradeDisplayValue"-->
            <!--            :digit-max-length="3"-->
            <!--            :label="$t('objective.complete')"-->
            <!--            :readonly="!hasValueTypePercent"-->
            <!--            allow-negative-->
            <!--            suffix="%"-->
            <!--          />-->

            <template v-if="!hasValueTypeBinary">
              <AppInputNumberNext
                :digit-max-length="12"
                :fraction="2"
                :label="$t('create.keyResult.label.originalValue')"
                :model-value="getOriginalValue"
                :suffix="getPercentSuffix"
                allow-negative
                data-auto-testid="start-value"
                readonly
              />
              <AppInputNumberNext
                ref="currentValueInput"
                v-model="getLocalCurrentValue"
                :digit-max-length="12"
                :fraction="2"
                :label="$t('create.keyResult.label.currentValue')"
                :suffix="getPercentSuffix"
                allow-negative
                data-auto-testid="current-value"
              />
              <AppInputNumberNext
                ref="targetValueInput"
                :digit-max-length="12"
                :fraction="2"
                :label="$t('create.keyResult.label.targetValue')"
                :model-value="getTargetValue"
                :suffix="getPercentSuffix"
                allow-negative
                data-auto-testid="target-value"
                data-testid="target-value-field"
                readonly
              />
            </template>

            <FormFieldNext v-else :label="$t('field.status.title')">
              <AppRadioGroup
                :model-value="localCurrentValue"
                :options="getBinaryOptions"
                data-auto-testid="binary-switch"
                name="binary-type"
                size="large"
                style="--fill: #dfe1e6; --option-padding: 4px 12px 4px 6px"
                type="primary-next"
                @update:model-value="changeBinaryValue"
              >
                <template #item-label="{ item }">
                  <AppRadioGroupNextOptionWithIcon :option="item" gap="8px" />
                </template>
              </AppRadioGroup>
            </FormFieldNext>
          </div>
          <OkrGradeValue :grade="localGradeDisplayValue" data-auto-testid="grade" />
        </div>

        <div class="gs-Content_Footer">
          <div v-if="isTask" class="gs-AutoModeWrapper">
            <AppTag
              :content="objective.jiraStatus"
              :content-background="objective.jiraStatusColor?.background"
              :content-color="objective.jiraStatusColor?.text"
            />
            <AppSelect
              :model-value="localGradeMode"
              :offset="[0, 0]"
              :options="gradeModeOptions"
              append-to="parent"
              class="gs-AutoModeSelect"
              dropdown-width="200px"
              @update:model-value="onUpdateGradeMode"
            >
              <template #option-label="{ option }">
                <div class="gs-AutoModeSelectLabel">
                  <AppIcon
                    :icon-name="option.icon"
                    class="gs-AutoModeSelect-LabelIcon"
                    height="24"
                    width="24"
                  />
                  {{ option.label }}
                </div>
              </template>
              <template #button="{ option, active }">
                <div
                  v-if="option"
                  :class="{ 'gs-SelectButton-active': active }"
                  class="gs-SelectButton"
                >
                  <AppIcon
                    :icon-name="option.icon"
                    class="gs-AutoModeSelect-ButtonIcon"
                    height="24"
                    width="24"
                  />
                  {{ option.label }}
                  <AppIcon
                    class="gs-SelectChevron"
                    height="24"
                    icon-name="arrow-down-black-next"
                    width="24"
                  />
                </div>
              </template>
            </AppSelect>
          </div>
          <StatusSelect
            v-else
            :grade="+localGradeValue"
            :objective="objectiveForStatus"
            append-to="parent"
            autocalculate-toggle
            data-auto-testid="status-switch"
            outline-button-style
            @update:confidence-level-id="onUpdateLocalConfidenceLevelId"
          >
            <template #button="{ option }">
              <ObjectiveStatus
                :status="option.value"
                class="gs-ObjectiveStatus"
                data-auto-testid="status-switch-trigger"
              >
                <template #default="{ label }">
                  <span class="gs-GradeSelectText">{{ label }}</span>
                </template>
                <template #after>
                  <AppIcon height="20" icon-name="arrow-down-black-next" width="20" />
                </template>
              </ObjectiveStatus>
            </template>
          </StatusSelect>

          <div class="gs-FooterActions">
            <AppButton data-auto-testid="cancel-button" type="ghost-next" @click="cancelEditValue">
              {{ $t('action.cancel') }}
            </AppButton>

            <AppButton
              :loading="isLoading"
              data-auto-testid="confirm-button"
              type="primary-next"
              @click="save"
            >
              {{ $t('action.confirm') }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </AppDroplist>
</template>

<script>
import { vOnClickOutside } from '@vueuse/components'
import { isNull } from 'lodash'
import { defineComponent } from 'vue'

import ObjectivesApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  trackManualUpdateGradeEvent
} from '@/tracking/amplitude-helpers'
import { BINARY_OPTIONS } from '@/utils/components-configurations/okr-grade'
import { isEnter } from '@/utils/key-codes'
import { KR_VALUE_TYPES } from '@/utils/metrics-helpers'
import { TYPE_ID_NAMES } from '@/utils/objective-types'
import { objectiveIsJiraTask, OKR_STATUSES, ALL_STATUS_OPTIONS, isKR } from '@/utils/objectives'
import {
  AUTO_GRADE_OPTION_VALUE,
  DEFAULT_CURRENT_VALUE,
  DEFAULT_ORIGINAL_VALUE,
  DEFAULT_TARGET_VALUE,
  GRADE_MODES,
  isBinaryProgress,
  isNumberWithUnitProgress
} from '@/utils/okr-element-values'
import { calculateGradeValue, getGradeDisplayValue } from '@/utils/okr-elements/grade'
// import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrGradeValue from '@/components/form/OkrGradeValue'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import StatusSelect from '@/components/objectives/status-select/StatusSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTag from '@/components/ui/AppTag/AppTag'

const objectivesApi = new ObjectivesApiHandler()

export default defineComponent({
  name: 'GradeSelect',

  directives: [vOnClickOutside],

  components: {
    ObjectiveStatus,
    AppTag,
    OkrGradeValue,
    AppDroplist,
    AppSelect,
    AppIcon,
    AppButton,
    FormFieldNext,
    AppRadioGroupNextOptionWithIcon,
    AppInputNumberNext,
    AppRadioGroup,
    StatusSelect
  },

  props: {
    readonly: {
      type: Boolean
    },

    objective: {
      type: Object,
      required: true
    },

    // showMultiplier: {
    //   type: Boolean,
    //   default: true
    // },

    toSelector: {
      type: String,
      default: null
    },

    show: {
      type: Boolean
    }
  },

  emits: {
    'update:is-loading': null,
    'error-on-save': null,
    save: null,
    // click: null,
    'update:show': null
  },

  data() {
    return {
      // uid: uid(),
      localFieldTypeId: this.objective.fieldTypeId,
      localGradeValue: this.objective.gradeToUse,
      localCurrentValue: this.objective.currentValue,

      openDialog: this.show,
      localConfidenceLevelId: this.objective.confidenceLevelId || OKR_STATUSES.AUTO,
      localAutomaticConfidenceLevelId:
        this.objective.automaticConfidenceLevelId || OKR_STATUSES.AUTO,

      /* v-click-away on dialog content is triggered also on dialog opening, `dialogIsOpened`
       * is used to recognize opening and other cases
       */
      dialogIsOpened: false,
      // editInitialised: false,

      isLoading: false,
      localGradeMode: this.objective.gradeMode
    }
  },

  computed: {
    isVisibleContentValues() {
      return this.isTask ? !this.isAutoMode : true
    },

    isAutoMode() {
      return this.localGradeMode === GRADE_MODES.AUTO
    },

    getPercentSuffix() {
      return this.hasValueTypePercent ? '%' : ''
    },

    getTargetValue() {
      return isNull(this.objective.targetValue) ? DEFAULT_TARGET_VALUE : this.objective.targetValue // after link jira-issue in autoMode we get null from backend
    },

    getOriginalValue() {
      return isNull(this.objective.originalValue)
        ? DEFAULT_ORIGINAL_VALUE
        : this.objective.originalValue // after link jira-issue in autoMode we get null from backend
    },

    getLocalCurrentValue: {
      get() {
        return isNull(this.localCurrentValue) ? DEFAULT_CURRENT_VALUE : this.localCurrentValue // after link jira-issue in autoMode we get null from backend
      },

      set(newValue) {
        this.localCurrentValue = newValue
      }
    },

    gradeModeOptions() {
      return [
        { label: this.$t('jira.auto_grade'), value: GRADE_MODES.AUTO, icon: 'jira-icon' },
        {
          label: this.$t('jira.manual_grade'),
          value: GRADE_MODES.MANUAL,
          icon: 'period-mode-manual-next'
        }
      ]
    },

    isTask() {
      return objectiveIsJiraTask(this.objective)
    },

    isKR() {
      return isKR(this.objective)
    },

    getBinaryOptions() {
      return BINARY_OPTIONS
    },

    hasValueTypePercent() {
      return isNumberWithUnitProgress({
        element: {
          ...this.objective,
          fieldTypeId: this.localFieldTypeId
        }
      })
    },

    hasValueTypeBinary() {
      return isBinaryProgress({
        element: {
          ...this.objective,
          fieldTypeId: this.localFieldTypeId
        }
      })
    },

    localGradeDisplayValue() {
      return getGradeDisplayValue(this.localGradeValue)
    },

    // localGradeDisplayValue: {
    // get() {
    //   return getGradeDisplayValue(this.localGradeValue)
    // }

    // set(newValue) {
    //   this.localGradeValue = newValue
    //   this.calculateCurrentValue()
    // }
    // },

    objectiveForStatus() {
      return {
        ...this.objective,
        confidenceLevelId: this.localConfidenceLevelId,
        automaticConfidenceLevelId: this.localAutomaticConfidenceLevelId
      }
    }

    // color() {
    //   return this.objective.gradeColor
    // }
  },

  watch: {
    openDialog(newValue) {
      if (!newValue) {
        this.dialogIsOpened = false
      }
      this.localGradeMode = this.objective.gradeMode
      this.$emit('update:show', newValue)
    },

    'objective.automaticConfidenceLevelId': function automaticConfidenceChanged(
      newValue,
      oldValue
    ) {
      if (oldValue !== newValue && newValue !== this.localAutomaticConfidenceLevelId) {
        this.localAutomaticConfidenceLevelId = newValue
      }
    },

    'objective.confidenceLevelId': function automaticConfidenceChanged(newValue, oldValue) {
      if (oldValue !== newValue && newValue !== this.localConfidenceLevelId) {
        this.localConfidenceLevelId = newValue
      }
    },

    show(newValue) {
      this.openDialog = newValue
    },

    localCurrentValue: {
      handler(newValue) {
        if (this.localConfidenceLevelId !== OKR_STATUSES.NOT_STARTED) {
          this.localGradeValue = calculateGradeValue(
            this.getTargetValue,
            this.getOriginalValue,
            newValue
          )
        }
        this.$refs.completeInput?.updateMask()
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', this.onDocumentKeydown)
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeydown)
  },

  methods: {
    onUpdateLocalConfidenceLevelId(value) {
      this.localConfidenceLevelId = value
    },

    onUpdateGradeMode(value) {
      this.localGradeMode = value
      this.localFieldTypeId =
        this.objective.fieldTypeId === AUTO_GRADE_OPTION_VALUE
          ? KR_VALUE_TYPES.START_TARGET
          : this.objective.fieldTypeId || KR_VALUE_TYPES.START_TARGET
    },

    cancelEditValue() {
      this.dialogIsOpened = false
      this.openDialog = false

      this.localFieldTypeId = this.objective.fieldTypeId
      this.localGradeValue = this.objective.gradeToUse
      this.localCurrentValue = this.objective.currentValue
      this.localConfidenceLevelId = this.objective.confidenceLevelId || OKR_STATUSES.AUTO
      this.localAutomaticConfidenceLevelId =
        this.objective.automaticConfidenceLevelId || OKR_STATUSES.AUTO
    },

    changeBinaryValue(value) {
      this.localCurrentValue = value
      this.localGradeValue = calculateGradeValue(
        this.getTargetValue,
        this.getOriginalValue,
        this.localCurrentValue
      )
    },

    // calculateCurrentValue() {
    //   const formula =
    //     this.objective.originalValue +
    //     ((this.getTargetValue - this.getOriginalValue) * this.localGradeValue) / 100
    //   if (this.getTargetValue - this.getOriginalValue > 100) {
    //     this.localCurrentValue = Math.round(formula)
    //   } else {
    //     this.localCurrentValue = +formula.toFixed(2)
    //   }
    //
    //   this.$refs.currentValueInput?.updateMask()
    // },

    async save() {
      this.isLoading = true
      this.$emit('update:is-loading', true)
      let payload = {
        elementId: this.objective.id,
        parentId: this.objective.parentId,
        jiraIssueId: this.objective.jiraIssueId,
        currentValue: +this.localCurrentValue,
        originalValue: this.getOriginalValue,
        targetValue: this.getTargetValue,
        confidenceLevelId: this.localConfidenceLevelId,
        workspaceId: this.objective.workspaceId,
        dueDate: this.objective.dueDate,
        elementStartDate: this.objective.elementStartDate,
        fieldTypeId: this.localFieldTypeId
      }
      if (this.isTask) {
        payload = {
          ...payload,
          gradeMode: this.localGradeMode
        }
      }
      if (this.localGradeMode === GRADE_MODES.AUTO && this.isTask) {
        payload = {
          ...payload,
          currentValue: undefined,
          originalValue: undefined,
          targetValue: undefined
        }
      }
      try {
        const result = await objectivesApi.updateOkrElement(payload)

        if (this.localConfidenceLevelId !== this.objective.confidenceLevelId) {
          const statusValue = ALL_STATUS_OPTIONS.map(option => ({
            ...option,
            label: this.$t(option.label)
          })).find(item => item.value === this.localConfidenceLevelId)
          tracker.logEvent('Changed okr status', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            label: 'kr',
            source: EVENT_SOURCES.GRID,
            value: this.localConfidenceLevelId ? statusValue.label : 'auto'
          })
        }

        this.$emit('save', result)

        if (this.isKR || (this.localGradeMode !== GRADE_MODES.AUTO && this.isTask)) {
          const confidenceLevel = this.isKR
            ? this.localConfidenceLevelId
            : this.objective.jiraStatus

          let value = payload.currentValue

          if (this.hasValueTypeBinary) {
            value = payload.currentValue * 100 // for convert boolean to percent
          }

          trackManualUpdateGradeEvent({
            confidenceLevel,
            value,
            source: EVENT_SOURCES.GRID,
            id: this.objective.id,
            issueType: TYPE_ID_NAMES[this.objective.typeId]
          })
        }

        this.dialogIsOpened = false
        this.openDialog = false
      } catch (e) {
        this.$emit('error-on-save', e)
      } finally {
        this.isLoading = false
        this.$emit('update:is-loading', false)
      }
    },

    onOpen() {
      this.dialogIsOpened = true
      this.localFieldTypeId = this.objective.fieldTypeId
      this.localGradeValue = calculateGradeValue(
        this.getTargetValue,
        this.getOriginalValue,
        this.objective.currentValue
      )
      this.localCurrentValue = this.objective.currentValue
      this.$refs.completeInput?.updateMask()
      this.$refs.currentValueInput?.updateMask()
      this.$refs.targetValueInput?.updateMask()
    },

    onDialogOpened() {
      // for start/target type focus on current value
      if ([KR_VALUE_TYPES.START_TARGET].includes(this.localFieldTypeId)) {
        this.$refs.currentValueInput?.focus()
        this.$refs.currentValueInput?.selectAll()
      }
    },

    onHidden() {
      if (this.dialogIsOpened) {
        this.dialogIsOpened = false
        this.openDialog = false
      }
    },

    onClose() {
      this.localGradeValue = this.objective.gradeToUse
      this.localCurrentValue = this.objective.currentValue
      this.localConfidenceLevelId = this.objective.confidenceLevelId || OKR_STATUSES.AUTO
      this.localAutomaticConfidenceLevelId =
        this.objective.automaticConfidenceLevelId || OKR_STATUSES.AUTO
    },

    onDocumentKeydown(event) {
      const eventCode = event.keyCode ? event.keyCode : event.which
      // 13 is Enter
      if (this.openDialog && isEnter(eventCode)) {
        this.save()
      }
    },

    // onClickOutsideContent(event) {
    //   // click outside closes grade dialog, but ignore status dropdown that is outside of dialog
    //   if (this.openDialog) {
    //     if (this.dialogIsOpened && !event.target.matches(`.gs-StatusDropdown-${this.uid} *`)) {
    //       this.openDialog = false
    //     } else {
    //       this.dialogIsOpened = true
    //       this.$refs.droplist.show()
    //     }
    //   }
    // }

    onClickOutsideContent(e) {
      if (this.openDialog) {
        if (
          Array.from(e.composedPath()).some(item => item.classList?.contains('gc-Grade-updatable'))
        ) {
          return
        }
        this.cancelEditValue()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.gs-Content {
  :deep(.ain-Input:read-only) {
    color: $dark-3;
  }

  :deep(.ain-Wrapper) {
    max-width: 100px; // need when we in binary mode
  }
}
.gs-ContentWrapper {
  min-width: 500px;
  padding: 20px;
  &-withoutContentValues {
    padding-top: 0;
    .gs-Content_Footer {
      margin-top: 0;
    }
  }
}

.gs-Content_Values {
  display: flex;
  gap: 20px;
}

.gs-ValuesWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 44px;
}

.gs-Content_Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding-top: 17px;
  box-shadow: inset 0 1px 0 $grey-2-next;
}

.gs-FooterActions {
  display: inherit;
  gap: inherit;
}

$max-width-container: 380px;

.gs-AutoModeWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: $max-width-container;
}

.gs-GradeSelectText {
  max-width: $max-width-container;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gs-AutoModeSelect-ButtonIcon {
  margin: 0 4px 0 0;
}
.gs-SelectButton {
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('bold');
  font-family: $system-ui;
  color: $dark-2;
  cursor: pointer;
  display: flex;
  align-items: center;

  &-active {
    color: $primary-color-next;
  }
}
.gs-SelectChevron {
  flex: 0 0 auto;
  transition: transform $transition-fast ease-in-out;
  .gs-SelectButton-active & {
    transform: rotate(180deg);
    color: $primary-color-next;
  }
}
.gs-AutoModeSelect-LabelIcon {
  color: $dark-3;
}
.gs-AutoModeSelect-ButtonIcon {
  color: $dark-2;
}

.gs-AutoModeSelectLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}
.gs-AutoModeInfoWrapper {
  display: flex;
  justify-content: space-between;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  width: 100%;
  gap: 15px;
}
.gs-AutoModeLeftSide {
  display: flex;
  margin: 8px 0 18px 8px;
  gap: 8px;
}
.gs-AutoModeLeftSide_Text {
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  max-width: 280px;
  white-space: pre-line;
  margin: 12px 0 0 0;
}
.gs-AutoModeRightSide {
  display: flex;
  background: top right url('~@/assets/images/jira-in-progress.svg') no-repeat, $white;
  width: 140px;
  flex: 0 0 auto;
  border: 1px solid $grey-3-next;
  border-radius: $border-radius-sm-next;
  position: relative;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    right: -1px;
    bottom: -1px;
    width: calc(100% + 1px);
    height: calc(100% - 28px);
    background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 55px, $white 100%);
    border-radius: 0 0 $border-radius-sm-next $border-radius-sm-next;
  }
}
.gs-ObjectiveStatus {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0;
  padding-left: 4px;
}
</style>
