<template>
  <template v-if="isNoParent || selectedParentElement">
    <div class="pes-Trigger">
      <LinkToParentButton
        v-if="isNoParent"
        :disabled="isElementClosed"
        :is-error="isError"
        data-auto-testid="link-to-parent-button"
      >
        <template v-if="isError">
          {{ t('objective.link_to_parent_okr') }}
          <AppFieldError show> ({{ t('okr_element.conflicts.required_parent') }})</AppFieldError>
        </template>
      </LinkToParentButton>

      <div v-else-if="selectedParentElement" class="pes-Trigger_Element">
        <OkrElementsListItem
          :allowed-depth="0"
          :id-as-link="parentElementReadable && isEdit"
          :objective="selectedParentElement"
          data-auto-testid="parent-element"
          rotate-toggle-sign
          show-interval
          show-only-grade-value
          type="secondary"
          @edit-element="emit('edit-element', $event)"
        >
          <template #actions>
            <div class="pes-Actions">
              <AppButton
                v-if="hasUnlinkAction && !disableOnEdit"
                v-tippy="{
                  content: t('action.unlink')
                }"
                :height="24"
                icon="unlink-from-parent"
                size="sm"
                type="subtle"
                @click.stop="emit('unlink-parent')"
              />

              <AppIcon height="24" icon-name="arrow-down-black" width="24" />
            </div>
          </template>
        </OkrElementsListItem>
      </div>
    </div>

    <!-- inline search isn't used, but needed to activate search-related features such as height alignment-->
    <AppSelect
      v-model="parentModel"
      :bottom-fixed-items="BOTTOM_FIXED_ITEMS"
      :disabled="disableOnEdit"
      :group-by="getGroupName"
      :item-icon="getParentElementIconName"
      :item-label="item => `${item.displayId} ${item.name}`"
      :loading="loading"
      :offset="[0, -44]"
      :options="options"
      :search-function="searchFunction"
      dropdown-search
      item-value="id"
      to-selector=".pes-Trigger"
      @update:options="emit('update:options', $event)"
      @update:model-value="emit('update:parent-id', $event)"
    >
      <template #option-label="{ option }">
        <ObjectiveSelectItem v-if="option" :objective="option" show-interval />
      </template>
      <template #bottom-fixed-items="{ bottomFixedItems, searchString }">
        <div v-for="item in bottomFixedItems" :key="item.id">
          <BottomFixedSelectItem v-if="!searchString" icon-name="info-next" no-hover>
            {{ item.text }}
          </BottomFixedSelectItem>
        </div>
      </template>
    </AppSelect>

    <div v-if="selectedParentElement" class="pes-ContributeWrapper">
      <AppCheckbox
        v-model="contributeModel"
        :disabled="isNoParent || disableOnEdit"
        data-auto-testid="contribute-switch"
        @update:model-value="emit('update:contribute', $event)"
      >
        {{ t('field.contribute.label') }}
      </AppCheckbox>
    </div>
  </template>

  <ImmediateParentLoader v-else :with-checkbox="isObjective" />
</template>

<script setup>
import { isNull, isUndefined } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { BOTTOM_INFO_PANEL } from '@/composables/bottom-fixed-items'
import { getObjectiveIconName, OKR_ELEMENT_PERMISSIONS, OKR_FORM_VIEWS } from '@/utils/objectives'
import { numberOrNullProp, objectOrNullProp } from '@/utils/prop-validators'

import AppFieldError from '@/components/form/AppFieldError'
import LinkToParentButton from '@/components/objectives/forms/LinkToParentButton'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrElementsListItem from '@/components/objectives/okr-elements-list/OkrElementsListItem'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import ImmediateParentLoader from '@/components/ui/SkeletonLoaders/ImmediateParentLoader'

defineOptions({
  name: 'ParentElementSelect',
  inheritAttrs: false
})

const props = defineProps({
  selectedParentElement: {
    required: true,
    validator: v => objectOrNullProp(v) || isUndefined(v)
  },

  isElementClosed: {
    type: Boolean
  },

  parentElementReadable: {
    type: Boolean
  },

  isEdit: {
    type: Boolean
  },

  disableOnEdit: {
    type: Boolean
  },

  loading: {
    type: Boolean
  },

  options: {
    type: Array,
    required: true
  },

  searchFunction: {
    type: Function,
    default: null
  },

  isError: {
    type: Boolean
  },

  formView: {
    type: Number,
    default: OKR_FORM_VIEWS.OBJECTIVE,
    validator: v => [OKR_FORM_VIEWS.OBJECTIVE, OKR_FORM_VIEWS.KR].includes(v)
  },

  hasUnlinkAction: {
    type: Boolean
  }
})

const emit = defineEmits([
  'edit-element',
  'unlink-parent',
  'update:options',
  'update:parent-id',
  'update:contribute'
])

const { t } = useI18n()

const BOTTOM_FIXED_ITEMS = [
  {
    id: 0,
    text: t('objectives.last_count_items_shown', {
      count: 10,
      entity: t('objectives.okr', 2)
    }),

    action: BOTTOM_INFO_PANEL
  }
]

const parentModel = defineModel('parentId', {
  required: true,
  validator: v => numberOrNullProp(v),
  set: value => value
})

const isNoParent = computed(() => isNull(parentModel.value))

const contributeModel = defineModel('contribute', {
  type: Boolean,
  required: true
})

const getParentElementIconName = element => {
  if (element && element.id === 0) {
    return null
  }

  return getObjectiveIconName({
    objective: { ...element, permissions: [OKR_ELEMENT_PERMISSIONS.READ] }
  })
}

const isObjective = computed(() => props.formView === OKR_FORM_VIEWS.OBJECTIVE)

const getGroupName = element => {
  if (element.id === 0) {
    return null
  }
  return `${element.workspaceKey} ${element.intervalName}`
}
</script>

<style lang="scss" scoped>
.pes-Trigger_Element {
  // border: 1px solid $azure-medium;
  border-radius: $border-radius-sm-next;
  overflow: hidden;
  --right-items-gap: 24px;
  cursor: pointer;
}

.pes-ContributeWrapper {
  padding-top: 10px;
  padding-left: 14px;

  &:deep(.ac-Text) {
    line-height: normal;
  }
}

.pes-Actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
