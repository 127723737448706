export default {
  "action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "action.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
  "action.revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销"])},
  "action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "action.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "action.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
  "action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "action.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "action.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "action.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
  "action.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
  "action.restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
  "create.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新目标"])},
  "create.objective.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加目标"])},
  "create.objective.btn.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
  "create.keyResult.label.originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
  "create.keyResult.label.currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前"])},
  "create.keyResult.label.targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "create.task.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "create.task.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功链接的 Jira Issue"])},
  "field.labels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
  "field.labels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加标签..."])},
  "field.assignee.stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干系人"])},
  "filed.assignee.stakeholders.plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加干系人"])},
  "field.group.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
  "field.group.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置群组..."])},
  "field.status.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "field.quoter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔"])},
  "field.quoter.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置间隔…"])},
  "field.parentObjective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父目标"])},
  "field.issue.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名称或完整 ID 搜索 Jira-Issue"])},
  "field.issue.jql_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入 JQL 查询"])},
  "field.select.nooptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有选项"])},
  "field.select.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["载入"])},
  "field.contribute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为父评分数做出贡献"])},
  "field.workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称应最少 2 个字符"])},
  "field.workspace_key_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key 长度：1-3 字符"])},
  "field.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填字段"])},
  "field.workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
  "fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填字段"])},
  "users.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "users.table_header_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "objectives.table_header_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
  "objectives.table_header_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
  "objectives.table_header_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有者"])},
  "objectives.table_header_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔"])},
  "objectives.table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "objectives.table_header_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "objectives.table_header_duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
  "objectives.table_header_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
  "objectives.unavailable_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权访问此目标。请联系您的 Jira 管理员。"])},
  "objectives.type_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
  "objectives.type_PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人的"])},
  "objectives.type_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
  "objective.btn.custom_weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义权重"])},
  "objective.btn.add_nested_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加嵌套目标"])},
  "objective.modal_title.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入目标名称"])},
  "objective.activity_log_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活动日志"])},
  "objective.copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["重复：", _interpolate(_named("name"))])},
  "objective.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
  "objective.a_moment_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刚才"])},
  "objective.hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时前"])},
  "objectives.days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天前"])},
  "objective.search_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过完整 ID 或名称搜索"])},
  "objective.no_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有拥有者"])},
  "objectives.due_date.end_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔结束"])},
  "objective.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "objective.sorting_switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子排序 (drag’n’drop is blocked)"])},
  "objective.btn.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有仅读权限"])},
  "objective.chart_table.split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过贡献项拆分"])},
  "objective.chart.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进展"])},
  "objective.chart.fullSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完整大小"])},
  "objective.chart.no_items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无所选项"])},
  "kr.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嵌套项"])},
  "kr.add_nested_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加嵌套项"])},
  "intervals.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开"])},
  "intervals.status.archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
  "intervals.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔名称"])},
  "intervals.new_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新时间间隔"])},
  "intervals.create_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建时间间隔"])},
  "intervals.edit_interval": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["编辑时间间隔 ", _interpolate(_named("intervalName"))])},
  "workspaces.new_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新工作区"])},
  "workspaces.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理工作区"])},
  "workspaces.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
  "workspaces.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建工作区"])},
  "workspaces.workspace_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区已创建。"])},
  "workspaces.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
  "workspace.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区名称"])},
  "workspace.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键码"])},
  "workspaces.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区名称"])},
  "workspaces.private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私有工作区"])},
  "workspaces.public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共工作区"])},
  "workspaces.visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可视性"])},
  "groups.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有群组匹配此搜索"])},
  "groups.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建群组"])},
  "groups.new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建群组"])},
  "groups.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除群组"])},
  "group.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组名称"])},
  "group.select_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择工作区"])},
  "users.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加用户"])},
  "users.change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改角色"])},
  "users.change_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改默认群组"])},
  "user.selected_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择的群组"])},
  "user.group.sync.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["无法选择该群组，因为它从关联的 ", _interpolate(_named("platform")), " 群组导入用户。"])},
  "create.admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加管理员"])},
  "admin.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "filter.manage_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理时间间隔"])},
  "filter.all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有群组"])},
  "filter.all_grade_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有状态"])},
  "filter.all_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有角色"])},
  "filter.all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有用户"])},
  "filter.all_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有拥有者"])},
  "filter.all_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有标签"])},
  "filter.all_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有干系人"])},
  "filter.expand_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部展开"])},
  "filter.collapse_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部收起"])},
  "filter.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将链接复制到剪贴板"])},
  "filter.no_objective_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个目标开始吧"])},
  "filter.this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
  "filter.this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
  "filter.custom_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义范围"])},
  "filter.show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示所有"])},
  "filter.due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
  "filter.all_are_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有目标都已筛选"])},
  "filter.show_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示它们"])},
  "search_criteria.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置搜索条件"])},
  "filter.sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按...排序"])},
  "filter.clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除所有"])},
  "filter.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有者"])},
  "filter.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
  "filter.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "filter.statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "filter.more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多筛选"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择"])},
  "dropdown.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
  "dropdown.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义权重"])},
  "dropdown.sync_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在同步"])},
  "dropdown.export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出 csv"])},
  "confirm.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接它"])},
  "confirm.dontLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不要链接"])},
  "confirm.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除目标"])},
  "confirm.objective.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除嵌套项"])},
  "confirm.user.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除用户吗？"])},
  "confirm.admin.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从管理员中移除 ", _interpolate(_named("userName"))])},
  "confirm.admin.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从管理员中移除 ", _interpolate(_named("userName")), " 吗？如果该用户至少添加到一个工作区，该用户将不会从插件中删除。"])},
  "delete_confirm_workspace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除工作区"])},
  "delete_confirm_interval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除时间间隔"])},
  "delete_confirm_interval_input_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["输入 ", _interpolate(_named("keyword")), " 以确认"])},
  "delete_confirm_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销 API 令牌"])},
  "delete_confirm_token_message_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要永久撤销您的API 令牌吗"])},
  "delete_confirm_token_message_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销 API 令牌是不可逆的"])},
  "users.roles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "menu.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "menu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
  "menu.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表板"])},
  "menu.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
  "menu.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持"])},
  "menu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员"])},
  "menu.integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集成"])},
  "menu.api_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 令牌"])},
  "menu.okr_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常规"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "menu.tooltip.collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收起"])},
  "menu.tooltip.expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开"])},
  "menu.tooltip.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "tour.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新目标"])},
  "tour.step1.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里您能够创建一个新目标。"])},
  "tour.step3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改评分"])},
  "tour.step4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加群组成员"])},
  "tour.step4.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加您的群组成员并管理群组。"])},
  "support.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持页面"])},
  "support.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们也欢迎对我们的插件、特性的想法和一般评论提出建议"])},
  "support.response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们尽量在 1 个工作日内回复所有需支持的请求。 Oboard 位于中欧，我们的官方"])},
  "support.support_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持时间为 GMT+1 时区的周一至周五上午 9 点至下午 5 点"])},
  "support.plugin_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的插件版本:"])},
  "support.security_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云安全程序"])},
  "breadcrumb.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
  "roles.not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择"])},
  "roles.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "roles.group_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组主管"])},
  "roles.workspace_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区管理"])},
  "roles.user_no_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户/不删除"])},
  "roles.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["读者"])},
  "dashboard.average_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均总数"])},
  "dashboard.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
  "dashboard.no_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有拥有者"])},
  "dashboard.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有群组"])},
  "dashboard.assigned_to_all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配给所有群组"])},
  "dashboard.assigned_to_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配给群组"])},
  "dashboard.across_all_the_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在所有群组中"])},
  "dashboard.in_this_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个工作区里"])},
  "dashboard.in_this_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个群组中"])},
  "dashboard.avg_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均进展"])},
  "dashboard.all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人员"])},
  "dashboard.interval_passed_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cyclePassedPercent")), "% 通过"])},
  "dashboard.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "dashboard.jira_issue_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira issues"])},
  "homepage.user_select.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队成员"])},
  "homepage.user_select.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司单位"])},
  "homepage.objectives.owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 的目标"])},
  "homepage.objectives.stakeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 是干系人"])},
  "homepage.objectives.watcher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 正在关注"])},
  "homepage.user_summary.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 群组"])},
  "homepage.group_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在团队中的人员"])},
  "homepage.okr_elements_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["对于 ", _interpolate(_named("role")), " 没有目标或关键结果"])},
  "homepage.role.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是拥有者"])},
  "homepage.role.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是干系人"])},
  "homepage.role.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是旁观者"])},
  "homepage.role.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组是一个拥有者"])},
  "homepage.notify.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不能找到所选择的 ", _interpolate(_named("role"))])},
  "homepage.notify.role.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "tokens.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"您可以使用 API 令牌来执行 OKR Board 应用程序的身份验证。您应该像对待任何其他密码一样安全地对待 API 令牌。", _interpolate(_named("break")), "您已使用了 ", _interpolate(_named("usedTokensCount")), " 个令牌，最多只能创建 10 个令牌。\""])},
  "tokens.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令牌标签"])},
  "tokens.no_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有令牌"])},
  "token.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个 API 令牌"])},
  "token.create.label_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个简短、容易记住的标签。"])},
  "token.minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟之前"])},
  "token.toast.revoke_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 API 令牌已经被撤销"])},
  "token.copy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的新的 API 令牌"])},
  "token.copy.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保您复制了您的新 API 令牌。 您将无法再次查看此令牌。"])},
  "pagination.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示"])},
  "pagination.of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的"])},
  "pagination.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有者"])},
  "pagination.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条目"])},
  "link_jira_issue.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想要链接所贡献的 Jira-Issue 到关键结果中。"])},
  "link_jira_issue.it_means": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意思是"])},
  "link_jira_issue.list_item_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分将根据 Jira-Issue 状态自动计算。"])},
  "link_jira_issue.list_item_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除非您取消所链接的问题或关闭贡献选项，否则您将无法手动更改评分。"])},
  "link_jira_issue.link_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还是要链接？"])},
  "link_jira_issue.jql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL"])},
  "link_jira_issue.jql_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效 JQL 请求"])},
  "link_jira_issue.jira_issues_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Jira-Issues 被发现。"])},
  "link_jira_issue.check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在新选项卡中检查结果。"])},
  "link_jira_issue.jql_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求超时"])},
  "interval.label.time_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间段"])},
  "interval.label.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期"])},
  "status.switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将根据进展和时间表更改状态"])},
  "grade_info.total_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总评分"])},
  "grade_info.filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选的项目"])},
  "custom_weights.contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贡献"])},
  "custom_weights.weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
  "custom_weights.impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影响"])},
  "saving_indicator.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存…"])},
  "saving_indicator.saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存"])},
  "levels.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id (1-3)"])},
  "levels.color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
  "levels.add_to_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到所有工作区中"])},
  "levels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图标"])},
  "debug.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调试页面"])},
  "debug.migrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移"])},
  "debug.migrate_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移任务"])},
  "debug.migrate_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Issues 迁移"])},
  "debug.migrate_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移属性"])},
  "debug.migrate_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务属性迁移"])},
  "debug.migrate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移时间间隔"])},
  "debug.migrate_intervals_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔迁移"])},
  "debug.confirm_migration_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定您想开始迁移吗？"])},
  "debug.start_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始迁移"])},
  "debug.clean_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清理"])},
  "debug.clean_up_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清理指向 Jira-Issues 的损坏链接"])},
  "debug.grade_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分等级计算"])},
  "debug.calculate_grades_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启动评分重新计算"])},
  "debug.calculate_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算评分"])},
  "debug.debug_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调试信息"])},
  "debug.download_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载调试信息"])},
  "debug.download_logs_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载日志"])},
  "debug.download_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
  "debug.grades_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分更新"])},
  "debug.restore_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复属性"])},
  "debug.restore_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复元素的属性"])},
  "debug.remove_duplicated_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除重复的时间间隔"])},
  "debug.remove_users_without_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除没有群组的用户"])},
  "debug.restore_interval_dates_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复元素的时间间隔日期"])},
  "debug.restore_users_and_groups_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复元素的用户和群组"])},
  "debug.restore_jira_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复 Jira 详细信息"])},
  "comments.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入 ", "@", " 以提及某人"])},
  "comments.edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已编辑"])},
  "banner.trial_subscribe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用版即将到期。请在 ", _interpolate(_named("licenseDaysLeft")), " 天内订阅，以继续使用 Jira 的 OKR Board。"])},
  "banner.license_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["许可证已过期。 请更新以继续使用完整的插件功能。"])},
  "description.normal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通文本"])},
  "description.heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 1"])},
  "description.heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 2"])},
  "description.heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 3"])},
  "description.heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 4"])},
  "description.heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 5"])},
  "description.heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题 6"])},
  "description.underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下划线"])},
  "description.strikethrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除线"])},
  "description.subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下标"])},
  "description.superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上标"])},
  "description.clear_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除格式"])},
  "description.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一个描述…"])},
  "table.no_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无项目"])},
  "settings.company.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司设置"])},
  "settings.company.homepage_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阻止查看其他人的进度"])},
  "settings.company.homepage_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["启用特性时。 对于普通用户，检查进展的选项将被禁用。 ", _interpolate(_named("break")), " 只有 WS 管理员和团队负责人才能检查进展。"])},
  "settings.company.alignment_settings_switch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许 ", _interpolate(_named("objective")), " 和 ", _interpolate(_named("keyResult")), " 链接到关键结果"])},
  "settings.company.default_states_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认状态"])},
  "settings.company.default_states_okr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启动特性后，嵌套目标会为其父级做出贡献。"])},
  "settings.company.default_states_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用特性后，嵌套的 Jira-Issues会影响其父级。"])},
  "labels.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签名称"])},
  "labels.no_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有标签"])},
  "labels.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除标签"])},
  "labels.delete_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从所有目标和关键结果中移除标签 ", _interpolate(_named("labelName")), " 吗?"])},
  "labels.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建标签"])},
  "labels.toast.create_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签已创建"])},
  "labels.new_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新标签"])},
  "jira.dashboard.okrs_displayed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-100的数字被允许"])},
  "jira.dashboard.hierarchy_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["层次结构视图"])},
  "jira.dashboard.access.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有通向工作区的访问权限。"])},
  "notifications.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "notifications.excel_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该报告已提交，并将很快下载。"])},
  "notifications.excel_export_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该报告正在准备中，准备就绪后将发送到您的电子邮箱中。"])},
  "notifications.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接已复制。"])},
  "notifications.plugin_version_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的插件版本已复制。"])},
  "notifications.administrator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员已添加。"])},
  "notifications.user_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户已删除"])},
  "notifications.role_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色已更改。"])},
  "notifications.comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论已删除"])},
  "notifications.comment_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销"])},
  "view_select.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对齐视图"])},
  "view_select.explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表浏览器"])},
  "datepicker.select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "datepicker.select_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期范围"])},
  "datepicker.next": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["接下来  ", _interpolate(_named("days")), " 天"])},
  "datepicker.previous": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["前 ", _interpolate(_named("days")), " 天"])},
  "date.custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义日期"])},
  "objectives.percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百分比"])},
  "objectives.binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二进制"])},
  "objectives.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
  "objective.columns_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列设置"])},
  "user.preferences.change_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "filter.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "filter.avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
  "filter.A_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Z"])},
  "filter.Z_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-A"])},
  "tour.got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知道了！"])},
  "status.switch_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动"])},
  "status.switch_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动"])},
  "status.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无"])},
  "stand_with_ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与乌克兰站在一起"])},
  "objectives.due_date.start_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间间隔开始"])},
  "objectives.period_mode.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周期模式"])},
  "confirm.interval_change_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["这会将 ", _interpolate(_named("dateProp")), " 值重置为新时间间隔的值。"])},
  "menu.synchronization_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步设置"])},
  "roadmap.views.weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
  "roadmap.views.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "roadmap.views.quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季度"])},
  "roadmap.updated_timeline_success_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateProp")), " 已成功更新。"])},
  "roadmap.start_and_due_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始和到期日期"])},
  "roadmap.backlog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路线图不适用于待办事项的时间间隔"])},
  "roadmap.invalid_dates_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期应小于到期日期"])},
  "settings.synchronization.general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常规设置"])},
  "settings.synchronization.save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存同步设置"])},
  "settings.synchronization.select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择项目"])},
  "settings.synchronization.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目"])},
  "settings.synchronization.select.start_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期字段"])},
  "settings.synchronization.select.start_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择开始日期字段"])},
  "settings.synchronization.select.end_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期字段"])},
  "settings.synchronization.select.end_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择结束日期字段"])},
  "view_select.roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路线图"])},
  "period_mode.auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动自下而上"])},
  "period_mode.manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动"])},
  "filters.update_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新筛选器"])},
  "filters.save_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将筛选器另存为..."])},
  "filters_saver.filter_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["筛选器：", _interpolate(_named("filterName")), " 已成功保存"])},
  "filters_saver.filter_renamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["筛选器：", _interpolate(_named("filterName")), " 已重命名成功"])},
  "filters_saver.filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["筛选器：", _interpolate(_named("filterName")), " 已成功更新"])},
  "filters.filter_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["筛选器：", _interpolate(_named("filterName")), " 已成功删除"])},
  "menu.show_other_views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示其他视图"])},
  "action.remove_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["移除 ", _interpolate(_named("entity"))])},
  "dashboard.no_objectives_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组中没有目标"])},
  "settings.synchronization.select_synchronisation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择同步类型"])},
  "settings.synchronization.delete_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除定制化设置"])},
  "settings.synchronization.sync_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步进行中…"])},
  "objectives.table_header_startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
  "objectives.table_header_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "objectives.table_header_currentTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前/目标"])},
  "objective.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成"])},
  "objective.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "objective.chart.daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
  "objective.chart.weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])},
  "objective.chart.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
  "objective.chart.quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每季度"])},
  "objective.chart.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "objective.chart.downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载 CSV"])},
  "workspace.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时区"])},
  "filter.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
  "tour.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "tour.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过浏览"])},
  "unlink_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消链接 Jira Issue"])},
  "roadmap.quarter_shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
  "levels.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "levels.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "banner.license_renew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的许可证即将到期。 请在 ", _interpolate(_named("licenseDaysLeft")), " 之前续订，以继续使用 Jira 的 OKR board。"])},
  "settings.synchronization.synchronization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["是否要删除 ", _interpolate(_named("projectName")), " 的自定义同步设置？"])},
  "settings.synchronization.not_synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未同步"])},
  "period_mode.auto.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["根据嵌套元素安排的开始和结束日期 ", _interpolate(_named("break"))])},
  "period_mode.manual.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动安排开始和结束日期"])},
  "validation.input_number.allowed_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许的数字、点、逗号"])},
  "validation.input_number.allowed_max_characters_before_dot": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["点前允许 ", _interpolate(_named("integerMaxLength")), " 个字符"])},
  "validation.input_number.allowed_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许 ", _interpolate(_named("integerMaxLength")), " 个字符"])},
  "validation.input_number.allowed_one_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许一个点"])},
  "validation.input_number.allowed_one_minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许一个负号"])},
  "validation.input_number.integer_begins_with_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整数以零开始"])},
  "group.group_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分组"])},
  "confluence.settings.api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 令牌"])},
  "confluence.token.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 Oboard 应用程序在您的 JIRA 实例上生成的 API 令牌。 请联系您的 Oboard Jira 管理员以获取 API 令牌。"])},
  "menu.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "menu.activity_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动日志"])},
  "comments.placeholder.leave_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论…"])},
  "confluence.permission_less.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权查看此页面"])},
  "confluence.permission_less.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您认为这不正确，请联系您的 Jira 或 Oboard 管理员。"])},
  "confluence.error_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出现问题了。"])},
  "confluence.error_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重新载入页面。"])},
  "action.proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "action.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动"])},
  "field.parentItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父项"])},
  "objective.move_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["移动 ", _interpolate(_named("displayId")), " 到另一个 WS"])},
  "kr.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动嵌套项。 仅从当前工作区。"])},
  "kr.move_from_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从所有工作区移动"])},
  "move.select_assignee_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们尚未在目标工作区中找到相应的拥有者，请将其映射出来。"])},
  "move.select_groups_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们尚未在目标工作区中找到相应的群组，请将其映射出来。"])},
  "debug.restore_task_chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复任务链"])},
  "support.btn_write_to_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写信给支持"])},
  "missing_page.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "text_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制到剪贴板"])},
  "in_app_notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
  "in_app_notifications.no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前您没有通知"])},
  "in_app_notifications.notification_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "在", _interpolate(_named("element")), " 上提到了您"])},
  "okr_elements.objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "action.add_column_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在右侧插入列"])},
  "action.add_column_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在前面插入列"])},
  "action.add_row_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在上方插入行"])},
  "action.add_row_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下方插入行"])},
  "action.delete_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除行"])},
  "action.delete_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除列"])},
  "action.merge_or_split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合并或拆分单元格"])},
  "action.table_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格选项"])},
  "action.delete_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除表格"])},
  "action.toggle_header_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换标题列"])},
  "action.toggle_header_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换标题行"])},
  "action.toggle_header_cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换标题单元格"])},
  "objective.select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择群组"])},
  "objective.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择拥有者"])},
  "move.success_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功移动"])},
  "move.no_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无父项"])},
  "confluence.okr_board_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board 连接当前"])},
  "in_app_notifications.button.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有未读通知"])},
  "action.add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加链接"])},
  "action.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
  "action.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码片段"])},
  "action.mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提到"])},
  "action.quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引用"])},
  "action.emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表情"])},
  "objectives.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["预测 ", _interpolate(_named("symbol"))])},
  "objectives.status_indicator.description.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("predicted")), " — 预测评分 (", _interpolate(_named("grade")), "/", _interpolate(_named("passed")), ")*100"])},
  "objectives.status_indicator.description.grade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("grade")), " -当前评分"])},
  "objectives.status_indicator.description.passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("passed")), " - 已过去的时间"])},
  "objective.btn.no_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有嵌套项"])},
  "link_jira_issue.linked_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接 Jira-Issue："])},
  "link_jira_issue.link_another_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接另一个 Jira-Issue："])},
  "status.switch_auto_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动状态"])},
  "debug.confirm_migration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移无法自动恢复，除非支持团队要求您执行此操作，否则请不要启动迁移。"])},
  "description.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置："])},
  "lock_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您没有编辑这个 ", _interpolate(_named("element")), " 的权限"])},
  "element.type.key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键结果"])},
  "element.type.jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Issue"])},
  "user.personal_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人设置"])},
  "user.personal_settings.dont_have_email_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们无权访问您的电子邮箱"])},
  "user.personal_settings.please_contact_our": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请联系我们的 ", _interpolate(_named("contact")), " 了解更多信息"])},
  "user.personal_settings.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "user.personal_settings.product_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将向您发送时事通讯，宣布 Oboard 中的重要产品更新。"])},
  "user.personal_settings.product_case_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有关协作的技巧、案例研究、教程和许多富有洞察力的文章。"])},
  "user.personal_settings.product_promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宣布促销活动和即将举行的活动以及网络研讨会。"])},
  "user.personal_settings.about_qty_per_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每个月约 ", _interpolate(_named("qty")), " 次"])},
  "user.personal_settings.about_qty_every_months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月约 ", _interpolate(_named("qty")), " 次"])},
  "menu.personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人设置"])},
  "debug.migrate_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移标签"])},
  "debug.migrate_labels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签迁移"])},
  "weights.objective.manual_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动模式。"])},
  "field.owner.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择拥有者..."])},
  "objective.move_objective_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要移动目标吗："])},
  "objective.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动嵌套项目"])},
  "move.copy_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制嵌套目标"])},
  "objective.clear_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除群组"])},
  "link_jira_issue.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Jira 中打开任务"])},
  "status.switch_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相等"])},
  "debug.upload_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
  "debug.i_understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我了解"])},
  "debug.instance_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实例迁移"])},
  "debug.export_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出至 .zip"])},
  "debug.import_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 .zip 导入"])},
  "debug.import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入错误"])},
  "debug.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警告"])},
  "debug.import_file_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档太大，请写信给我们 <a href=\"mailto:support", "@", "oboard.io\">support", "@", "oboard.io</a> 以增加限制。"])},
  "app.platform.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])},
  "action.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示更多"])},
  "action.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "action.add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加用户"])},
  "action.add_user_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["添加用户"]), _normalize(["添加多个用户"])])},
  "action.unlink_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消群组链接"])},
  "action.import_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入用户"])},
  "action.link_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["链接 ", _interpolate(_named("platform")), " 群组"])},
  "date.date_with_word_break": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " ", _interpolate(_named("break")), " — ", _interpolate(_named("end"))])},
  "create.objective.btn.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择目标"])},
  "field.interval_wrong_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键字错误"])},
  "users.all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])},
  "users.bulk_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量添加"])},
  "objectives.table_header_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
  "objectives.table_header_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干系人"])},
  "objective.sorting_drag_blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（不允许拖放）"])},
  "objective.chart.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全屏"])},
  "intervals.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到间隔"])},
  "workspaces.no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权创建新工作区。请联系您的管理员"])},
  "workspaces.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有工作区"])},
  "workspaces.no_workspaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个工作区开始吧"])},
  "workspaces.workspace_field_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " 更新成功"])},
  "workspace.select_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择时区"])},
  "workspaces.users_from_platform": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["来自已链接的 ", _interpolate(_named("platform")), " 群组的用户"])},
  "workspaces.linked_user_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法手动移除已链接的用户"])},
  "workspaces.delete_user_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从 ", _interpolate(_named("groupName")), " 群组中删除 ", _interpolate(_named("userName")), " 吗？"])},
  "workspaces.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区中的角色"])},
  "workspaces.intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["间隔"])},
  "workspaces.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到工作区"])},
  "workspaces.groups_tab.linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["链接的 ", _interpolate(_named("platform")), " 群组"])},
  "workspaces.groups_tab.linked_groups.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们从这些群组获取用户列表以添加到插件中。"])},
  "workspaces.table.cell.users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 用户"]), _normalize([_interpolate(_named("count")), " 个用户"])])},
  "workspaces.table.cell.groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个群组"]), _normalize([_interpolate(_named("count")), " 个群组"])])},
  "workspaces.table.cell.intervals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 间隔"]), _normalize([_interpolate(_named("count")), " 个间隔"])])},
  "workspace.change_role": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["更改用户的角色"]), _normalize(["更改 ", _interpolate(_named("qty")), " 个用户的角色"])])},
  "workspaces.no_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有群组"])},
  "workspaces.no_groups.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建群组以将人员添加到工作区"])},
  "workspaces.add_users_to_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将用户添加到 ", _interpolate(_named("group"))])},
  "workspaces.create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个群组"])},
  "workspaces.select_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择 ", _interpolate(_named("platform")), " 群组"])},
  "workspaces.select_linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择已链接的 ", _interpolate(_named("platform")), " 群组"])},
  "workspaces.manage_workspace.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理所有用户的工作区访问和角色"])},
  "groups.no_groups_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您在此工作区中没有群组。"])},
  "groups.unlink.keep_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留用户"])},
  "groups.unlink.keep_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["取消链接 ", _interpolate(_named("platform")), " 群组后，将用户保留在 ", _interpolate(_named("group")), " 中。"])},
  "groups.unlink.delete_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除用户"])},
  "groups.unlink.delete_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["取消链接 ", _interpolate(_named("platform")), " 群组后，从 ", _interpolate(_named("group")), " 中删除用户。"])},
  "groups.unlink.strategy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择选项"])},
  "groups.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名群组"])},
  "users.add_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加工作区"])},
  "users.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个用户"])},
  "users.import_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 Jira 群组导入用户一次。 导入的用户可以随时手动移除"])},
  "users.link_jira_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接 Jira 群组"])},
  "users.link_jira_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将 Jira 群组链接到 OKR Board。 对 Jira 群组的所有更改都会自动拉入 OKR Board 群组。 无法手动移除链接的用户"])},
  "users.select_jira_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择 Jira 群组"])},
  "users.select_import_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择导入选项"])},
  "users.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有任何用户与所应用的筛选器匹配。"])},
  "user_details.platform_profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("platform")), " 个人资料"])},
  "user_details.delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要从 ", _interpolate(_named("workspaceName")), " 中删除用户，请将其从相应的同步 ", _interpolate(_named("platform")), " 群组中移除。"])},
  "users.delete_user_from_plugin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要删除选定的用户，请将他们从相应的同步 ", _interpolate(_named("platform")), " 群组中移除。"])},
  "user_details.add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到工作区"])},
  "user_details.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到另一个工作区"])},
  "user_details.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该用户不在任何工作区中"])},
  "user_details.select_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择群组"])},
  "filter.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
  "filter.clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除选项"])},
  "filter.only_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅此"])},
  "filter.no_objective_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["尝试修改您的搜索条件或 ", _interpolate(_named("creating"))])},
  "filter.creating_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个新的目标。"])},
  "filter.no_objective_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个时间间隔内，您当前没有目标。"])},
  "filter.add_another_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个字段 "])},
  "filters.update_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将筛选器更新为"])},
  "filters.update_dashboard_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将仪表板更新为"])},
  "filters.save_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另存为..."])},
  "dropdown.link_new_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["链接新的 ", _interpolate(_named("platform")), " 群组"])},
  "dropdown.unlink_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["取消链接 ", _interpolate(_named("platform")), " 群组"])},
  "confirm.discard_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放弃"])},
  "delete_user.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["删除 ", _interpolate(_named("userName"))]), _normalize(["删除用户"])])},
  "delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从 ", _interpolate(_named("workspaceName")), " 工作区中删除 ", _interpolate(_named("userName")), " 吗？"])},
  "delete_user_from_app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从 ", _interpolate(_named("appName")), " 中删除 ", _interpolate(_named("userName")), " 吗？"])},
  "delete_confirm_issue_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要取消链接事务吗："])},
  "users.roles.total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("qty")), " 个角色"])},
  "users.add_users_to_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["将选定的用户添加到工作区"]), _normalize(["将 ", _interpolate(_named("count")), " 个选定用户添加到工作区"])])},
  "menu.workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组、用户和时间间隔"])},
  "support.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
  "support.support.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在这里创建支持请求，或通过 ", _interpolate(_named("emailAddress")), " 发送电子邮件给我们"])},
  "support.help_center.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在我们的 Confluence 帮助中心查找常见问题的答案。"])},
  "support.help_center.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问帮助中心"])},
  "confirm_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放弃更改？"])},
  "confirm_interval_change.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认间隔变更"])},
  "confirm_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可能有未保存的数据。 您确定要放弃所有未保存的更改吗？"])},
  "dashboard.dashboard_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["仪表板：", _interpolate(_named("dashboardName")), " 已成功删除"])},
  "dashboard.no_owners_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有与此搜索匹配的拥有者"])},
  "dashboard.menu.general_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用仪表板"])},
  "dashboard.menu.performance_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绩效报告"])},
  "dashboard.menu.custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义仪表板"])},
  "dashboard.across_all_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跨越所有群组"])},
  "dashboard.by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按群组"])},
  "dashboard.days_left_in_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周期剩余天数"])},
  "dashboard.linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已链接"])},
  "dashboard.in_this_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这个周期中"])},
  "dashboard.last_qty_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["过去 ", _interpolate(_named("qty")), " 天"])},
  "dashboard.enter_the_main_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入主要目标"])},
  "dashboard.access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝访问"])},
  "homepage.export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出*.pdf"])},
  "tokens.create_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建令牌"])},
  "tokens.token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
  "tokens.no_tokens_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个令牌开始吧"])},
  "token.copy.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api 令牌"])},
  "roadmap.export.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["某一部分的宽度："])},
  "roadmap.export.max_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图像最大宽度："])},
  "roadmap.export.max_width_item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["• ", _interpolate(_named("app")), " — ", _interpolate(_named("width")), " px"])},
  "roadmap.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
  "roadmap.export_option.visible.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可见区域"])},
  "roadmap.export_option.visible.hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅导出您在屏幕上看到的内容"])},
  "roadmap.export_option.full.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整个路线图"])},
  "roadmap.export_option.multiple.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出为多个图像"])},
  "roadmap.export.safari_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safari 浏览器不完全支持导出功能。请使用 Chrome 或 Firefox 浏览器。"])},
  "roadmap.export_beta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出路线图（beta）"])},
  "debug.migrate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迁移电子邮件"])},
  "debug.update_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新用户信息"])},
  "debug.migrate_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件迁移"])},
  "debug.update_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新用户信息"])},
  "settings.private_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人工作区"])},
  "settings.public_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共工作区"])},
  "labels.no_labels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个标签开始吧"])},
  "labels.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有找到标签"])},
  "view_select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表"])},
  "expired_license.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的 ", _interpolate(_named("platform")), " 许可证 ", _interpolate(_named("break")), " 的 OKR Board 不再有效"])},
  "expired_license.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买许可证"])},
  "expired_license.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看详细定价"])},
  "expired_license.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您不是 Jira 管理员，请请求他们启用访问权限"])},
  "missing_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哎呀，抱歉，我们找不到该页面"])},
  "missing_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要么出现问题，要么该页面不再存在。"])},
  "units.px": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["px"])},
  "figma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figma"])},
  "miro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miro"])},
  "badge.already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经添加"])},
  "badge.added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加"])},
  "badge.already_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经链接"])},
  "notification.contact_ws_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请联系您的工作区管理员。"])},
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard"])},
  "action.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开"])},
  "tree.fit_content_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适合内容大小"])},
  "tree.switch_to_detailed_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到详细卡片视图"])},
  "tree.switch_to_compact_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到简洁卡片视图"])},
  "tree.legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图例"])},
  "tree.legend.show_child_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示子项"])},
  "tree.legend.show_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示筛选的项"])},
  "tree.legend.hide_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏筛选的项"])},
  "tree.legend.not_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为父级评分不做出贡献"])},
  "okr_element.rename.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入新名称"])},
  "global_groups.add_sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加子群组"])},
  "global_groups.manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理用户"])},
  "global_groups.select_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择父级群组"])},
  "global_groups.add_selected_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["添加 ", _interpolate(_named("count")), " 选定的群组"]), _normalize(["添加 ", _interpolate(_named("count")), " 个选定的群组"])])},
  "global_groups.audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审核日志"])},
  "global_groups.unavailable_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权管理该群组"])},
  "global_groups.users_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加或删除用户可能会影响工作区"])},
  "global_groups.delete_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要删除 ", _interpolate(_named("groupName")), " 吗？ 群组将从所有目标和所有工作区中删除！"])},
  "global_groups.keep_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除群组后将用户保留在工作区中。"])},
  "global_groups.delete_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除用户和群组。"])},
  "global_groups.add_groups_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将群组添加到工作区"])},
  "add_users_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["用户将被添加到没有任何群组中"]), _normalize(["用户将被添加到没有任何群组中"])])},
  "global_groups.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从群组中移除"])},
  "global_groups.sub_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子群组"])},
  "action.claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索取"])},
  "action.remove_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 ws 中移除"])},
  "action.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到群组"])},
  "action.delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除用户"])},
  "action.edit_on_user_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑用户页面"])},
  "action.add_to_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到收藏夹"])},
  "action.remove_from_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从收藏夹中移除"])},
  "action.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
  "create.objective.success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标已创建"])},
  "create.objective.success_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果没有看到，请检查筛选器"])},
  "user.personal_settings.when_someone_reply_me": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["当有人 ", _interpolate(_named("mentions")), " 并 ", _interpolate(_named("replies")), " 您时"])},
  "user.personal_settings.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
  "user.personal_settings.mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", " 提及"])},
  "user.personal_settings.you_are_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是拥有者"])},
  "user.personal_settings.you_are_stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是干系人"])},
  "dropdown.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观众"])},
  "menu.whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "support.okr_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR 咨询"])},
  "support.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 Oboard 的 OKR 顾问培训和研讨会，提高您的 OKR 学习曲线。"])},
  "support.okr_review.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与 OKR 教练预约咨询"])},
  "support.okr_consulting.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约咨询"])},
  "release_updates.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开变更日志"])},
  "release_updates.footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欲了解更多更新，请查看:"])},
  "dashboard.layout_orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布局方向"])},
  "dashboard.horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水平"])},
  "dashboard.vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直"])},
  "dashboard.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加更多"])},
  "dashboard.created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["创建者：", _interpolate(_named("userName"))])},
  "dashboard.start_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始演示"])},
  "dashboard.stop_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停止演示"])},
  "dashboard.export_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出为 PDF"])},
  "dashboard.duplicate_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制仪表板"])},
  "dashboard.share_with_same_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与相同的人分享"])},
  "dashboard.share_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享仪表板"])},
  "dashboard.share.public.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["所有公共仪表板对所有用户都可见，并且可以复制。", _interpolate(_named("break")), _interpolate(_named("break")), "如果您想与特定人员共享或包含敏感数据，请将仪表板设为私有。"])},
  "dashboard.add_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加人员"])},
  "homepage.progress.switch.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标的进度"])},
  "homepage.objectives_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupName")), " 目标"])},
  "homepage.objective_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "auth.session_expired.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离您上次活动已经有一段时间了"])},
  "auth.session_expired.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重新加载页面以获得准确的数据。"])},
  "debug.rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权利"])},
  "debug.remove_okr_board_global_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除 OKR Board 全局权限"])},
  "debug.claim_admin-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索取管理员权限"])},
  "settings.learn_more_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解有关电子邮件通知的更多信息"])},
  "settings.get_email_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当以下情况发生时，获取 OKR 活动的电子邮件更新"])},
  "jira.auto_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动评分"])},
  "jira.manual_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动评分"])},
  "jira.auto_grade.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动评分基于 Jira issue 解决状态或其子任务的进度"])},
  "in_app_notifications.notification.status.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " 更改了状态"])},
  "in_app_notifications.notification.commented.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " 对 ", _interpolate(_named("element")), " 发表了评论"])},
  "in_app_notifications.notification.replied.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " 在一个事务中回复了您"])},
  "in_app_notifications.notification.updates.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["来自 ", _interpolate(_named("userName")), " 和其他人的 + ", _interpolate(_named("qty")), " 条更新"])},
  "in_app_notifications.notification.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示较少"])},
  "action.merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合并"])},
  "action.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从群组中移除"])},
  "update.keyResult.result_type_lock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分数无法编辑，因为它是基于嵌套项进行的计算。"])},
  "update.keyResult.result_type_lock_message_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果你想回到手动模式，", _interpolate(_named("action")), " 所有嵌套的项目。"])},
  "update.keyResult.result_type_lock_message_description_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭贡献"])},
  "field.name_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，此名称已被保留"])},
  "objectives.table_header_lastGradeUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后评分更新"])},
  "objectives.last_count_items_shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最后 ", _interpolate(_named("count")), " 个 ", _interpolate(_named("entity")), " 已显示。若要查看更多，请使用完整的 ID 或名称搜索。"])},
  "group.user_will_be_added_to_the_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户将被添加到工作区："])},
  "users.add_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workspace\n"])},
  "users.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将用户添加到群组"])},
  "users.add_to_group_userName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将 ", _interpolate(_named("userName")), " 添加到群组"])},
  "user.preferences.default_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认群组"])},
  "user.preferences.no_groups_for_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您在工作区中不属于任何群组"])},
  "filter.custom_range.between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["之间"])},
  "filter.custom_range.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自从"])},
  "filter.custom_range.before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 之前"])},
  "filter.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "filter.limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "filter.last_grade_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分更新"])},
  "menu.organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组和用户"])},
  "menu.organization.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全局群组和用户"])},
  "roles.suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暂停"])},
  "dashboard.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有自定义报告"])},
  "dashboard.no_report_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个自定义报告开始吧"])},
  "dashboard.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有任何报告符合应用的筛选条件。"])},
  "settings.company.default_user_role_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当手动添加用户时，可以在那里选择角色，如果需要，也可以随时更改。"])},
  "settings.company.ws_admins_manage_permission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["允许工作区管理员在 ", _interpolate(_list(0)), " 中管理用户和群组。"])},
  "settings.company.ws_admins_allowed_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])},
  "notifications.oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啊呀。。。"])},
  "notifications.lost_group_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["似乎您已失去对此群组的访问权限。请联系您的管理员。"])},
  "notifications.groups_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组已从工作区移除。"])},
  "global_groups.manage_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理群组"])},
  "global_groups.merge_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合并群组"])},
  "global_groups.parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父群组"])},
  "global_groups.merge_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请至少选择两个群组进行合并"])},
  "global_groups.unavailable_for_add_to_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须是所有该群组分配的工作区的管理员。请询问您的插件管理员授予相应权限。"])},
  "global_groups.merge_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合并群组会影响工作区"])},
  "global_groups.add_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加群组"])},
  "global_groups.will_be_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户也可能从工作区中被移除"])},
  "global_groups.without_parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有父群组"])},
  "global_groups.no_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此群组中尚无用户"])},
  "global_groups.no_users.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将用户添加到此群组，以便在各个工作区中使用"])},
  "global_groups.remove_from_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从工作区中移除群组"])},
  "global_groups.remove_from_workspace.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要从工作区中移除 ", _interpolate(_named("groupName")), " 吗？"])},
  "audit_log.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "action.add_to_group.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["用户已被添加 ", _interpolate(_named("break")), " 到与群组 ", _interpolate(_named("break")), " 相关联的所有工作区"])},
  "user_details.add_user.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["用户已被添加 ", _interpolate(_named("break")), " 到与群组 ", _interpolate(_named("break")), " 相关联的所有工作区"])},
  "action.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
  "action.updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
  "action.lets_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让我们开始吧"])},
  "action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "action.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
  "action.activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活"])},
  "action.inactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停用"])},
  "datepicker.without_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无日期"])},
  "weights.objective.cant_change_weight_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您无法更改 ", _interpolate(_named("status")), " 目标中的权重。重新打开目标以编辑自定义权重。"])},
  "objectives.table_header_gradeUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分更新成功"])},
  "objectives.table_header_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私"])},
  "objective.you_cant_make_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您不能将目标设为私有。\n它必须被分配或有一个干系人"])},
  "objective.make_okr_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让仅对所有者和利益相关者可见"])},
  "objective.make_okr_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让每个人都能可见"])},
  "objective.columns_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列"])},
  "objective.by_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按状态"])},
  "objective.obj_cant_move": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["无法使用嵌套元素移动目标，因为其中一个嵌套项为 ", _interpolate(_named("closedStatus")), " 或 ", _interpolate(_named("abandonedStatus"))])},
  "objective.cant_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无法移动目标"])},
  "objective.obj_cant_move_because_kr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["无法移动目标，因为其中一个嵌套项为 ", _interpolate(_named("closedStatus")), " 或 ", _interpolate(_named("abandonedStatus"))])},
  "group.table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格视图"])},
  "filter.clear_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除选项"])},
  "filter.more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多于"])},
  "filter.less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少于"])},
  "filter.at_least_comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["至少 ", _interpolate(_named("count")), " 个评论"])},
  "filter.never_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从未评论过"])},
  "filter.is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为空"])},
  "filter.is_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不为空"])},
  "filter.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
  "filter.last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后评论"])},
  "filter.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多筛选"])},
  "filters.custom_fields.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存的过滤器有一个不再可用的自定义字段。"])},
  "menu.custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至自定义字段"])},
  "menu.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "dashboard.reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新开放"])},
  "dashboard.add_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加自定义仪表板"])},
  "dashboard.choose_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择自定义仪表板"])},
  "homepage.progress.last_week_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上周更改"])},
  "tokens.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到 API 令牌的最大数量 (", _interpolate(_named("limit")), ")。"])},
  "link_jira_issue.import_count_jira_issues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我们将仅导入前 ", _interpolate(_named("count")), " 个 Jira-issues"])},
  "status.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待办事项"])},
  "comments.see_last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参见最后一条评论"])},
  "comments.add_a_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论"])},
  "comments.open_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开放评论"])},
  "comments.more_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个回复"]), _normalize([_interpolate(_named("count")), " 条回复"])])},
  "settings.company.adjust_thresholds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过拉动线上的点来调整代表状态的阈值。"])},
  "lock_message.grade_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("status")), " OKR 的评分未更新"])},
  "lock_message.entity_parent_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["父级 ", _interpolate(_named("parentOkr")), " 已 ", _interpolate(_named("statusName")), "，其评分将不会更新"])},
  "element.set_new_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将 ", _interpolate(_named("okr")), " 状态更改为 ", _interpolate(_named("status"))])},
  "custom_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义字段"])},
  "custom_fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必需的"])},
  "custom_fields.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用于"])},
  "custom_fields.content_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容区域"])},
  "custom_fields.right_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右侧面板"])},
  "custom_fields.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有自定义字段"])},
  "custom_fields.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个自定义字段"])},
  "custom_fields.create_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建自定义字段"])},
  "custom_fields.add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加自定义字段"])},
  "custom_fields.disable_create_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求您的管理员添加新的自定义字段。"])},
  "custom_fields.disable_create_button_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目前只能创建 ", _interpolate(_named("limit")), " 个自定义字段。"])},
  "custom_fields.single_line_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单行文本"])},
  "custom_fields.long_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长文本"])},
  "custom_fields.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
  "custom_fields.link.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示名称"])},
  "custom_fields.assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受让人"])},
  "custom_fields.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "custom_fields.single_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单选"])},
  "custom_fields.multi_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多选"])},
  "custom_fields.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
  "custom_fields.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱"])},
  "custom_fields.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选项"])},
  "custom_fields.options.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加选项"])},
  "custom_fields.options.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入选项名称"])},
  "custom_fields.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
  "custom_fields.currency.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入货币"])},
  "custom_fields.field_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字段类型"])},
  "custom_fields.field_type.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择字段类型"])},
  "custom_fields.field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字段名称"])},
  "custom_fields.field_name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入字段名称 "])},
  "custom_fields.add_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为…添加"])},
  "custom_fields.help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助文本"])},
  "custom_fields.help_text.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入帮助文本"])},
  "custom_fields.add_field_to_placement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将字段添加到 ", _interpolate(_named("placement"))])},
  "custom_fields.warning.filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段无法过滤或排序"])},
  "custom_fields.warning.not_editable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " 将来无法编辑"])},
  "custom_fields.warning.options.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅在创建新的选择字段时才可以配置选项。"])},
  "custom_fields.warning.options.create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["仅当创建新选择时才可以配置选项。", _interpolate(_named("break")), "以后无法编辑选项。"])},
  "custom_fields.notification.created_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["字段 ", _interpolate(_named("fieldName")), " ", _interpolate(_named("action")), " 已成功"])},
  "custom_fields.delete_field_confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要删除自定义字段 ", _interpolate(_named("fieldName")), " 吗？此字段的所有信息都将被删除！"])},
  "custom_fields.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除自定义字段"])},
  "custom_fields.field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["设置 ", _interpolate(_named("fieldName")), "…"])},
  "custom_fields.text_field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["添加", _interpolate(_named("fieldName")), "…"])},
  "onboarding.welcome.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["欢迎光临，", _interpolate(_named("userName"))])},
  "onboarding.welcome.rerun_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您被分配了管理员角色！"])},
  "onboarding.welcome.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们很高兴您能加入我们的应用。让我们一起开始我们的旅程吧。"])},
  "onboarding.welcome.rerun_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是一个高管角色，允许您管理 OKR 流程。您现在可以设置目标、配置周期和其他细节，管理用户权限和角色。"])},
  "onboarding.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅 Oboard 新闻通讯，获取重要产品更新、促销以及即将举行的活动和网络研讨会的公告。每月约 1 份，无垃圾邮件。"])},
  "onboarding.demo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见见我们的一位专家"])},
  "onboarding.demo.product_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品演示"])},
  "onboarding.demo.product_demo.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订免费演示课程并了解 OKR 在各个规模上的有效实施。"])},
  "onboarding.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回顾您的 OKR，并在最好的教练的指导下增强您的 OKR 学习。"])},
  "onboarding.tutorial.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观看视频指南"])},
  "onboarding.tutorial.admin_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员设置指南"])},
  "onboarding.tutorial.user_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户指南"])},
  "onboarding.admins.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加更多管理员"])},
  "onboarding.invite_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请 OKR Board 管理员"])},
  "onboarding.existed_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board 管理员\n"])},
  "onboarding.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步"])},
  "support.onboarding_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入职电话"])},
  "support.consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询"])},
  "support.video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频指南"])},
  "support.video_guides.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观看我们的指南，确保您充分利用我们的应用"])},
  "support.video_guides.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["播放视频"])},
  "support.onboarding_call.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过全面的产品支持开始您的第一个 90 天 OKR 周期。"])},
  "support.onboarding_call.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约电话"])},
  "support.okr_consulting.link.text.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订免费 OKR 评审"])},
  "support.define_your_okr.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估您组织的准备程度、文化和能力，以有效实施 OKR。"])},
  "support.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "action.change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改状态"])},
  "dropdown.restore_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复默认"])},
  "statuses.status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态名称"])},
  "statuses.progress_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进度状态"])},
  "statuses.close_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭状态"])},
  "statuses.close_statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭状态锁定对象并阻止编辑和更改评分。"])},
  "statuses.notifications.renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态重命名成功"])},
  "statuses.rename_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名状态"])},
  "app.platform.web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网页应用"])},
  "app.platform.both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两个平台"])},
  "action.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
  "action.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开"])},
  "action.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消链接"])},
  "action.create_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建仪表板"])},
  "action.grant_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授予网络访问权限"])},
  "action.revoke_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤销网络访问权限"])},
  "action.remove_from_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除管理员权限"])},
  "action.grant_web_access.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请注意，它将", _interpolate(_named("break")), "根据 网页应用程序许可证单独收取费用。"])},
  "action.select_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户"])},
  "action.select_user_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入电子邮件或选择用户"])},
  "action.update_license_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新许可证状态"])},
  "create.okr_element.create_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建另外一个"])},
  "weights.objective.cant_change_weight_by_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用于专业订阅计划或更高级别。"])},
  "weights.objective.cant_change_weight_by_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要使用自定义权重，您需要至少有 1 个嵌套元素。"])},
  "action.link_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接至 Jira-Issue"])},
  "field.not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未设置"])},
  "field.groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
  "field.period.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时期"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
  "action.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "common.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
  "common.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "users.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请用户"])},
  "user.preferences.user.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "user.preferences.user.email.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "admins.platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台"])},
  "filter.all_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有平台"])},
  "common.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "common.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
  "action.create_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建目标"])},
  "action.create_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 Jira-Issue"])},
  "action.unlink_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消链接事务"])},
  "action.paste_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粘贴链接"])},
  "delete_user.count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除 ", _interpolate(_named("count")), " 个用户"])},
  "confirm.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您确定要删除", _interpolate(_named("entity")), "吗？"])},
  "menu.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计费"])},
  "menu.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全性"])},
  "menu.branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌推广"])},
  "user_menu.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户账户"])},
  "user_menu.switch_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改组织"])},
  "user_menu.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "user_menu.create_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新组织"])},
  "roles.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员"])},
  "roles.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有者"])},
  "banner.join_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即加入！"])},
  "banner.okr_accelerator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领导者 OKR 加速器"])},
  "settings.billing.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计费"])},
  "notifications.role_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色更新成功。"])},
  "okr_elements.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
  "badge.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将"])},
  "global_groups.available_only_in_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅在Jira应用中可用"])},
  "onboarding.setup_name.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Onboard！"])},
  "organization.create_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置您的组织"])},
  "organization.invite_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请用户"])},
  "organization.invite_users.email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入一个或多个电子邮件地址，以逗号或空格分隔。"])},
  "organization.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织名称"])},
  "organization.name.updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织名称更新成功"])},
  "limitations.create_button.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["对于您的订阅，仅可以创建 ", _interpolate(_named("limit")), " 个 ", _interpolate(_named("entity")), "。"])},
  "limitations.entity.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到 ", _interpolate(_named("entity")), " 的最大数量 (", _interpolate(_named("limit")), ")。"])},
  "subscription.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
  "subscription.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前计划"])},
  "subscription.manage_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理计划"])},
  "subscription.manage_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理层级"])},
  "subscription.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门"])},
  "subscription.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
  "subscription.user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户层级"])},
  "subscription.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下次付款"])},
  "subscription.billing_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结算周期"])},
  "subscription.next_payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下次付款日期"])},
  "subscription.compare_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比较计划"])},
  "subscription.upgrade_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级层级"])},
  "subscription.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票历史记录"])},
  "subscription.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票"])},
  "subscription.receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收据"])},
  "subscription.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
  "subscription.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐单地址"])},
  "subscription.tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税务识别号"])},
  "subscription.try_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么不考虑我们的年度订阅计划呢？节省 15%！"])},
  "subscription.switch_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到年度"])},
  "subscription.switch_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到月度"])},
  "subscription.upgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级至年度"])},
  "subscription.upgrade_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级至月租"])},
  "subscription.downgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降级为年度"])},
  "subscription.your_current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您当前的计划"])},
  "subscription.your_current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您当前的层级"])},
  "subscription.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降级"])},
  "subscription.leave_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论如何取消"])},
  "subscription.apply_discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["应用 ", _interpolate(_named("percent")), " 折扣"])},
  "subscription.leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们很抱歉您要离开"])},
  "subscription.up_sale_promocode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请考虑", _interpolate(_named("discount")), "以继续与我们合作"])},
  "subscription.discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " 折扣"])},
  "subscription.subscription_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅已取消"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "subscription.tier_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户数量超出等级限制。升级您的等级或删除用户"])},
  "subscription.license_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["许可证未激活"])},
  "subscription.contact_your_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系您的经理"])},
  "subscription.switch_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换计划"])},
  "subscription.select_subscription_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个订阅周期"])},
  "subscription.per_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个用户"])},
  "subscription.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
  "subscription.current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前层级"])},
  "subscription.expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["截止日期"])},
  "subscription.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订阅"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级"])},
  "subscription.contact_oboard_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系 Oboard 团队"])},
  "subscription.schedule_free_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安排免费的演示会议，了解如何在不同规模上正确实施 OKR。"])},
  "subscription.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约会议"])},
  "subscription.drop_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留言"])},
  "subscription.your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的名字"])},
  "subscription.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业电子邮件"])},
  "subscription.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
  "subscription.company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司规模"])},
  "subscription.get_your_price_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取报价"])},
  "subscription.is_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始订阅"])},
  "subscription.choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择计划"])},
  "subscription.back_to_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回账单"])},
  "subscription.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "subscription.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
  "subscription.subscription_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅已成功更新"])},
  "subscription.days_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余天数"])},
  "subscription.subscription_free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费试用"])},
  "subscription.state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
  "subscription.total_pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["总计：", _interpolate(_named("value"))])},
  "subscription.applied_balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已应用余额 ", _interpolate(_named("value"))])},
  "subscription.amount_due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["今日应付总额 ", _interpolate(_named("value"))])},
  "subscription.from_to_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " 到 ", _interpolate(_named("to")), " 用户"])},
  "subscription.payment_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款未完成。请检查您的余额或更新付款方式。"])},
  "subscription.upgrade_your_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级您的等级"])},
  "subscription.upgrade_your_tier_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["用户数量超出等级限制。还剩 ", _interpolate(_named("day")), " 天可以升级您的等级或删除用户。"])},
  "subscription.save_count_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["节省 ", _interpolate(_named("count")), "%"])},
  "subscription.annually_save_count_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年节省 15%"])},
  "subscription.annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
  "subscription.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
  "subscription.tier_upper_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级上限不能低于当前用户数量。"])},
  "subscription.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期到:"])},
  "subscription.subscription_wasnt_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅未更新"])},
  "subscription.subscription_will_be_downgraded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["订阅将在周期结束时降级为 ", _interpolate(_named("name")), " ", _interpolate(_named("cycle")), "。"])},
  "subscription.number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户数"])},
  "subscription.bills_on_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 的账单为期 1 个月"])},
  "subscription.web_app_only_delete_from_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限 网页应用程序的用户将从 Oboard 中删除"])},
  "subscription.address_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 1"])},
  "subscription.address_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 2"])},
  "subscription.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "subscription.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
  "step_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第 ", _interpolate(_named("count")), " 步。"])},
  "connections.connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接成功 "])},
  "connections.connected_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与连接"])},
  "connections.disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开连接"])},
  "connections.connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接中"])},
  "connections.connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接中"])},
  "connections.disconnection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开连接中"])},
  "connections.disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在断开连接"])},
  "connections.lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接丢失"])},
  "connections.copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制令牌"])},
  "connections.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令牌已复制"])},
  "connections.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接状态"])},
  "connections.token_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粘贴集成令牌"])},
  "connections.paste_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粘贴令牌"])},
  "connections.paste_token_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从粘贴令牌"])},
  "connections.help.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要帮助吗?"])},
  "connections.guide.link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集成指南"])},
  "integration_settings.web_app.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取Jira应用令牌"])},
  "integration_settings.web_app.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您已经有令牌，请转到第2步。否则，请确保您拥有Jira帐户。打开Atlassian Marketplace。安装Jira的OKR Board插件。打开插件并转到设置/集成/网络应用程序。"])},
  "integration_settings.web_app.step2.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将Jira应用集成令牌粘贴到输入字段。按连接。连接需要一些时间。"])},
  "integration_settings.jira.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按下按钮复制积分令牌。令牌有效期为 30 分钟。令牌只能使用一次。"])},
  "integration_settings.jira.step2.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要建立连接，您需要有 网页应用 帐户。按下按钮打开 网页应用 页面。", _interpolate(_named("break")), "登录。选择目标帐户。导航至设置/集成/Jira应用。"])},
  "integration_settings.jira.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要断开连接，请按按钮打开网页应用程序并导航到相关帐户。"])},
  "integration_settings.connection_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["似乎 Jira应用已被卸载。恢复Jira应用或断开连接。"])},
  "disconnect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将导致以下更改"])},
  "disconnect_modal_events_list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 网页应用程序中删除所有仅限 Jira 的用户"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除两个应用程序之间的所有 Jira 组连接"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从所有 网页应用程序视图中删除 Jira 问题"])},
    
  ],
  "actions.chain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstAction")), " 和 ", _interpolate(_named("secondAction"))])},
  "app_name.jira_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira应用"])},
  "app_name.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira的OKR Board"])},
  "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用商店"])},
  "action.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
  "action.leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留下评论"])},
  "action.access_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问设置"])},
  "action.add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加付款方式"])},
  "action.add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
  "action.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过"])},
  "action.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个"])},
  "objectives.table_header_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贡献"])},
  "objectives.table_header_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "workspaces.edit_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑工作区"])},
  "users.add_to_workspace_without_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户将被添加到没有任何组中"])},
  "user_details.delete_user_from_synced_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要删除用户，请将他们从相应的同步 ", _interpolate(_named("platform")), " 组中移除。"])},
  "common.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始使用"])},
  "common.complete_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " 共 ", _interpolate(_named("end"))])},
  "support.instance_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的实例 ID："])},
  "support.help_center.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找常见问题的答案。"])},
  "dashboard.no_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表板需要至少一个打开的间隔"])},
  "dashboard.no_intervals_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建第一个间隔以开始\n"])},
  "debug.delete_deactivated_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除已停用的用户"])},
  "jira.dashboard.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对齐"])},
  "edit_later.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以稍后编辑"])},
  "onboarding.setup_name.input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入你的名字"])},
  "setup_workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置工作区"])},
  "setup_workspace.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以稍后重命名您的工作区并随时添加更多内容。"])},
  "setup_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置群组"])},
  "setup_groups.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["这是默认的组织结构。", _interpolate(_named("break")), " 您可以稍后在设置部分进行自定义。"])},
  "organization.create_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["组织名称仅对您的团队可见。", _interpolate(_named("break")), "您可以在将来更改它。"])},
  "subscription.select_user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户层级"])},
  "subscription.final_amount_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最终应付金额在结账时确定"])},
  "subscription.number_of_users_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以随时添加更多用户。您将仅在下一个订阅周期内自动为这些额外用户付费。"])},
  "subscription.select_user_tier_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以随时升级用户层级。如果您升级用户层级，您的账户将根据升级时剩余的账单周期百分比收取按比例的费用。"])},
  "subscription.up_to_count_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最多 ", _interpolate(_named("count")), " 位用户"])},
  "subscription.user_tier_billed_annually": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["用户层级", _interpolate(_named("label")), "，按年计费。"])},
  "subscription.per_user_billed_monthly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " 位用户，每位用户 $", _interpolate(_named("price")), "，按月计费"])},
  "subscription.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "subscription.next_bill_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个账单日期"])},
  "subscription.billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单日期"])},
  "connections.get_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取令牌"])},
  "disconnect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开网页应用与 Jira 应用的连接"])},
  "connect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将网页应用与 Jira 应用连接"])},
  "connect_modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网页应用将从Jira应用继承内部设置，例如状态、阈值和公司设置"])},
  "feedback.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助我们打造更好的产品"])},
  "feedback.modal.message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您认为插件中最具挑战性或可以改进的地方是什么？"])},
  "feedback.modal.rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您会如何评价我们?"])},
  "feedback.modal.review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将非常感激您对应用商店的评价。"])},
  "feedback.modal.review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这帮助我们更快成长，并提供更好的产品。"])}
}