<template>
  <div class="ofc-Loader">
    <svg
      fill="none"
      height="62"
      viewBox="0 0 236 62"
      width="236"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2476_690"
        height="62"
        maskUnits="userSpaceOnUse"
        style="mask-type: alpha"
        width="236"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="62" width="236" />
      </mask>
      <g mask="url(#mask0_2476_690)">
        <path
          d="M0 58C5.77143 57.0187 17.3143 54.9181 28.8571 53.0935C40.4 51.2689 46.1714 51.0631 57.7143 48.8768C69.2571 46.6906 75.0286 45.0541 86.5714 42.1622C98.1143 39.2704 103.886 39.2822 115.429 34.4177C126.971 29.5532 132.743 21.1104 144.286 17.8397C155.829 14.5689 161.6 20.4319 173.143 18.0639C184.686 15.696 196.229 8.41278 202 6"
          stroke="#444CF7"
          stroke-linecap="round"
          stroke-width="6"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
defineOptions({
  name: 'OkrElementFormChartLoader'
})
</script>

<style lang="scss" scoped>
.ofc-Loader {
  width: 100%;
  height: 62px;
  stroke-dasharray: 230;
  animation: draw-line 2s linear infinite;
}

@keyframes draw-line {
  0% {
    stroke-dashoffset: 230;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 230;
  }
}
</style>
