import { computed } from 'vue'
import { useStore } from 'vuex'

import { isStringEmpty } from '@/utils/general'
import { METRIC_UNIT_SUGGESTIONS, USER_SETTINGS_MAPPER } from '@/utils/user-settings'

export const MAX_UNIT_LENGTH = 3
const MAX_SUGGESTIONS_COUNT = 5

export const useUnitSuggestions = () => {
  const store = useStore()

  const suggestions = computed(() => {
    return store.getters['system/metricUnitSuggestions']
  })

  const saveUnitSuggestions = async ({ unit = '' } = {}) => {
    if (isStringEmpty(unit)) return

    const validatedUnit = unit.trim().toUpperCase().slice(0, MAX_UNIT_LENGTH)

    if (!validatedUnit) return

    if (suggestions.value.includes(validatedUnit)) return

    const newValue = [validatedUnit, ...suggestions.value].slice(0, MAX_SUGGESTIONS_COUNT)

    await store.dispatch('system/updateUserSettings', {
      [USER_SETTINGS_MAPPER[METRIC_UNIT_SUGGESTIONS]]: newValue
    })
  }

  return {
    suggestions,
    saveUnitSuggestions
  }
}
