<template>
  <div :class="classes">
    <div class="rts-Wrapper_Type">
      <LockMessage v-if="showLockMessage" class="rts-Wrapper_Message">
        <div>
          <slot name="lock-message">
            {{ $t('update.keyResult.result_type_lock_message') }}
            <slot name="lock-message-description" />
          </slot>
        </div>
      </LockMessage>
      <slot v-else name="left-side" />
    </div>
    <div class="rts-Container">
      <div class="rts-Container_Content">
        <div class="rts-Options_Wrapper">
          <slot name="right-side" />
        </div>
        <slot name="append-after-right-side" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { TYPES } from '@/utils/components-configurations/result-type-settings'

import { SETTINGS_COUNT } from '@/components/form/key-result/result-type-settings'
import LockMessage from '@/components/form/LockMessage'

defineOptions({
  name: 'ResultTypeSettings'
})

const props = defineProps({
  count: {
    type: Number,
    default: 1,
    validator: v => Number.isInteger(v) && Object.values(SETTINGS_COUNT).includes(v)
  },

  showLockMessage: {
    type: Boolean
  },

  type: {
    type: String,
    default: TYPES.DEFAULT
  }
})

const classes = computed(() => {
  return {
    'rts-Wrapper': true,
    [`rts-Wrapper-${props.type}`]: true
  }
})
</script>

<style lang="scss" scoped>
.rts-Wrapper {
  border-radius: $border-radius-md-next;
  box-shadow: inset 0 0 0 2px $grey-3-next, 0 6px 8px rgba($grey-2-next, 0.5),
    0 2px 2px $grey-2-next;
  position: relative;
  overflow: hidden;

  &-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.rts-Wrapper_Message {
  margin: 0 auto;
  padding: 4px 0;
}

.rts-Wrapper_Type {
  &:not(&:empty) {
    padding: 16px 20px;
  }
}

.rts-Options_Wrapper {
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(v-bind(count), 1fr);
  gap: inherit;
  width: 100%;

  .rts-Wrapper-centered & {
    text-align: left;
  }
}

.rts-Container_Content {
  display: flex;
  gap: 8px;

  .rts-Wrapper-centered & {
    max-width: 616px;
    margin-inline: auto;
  }
}

.rts-Container {
  padding: 18px 20px 20px;
  width: 100%;
  background-color: $grey-3-next;
}
</style>
