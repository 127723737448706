<template>
  <Modal
    :data-testid="$attrs['data-testid'] || null"
    :scrollable-content="false"
    :show="opened"
    :size="364"
    :style="{ '--modal-content-bottom-padding': modalContentBottomPadding }"
    :title="t('action.bulk_edit')"
    class="bem-Modal"
    manual-close
    @close="onClose"
  >
    <div class="bem-BulkEditWrapper">
      <FormItemsGroup no-border>
        <BulkEditCoreFieldsHub
          :fields="availableCoreFields"
          :model-value="modelValue.coreFieldsModelValue"
          :selected-element-type-ids="selectedElementTypeIds"
          :workspace-id="workspaceId"
          @update:model-value="onUpdateFieldModelValue"
          @toggle-value-dropdown="onToggleFieldValueDropdown"
        />

        <BulkEditCustomFieldsHub
          :fields="availableCustomFields"
          :model-value="modelValue.customFieldsModelValue"
          :workspace-id="workspaceId"
          @update:model-value="onUpdateFieldModelValue({ ...$event, isCustomField: true })"
          @toggle-value-dropdown="onToggleFieldValueDropdown({ ...$event, isCustomField: true })"
        />
      </FormItemsGroup>
    </div>

    <AppInfoMessage
      v-if="!isEmpty(disabledFields)"
      :data-auto-testid="BULK_EDIT_MODAL_TEST_IDS.DISABLED_FIELDS_LIST"
      :data-testid="BULK_EDIT_MODAL_TEST_IDS.DISABLED_FIELDS_LIST"
      :type="INFO_MESSAGE_TYPES.WARNING"
      class="bem-Message"
    >
      {{ t('bulk_update.disabled_fields_message') }}
      <ul class="bem-DisabledFieldsList">
        <li
          v-for="disabledField in disabledFields"
          :key="disabledField.fieldId"
          :data-tauto-estid="BULK_EDIT_MODAL_TEST_IDS.DISABLED_FIELDS_LIST_ITEM"
          :data-testid="BULK_EDIT_MODAL_TEST_IDS.DISABLED_FIELDS_LIST_ITEM"
          class="bem-DisabledFieldsList_Item"
        >
          <b>{{ disabledField.name }}</b>
          <template v-if="disabledField.isCustom"> ({{ t('common.custom') }})</template>
        </li>
      </ul>
    </AppInfoMessage>

    <template #modal-footer>
      <div class="bem-Footer">
        <AppButton
          :data-auto-testid="BULK_EDIT_MODAL_TEST_IDS.CANCEL_BUTTON"
          :data-testid="BULK_EDIT_MODAL_TEST_IDS.CANCEL_BUTTON"
          type="ghost-next"
          @click="onClose"
        >
          {{ $t('action.cancel') }}
        </AppButton>

        <AppButton
          :data-auto-testid="BULK_EDIT_MODAL_TEST_IDS.CONFIRM_BUTTON"
          :data-testid="BULK_EDIT_MODAL_TEST_IDS.CONFIRM_BUTTON"
          :disable="!areDataChanged"
          :loading="isLoading"
          @click="onConfirm"
        >
          {{ $t('action.confirm') }}
        </AppButton>
      </div>
    </template>
  </Modal>

  <portal to="modal-windows">
    <AppDialog
      :data-auto-testid="BULK_EDIT_MODAL_TEST_IDS.CONFIRM_CLOSE_MODAL"
      :data-testid="BULK_EDIT_MODAL_TEST_IDS.CONFIRM_CLOSE_MODAL"
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { isEmpty, isEqual, isNull, uniq } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import OkrElementsBulkUpdateApiHandler from '@/api/okr-bulk-update'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  CORE_FIELD_NAMES
} from '@/utils/okr-elements-table-bulk-actions'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDialog from '@/components/AppDialog'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import BulkEditCoreFieldsHub from '@/components/objectives/bulk-edit/BulkEditCoreFieldsHub'
import BulkEditCustomFieldsHub from '@/components/objectives/bulk-edit/BulkEditCustomFieldsHub'
import { BULK_EDIT_MODAL_TEST_IDS } from '@/components/objectives/bulk-edit/jest-helpers'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import Modal from '@/components/ui/Modal/Modal'

defineOptions({
  name: 'BulkEditModal'
})

const { t } = useI18n()

const props = defineProps({
  opened: {
    type: Boolean
  },

  fieldsForEdit: {
    required: true,
    validator: v => objectOrNullProp(v)
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  selectedElementIds: {
    type: Array,
    required: true
  },

  selectedElementTypeIds: {
    type: Array,
    required: true
  }
})

const emit = defineEmits({
  'on-close': null
})

const modelValue = ref({
  coreFieldsModelValue: {},
  customFieldsModelValue: {}
})

const createInitialModelValue = () => {
  const { coreFields, customFields } = props.fieldsForEdit

  const coreFieldsModelValue = coreFields
    .filter(({ disabled }) => !disabled)
    .reduce((acc, field) => {
      return {
        ...acc,
        [field.fieldId]: {
          ...field,
          operation: BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS,
          value: null,
          valueDropdownOpened: false
        }
      }
    }, {})

  const customFieldsModelValue = customFields
    .filter(({ disabled }) => !disabled)
    .reduce((acc, field) => {
      return {
        ...acc,
        [field.fieldId]: {
          ...field,
          operation: BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS,
          value: null,
          valueDropdownOpened: false
        }
      }
    }, {})

  return {
    coreFieldsModelValue,
    customFieldsModelValue
  }
}

const setupModelValue = () => {
  modelValue.value = {
    ...createInitialModelValue()
  }
}

watch(
  () => props.opened,
  newValue => {
    if (newValue) {
      setupModelValue()
    }
  }
)

const onClose = ({ reloadData = false, checkDataChange = true } = {}) => {
  if (!isLoading.value) {
    checkConfirmationAndCloseModal({ checkDataChange, reloadData })
  }
}

const isLoading = ref(false)

const createFieldsPayload = ({ fieldsModelValue = {} } = {}) => {
  return Object.values(fieldsModelValue).reduce((acc, field) => {
    const { fieldId, operation, value } = field
    const payload = {
      fieldId,
      operationId: operation
    }

    if (!Object.values(BULK_FIELD_OPERATION_TYPE_IDS).includes(operation)) {
      return acc
    }

    const isNoValue = isNull(value) || isEqual(value, [])

    if (
      [BULK_FIELD_OPERATION_TYPE_IDS.REPLACE, BULK_FIELD_OPERATION_TYPE_IDS.ADD].includes(
        operation
      ) &&
      isNoValue
    ) {
      return acc
    }

    if (operation === BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS) {
      return acc
    }

    if (operation === BULK_FIELD_OPERATION_TYPE_IDS.CLEAR) {
      return [...acc, payload]
    }

    return [
      ...acc,
      {
        ...payload,
        newValue: value
      }
    ]
  }, [])
}

const TRACKING_OPERATION_NAMES = {
  [BULK_FIELD_OPERATION_TYPE_IDS.REPLACE]: 'replace',
  [BULK_FIELD_OPERATION_TYPE_IDS.ADD]: 'add',
  [BULK_FIELD_OPERATION_TYPE_IDS.CLEAR]: 'clear'
}

const getFieldNameForTracking = ({ fieldId, isCustom = false } = {}) => {
  if (isCustom) return fieldId

  const coreFieldName = CORE_FIELD_NAMES[fieldId]
  return coreFieldName ? t(coreFieldName, 1, { locale: 'en' }) : 'unknown_field'
}

const createTrackingPayload = ({ payload = {} } = {}) => {
  const { coreFieldUpdates = [], customFieldUpdates = [] } = payload

  const fieldsPayload = [
    ...coreFieldUpdates,
    ...customFieldUpdates.map(fieldPayload => ({ ...fieldPayload, isCustom: true }))
  ]

  const uniqOperationIds = uniq(fieldsPayload.map(({ operationId }) => operationId))

  return uniqOperationIds.reduce((acc, uniqOperationId) => {
    const fields = fieldsPayload.filter(({ operationId }) => operationId === uniqOperationId)

    const key = TRACKING_OPERATION_NAMES[uniqOperationId] || 'unknown_operation'

    return {
      ...acc,
      [key]: fields.map(({ fieldId, isCustom }) => getFieldNameForTracking({ fieldId, isCustom }))
    }
  }, {})
}

const createNotificationPayload = ({ total = 0, success = 0 } = {}) => {
  const isFailed = success === 0

  const isFullySuccess = total === success

  const isPartialSuccess = success > 0 && success < total

  const [title] =
    [
      isFailed && t('bulk_update.notification.failed.title'),
      isFullySuccess && t('bulk_update.notification.success.title'),
      isPartialSuccess && t('bulk_update.notification.partial_success.title')
    ].filter(Boolean) || ''

  const [content] =
    [
      isFailed && t('bulk_update.notification.failed.description'),
      isFullySuccess && '',
      isPartialSuccess &&
        t('bulk_update.notification.partial_success.description', { success, total })
    ].filter(Boolean) || ''

  const [type] =
    [
      isFailed && NOTIFICATION_TYPES.ERROR,
      isFullySuccess && NOTIFICATION_TYPES.SUCCESS,
      isPartialSuccess && NOTIFICATION_TYPES.WARNING
    ].filter(Boolean) || NOTIFICATION_TYPES.SUCCESS

  return {
    type,
    title,
    content,
    expanded: true,
    withButtonClose: true
  }
}

const api = new OkrElementsBulkUpdateApiHandler()

const onConfirm = async () => {
  const coreFieldUpdates = createFieldsPayload({
    fieldsModelValue: modelValue.value.coreFieldsModelValue
  })

  const customFieldUpdates = createFieldsPayload({
    fieldsModelValue: modelValue.value.customFieldsModelValue
  })

  if (isEmpty(coreFieldUpdates) && isEmpty(customFieldUpdates)) {
    onClose({ checkDataChange: false })
    return
  }

  const payload = {
    workspaceId: Number(props.workspaceId),
    elementIds: props.selectedElementIds,
    coreFieldUpdates: createFieldsPayload({
      fieldsModelValue: modelValue.value.coreFieldsModelValue
    }),
    customFieldUpdates: createFieldsPayload({
      fieldsModelValue: modelValue.value.customFieldsModelValue
    })
  }

  try {
    isLoading.value = true
    const { success, total } = await api.bulkUpdateOkrElements(payload)
    isLoading.value = false

    if (success > 0) {
      onClose({ reloadData: true, checkDataChange: false })
    }
    tracker.logEvent('elements updated bulk', {
      category: EVENT_CATEGORIES.OKR_MANAGEMENT,
      value: props.selectedElementIds.length,
      ...createTrackingPayload({ payload })
    })

    showNotify({
      ...createNotificationPayload({ success, total })
    })
  } catch (error) {
    handleError({ error, title: t('bulk_update.notification.failed.title'), expanded: true })
  } finally {
    isLoading.value = false
  }
}

const normalizedFields = computed(() => {
  return {
    [OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS]: props.fieldsForEdit[
      OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS
    ].map(field => {
      return {
        ...field,
        name: t(CORE_FIELD_NAMES[field.fieldId]) || ''
      }
    }),
    [OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS]: props.fieldsForEdit[
      OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS
    ].map(field => {
      const { fieldName } = useCustomFieldsHelpers({ fieldId: field.fieldId })
      return {
        ...field,
        name: fieldName.value || '',
        isCustom: true
      }
    })
  }
})

const availableCoreFields = computed(() => {
  return normalizedFields.value[OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS].filter(({ disabled }) => {
    return !disabled
  })
})

const availableCustomFields = computed(() => {
  return normalizedFields.value[OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS].filter(
    ({ disabled }) => {
      return !disabled
    }
  )
})

const disabledFields = computed(() => {
  return [
    ...normalizedFields.value[OKR_BULK_UPDATE_ENTITY_KEYS.CORE_FIELDS],
    ...normalizedFields.value[OKR_BULK_UPDATE_ENTITY_KEYS.CUSTOM_FIELDS]
  ].filter(({ disabled }) => {
    return disabled
  })
})

const onUpdateFieldModelValue = ({ operation, value, fieldId, isCustomField = false } = {}) => {
  const targetFields = isCustomField
    ? modelValue.value.customFieldsModelValue
    : modelValue.value.coreFieldsModelValue

  if (targetFields[fieldId]) {
    targetFields[fieldId] = {
      ...targetFields[fieldId],
      operation,
      value: operation === BULK_FIELD_OPERATION_TYPE_IDS.KEEP_AS_IS ? null : value
    }
  }
}

const onToggleFieldValueDropdown = ({ value, fieldId, isCustomField = false } = {}) => {
  const targetFields = isCustomField
    ? modelValue.value.customFieldsModelValue
    : modelValue.value.coreFieldsModelValue

  if (targetFields[fieldId]) {
    targetFields[fieldId].valueDropdownOpened = value
  }
}

const modalContentBottomPadding = computed(() => {
  const DEFAULT_PADDING = '0px'

  if (isEmpty(modelValue.value) || !props.opened) {
    return DEFAULT_PADDING
  }

  const isSomeValueDropdownOpened = [
    ...Object.values(modelValue.value.coreFieldsModelValue),
    ...Object.values(modelValue.value.customFieldsModelValue)
  ].some(({ valueDropdownOpened }) => {
    return valueDropdownOpened
  })

  return isSomeValueDropdownOpened ? '220px' : DEFAULT_PADDING
})

const checkConfirmationAndCloseModal = ({ checkDataChange = true, reloadData = false } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close', { reloadData })
  }
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const isConfirmCloseShow = ref(false)

const areDataChanged = computed(() => {
  const snapshot = createInitialModelValue()
  return !isEqual(modelValue.value, snapshot)
})
</script>

<style lang="scss" scoped>
.bem-Modal {
  --modal-title-margin: 0px;
  --modal-header-padding: 32px #{$page-right-padding} 25px #{$page-left-padding};
  --modal-body-padding: 0 #{$page-right-padding} 20px #{$page-left-padding};
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;

  &:deep(.o-modal-content) {
    // I don't know reason why, but binding computed styles here broke code with hot reload in mutation observer
    //  at runtime-dom.esm-bundler.js
    // but it works with css-var bounded in template
    // padding-bottom: v-bind(modalContentBottomPadding);
    padding-bottom: var(--modal-content-bottom-padding, 0px);
    transition: $menu-transition;
  }
}

.bem-BulkEditWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid $grey-2-next;
  padding-top: 20px;
  --group-padding: 0px;
  --divider-width: 100%;
  --divider-offset: 20px;
}

.bem-Footer {
  display: flex;
  gap: 8px;
  padding: 20px #{$page-right-padding} 20px #{$page-left-padding};
  justify-content: flex-end;
  background: $white;
  box-shadow: inset 0 1px 0 0 $grey-2-next;
  position: sticky;
  bottom: 0;
}

.bem-Message {
  margin-top: 24px;
}

.bem-DisabledFieldsList {
  margin: 8px 0 0;
  list-style: none;
}

.bem-DisabledFieldsList_Item {
  position: relative;
  --line-height: 16px;
  --padding-left: 18px;
  line-height: var(--line-height);
  padding-left: var(--padding-left);

  &:before {
    content: '';
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: $dark-3;
    border-radius: 50%;
    left: calc(var(--padding-left) / 2);
    top: calc(var(--line-height) / 2);
    transform: translate(-50%, -50%);
  }
}
</style>
