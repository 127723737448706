<script setup>
import { isEmpty } from 'lodash'
import { useI18n } from 'vue-i18n'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ElementTypeProgressOptionsSelect'
})

defineProps({
  disabled: {
    type: Boolean
  },

  isError: {
    type: Boolean
  },

  options: {
    type: Array,
    default: () => []
  }
})

const { t } = useI18n()

const model = defineModel({
  type: Boolean,
  required: true,
  set: value => value
})

const emit = defineEmits({
  'remove-error': null
})
</script>

<template>
  <FormFieldNext
    :disabled="disabled"
    :label="t('levels.progress_options')"
    data-auto-testid="manage-level-progress-options"
  >
    <AppSelect
      v-model="model"
      :disabled="disabled"
      :dropdown-search="false"
      :is-error="isError"
      :offset="[0, 0]"
      :options="options"
      :type="SELECT_TYPES.MODERN"
      class="etp-Select"
      hide-selected-items-in-dropdown
      multi
      show-all-selected
      @open="emit('remove-error')"
    >
      <template #button-content="{ options: selectedOptions }">
        <span v-if="isEmpty(selectedOptions)" class="etp-SelectEmptyLabel">
          {{ t('groups.unlink.strategy_label') }}
        </span>
      </template>
    </AppSelect>

    <AppFieldError v-if="isError" :show="isError" class="lm-FieldError">
      {{ t('field.required') }}
    </AppFieldError>

    <AppInfoMessage v-else :type="INFO_MESSAGE_TYPES.NONE">
      {{ t('element_types.progress_options.description') }}
    </AppInfoMessage>
  </FormFieldNext>
</template>

<style lang="scss" scoped>
.etp-Select {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}

.etp-SelectEmptyLabel {
  padding-left: 2px;
}
</style>
