import { isEmpty, isUndefined } from 'lodash'

import ApiHandler from '@/api/base'
import { OKR_ELEMENT_ENTITY_KEYS } from '@/utils/entity-keys'
import { objectiveIsJiraTask } from '@/utils/objectives'
import { GRADE_MODES } from '@/utils/okr-element-values'
import { uid } from '@/utils/uid'

// request parameters for getObjectives and exportExcel(and other exports in future should be
// handled in the same way)
const handleObjectivesParameters = payload => {
  const result = { ...payload }
  // delete result.gradeTypes;
  if (result.searchString === '') {
    result.searchString = null
  }
  return result
}

export const SEARCH_TYPES = {
  OKR_EXPLORER: 1,
  ALIGNMENT: 2,
  HOMEPAGE: 3
}

const enrichOkrElement = okrElement => {
  return {
    ...okrElement,
    uniqueId: okrElement.id,
    uid: uid(),
    childElements: enrichOkrElementList(okrElement.childElements)
  }
}

const enrichOkrElementList = okrElements => {
  return okrElements.map(enrichOkrElement)
}

class ObjectivesApiHandler extends ApiHandler {
  async getObjectives(payload, cancelToken = undefined) {
    const { data } = await this.http.post('/getElements', handleObjectivesParameters(payload), {
      cancelToken
    })
    return enrichOkrElementList(data)
  }

  async getChildObjectives(payload) {
    const { data } = await this.http.post('/getNestedElements', {
      ...payload,
      searchString: payload.searchString ? payload.searchString : null // '' -> null
    })
    return {
      ...data,
      items: enrichOkrElementList(data.items)
    }
  }

  async getObjectiveById({ elementId, workspaceId } = {}) {
    const { data } = await this.http.get(`/elements/${elementId}`, {
      params: {
        workspaceId
      }
    })
    return enrichOkrElement(data)
  }

  async getLinkedTasks(payload) {
    const { data } = await this.http.post('/getLinkedTasks', payload)
    return data
  }

  async getParentObjectiveInfo({
    elementTypeId,
    elementId,
    parentId = null,
    searchString,
    workspaceId = null,
    intervalId,
    strict = false
  } = {}) {
    const { data } = await this.http.post('/getParentObjectiveInfo', {
      elementTypeId,
      elementId,
      // eslint-disable-next-line no-unneeded-ternary
      parentId: parentId ? parentId : null, // 0 -> null
      intervalId,
      searchString,
      workspaceId,
      strict
    })
    return data
  }

  async createOkrElement(payload) {
    const requestData = {
      // needed only for KR, set default value for other types
      originalValue: 0,
      currentValue: 0,
      targetValue: 0,
      fieldTypeId: 0,
      ...payload,
      [OKR_ELEMENT_ENTITY_KEYS.WORKSPACE_ID]: Number(payload[OKR_ELEMENT_ENTITY_KEYS.WORKSPACE_ID])
    }
    const { data } = await this.http.post('/elements', requestData)
    return {
      ...data,
      elements: data.elements.map(element => enrichOkrElement(element))
    }
  }

  async updateOkrElement({
    contribute,
    description,
    dueDate,
    elementStartDate,
    groups,
    jiraIssueId,
    intervalId,
    name,
    elementId,
    orderValue,
    users,
    parentId,
    levelId,
    workspaceId,
    labelIds,
    confidenceLevelId,
    startDateManual,
    dueDateManual,
    // needed only for KR, set default value for other types
    originalValue = undefined,
    currentValue = undefined,
    targetValue = undefined,
    fieldTypeId = undefined,
    grade = undefined,
    gradeMode = undefined,
    isPrivate = undefined,
    typeId,
    updateStatusForNestedElements = undefined,
    unit = undefined
  } = {}) {
    let payload = {
      contribute,
      description,
      dueDate,
      elementStartDate,
      groups,
      intervalId,
      name,
      elementId,
      orderValue,
      users,
      parentId,
      levelId,
      workspaceId,
      labelIds,
      confidenceLevelId,
      originalValue,
      currentValue,
      targetValue,
      fieldTypeId,
      grade,
      jiraIssueId,
      startDateManual,
      dueDateManual,
      gradeMode,
      private: isPrivate,
      updateStatusForNestedElements,
      unit
    }

    if (objectiveIsJiraTask({ typeId }) && gradeMode === GRADE_MODES.AUTO) {
      payload = {
        ...payload,
        grade: undefined,
        currentValue: undefined,
        originalValue: undefined,
        targetValue: undefined
      }
    }

    const { data } = await this.http.put('/elements', payload)
    return {
      ...data,
      element: enrichOkrElement(data.element)
    }
  }

  async getHistoryForObject({ objectId, objectTypeId } = {}) {
    const { data } = await this.http.post('/getHistoryForObject', {
      objectId,
      objectTypeId
    })
    return data
  }

  async copyObjective(payload) {
    const { data } = await this.http.post('/copyObjective', payload)
    return {
      ...data,
      element: enrichOkrElement(data.element)
    }
  }

  async removeOkrElement({ elementId, removeNestedObjectives = false }) {
    const { data } = await this.http.delete('/elements', {
      data: {
        elementId,
        removeNestedObjectives
      }
    })
    return data
  }

  async getExcelExport({
    expandAll = true,
    groupIds = null,
    intervalIds,
    itemsToOpen = null,
    ownerIds = null,
    searchString = null,
    searchType = 1,
    gradeTypes = null,
    typeIds = null,
    limit = 1000,
    workspaceId,
    order = null,
    childOrder = null,
    dueDateFrom = null,
    dueDateTo = null,
    startDateFrom = null,
    startDateTo = null,
    levelIds = null,
    maxOpenLevel = 0,
    offset = 0,
    stakeholderIds = null,
    labelIds = null,
    customFields = []
  } = {}) {
    const response = await this.http.post(
      '/getExcelReport',
      handleObjectivesParameters({
        expandAll,
        groupIds,
        intervalIds,
        itemsToOpen,
        ownerIds,
        searchString,
        searchType,
        gradeTypes,
        typeIds,
        limit,
        workspaceId,
        order,
        childOrder,
        dueDateFrom,
        dueDateTo,
        startDateFrom,
        startDateTo,
        levelIds,
        maxOpenLevel,
        offset,
        stakeholderIds,
        labelIds,
        customFields
      }),
      { responseType: 'blob', timeout: 0 }
    )
    return {
      data: response.data,
      filename: 'OKR Report.xlsx',
      filetype: response.headers['content-type']
    }
  }

  async getBreadcrumbs({ objectiveId, workspaceId, includeCurrentObjective = true }) {
    const { data } = await this.http.post('/getBreadcrumbs', {
      elementId: objectiveId,
      workspaceId,
      includeCurrentElement: includeCurrentObjective
    })
    return data
  }

  async getCsvExport({ workspaceId }) {
    const response = await this.http.post(
      '/exportElements',
      {
        workspaceId
      },
      { responseType: 'blob' }
    )
    return {
      data: response.data,
      filename: 'elements.csv',
      filetype: response.headers['content-type']
    }
  }

  async moveOkrElement({
    elementId,
    intervalId,
    moveCrossWorkspace,
    moveNested,
    parentId,
    workspaceId,
    conflicts = {}
  }) {
    const { data } = await this.http.post('/move', {
      elementId,
      intervalId,
      moveCrossWorkspace,
      moveNested,
      parentId,
      workspaceId,
      conflicts
    })
    return data
  }

  async checkNestedElementsPermission({ elementId, confidenceLevelId }) {
    const { data } = await this.http.get('/elements/status/permission', {
      params: {
        elementId,
        confidenceLevelId
      }
    })
    return data
  }

  async updateOkrElementCustomFieldValue({ elementId, fieldId, value }) {
    const { data } = await this.http.put(`/elements/${elementId}/customfields`, [
      {
        fieldId,
        value
      }
    ])

    const result = data.customFields[fieldId]

    // BE remove key from response if value is ARRAY Like and empty
    // so to keep FE works as before, we need to return empty array
    if (Array.isArray(value) && isEmpty(value) && isUndefined(result)) {
      return []
    }

    return result
  }

  async checkMovingAvailability({ elementId }) {
    const { data } = await this.http.get(`/move/check?elementId=${elementId}`)

    return data
  }
}

export default ObjectivesApiHandler
