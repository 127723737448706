<template>
  <FormFieldNext
    :id="`mui-${id}`"
    :disabled="isDisabled"
    :label="t('common.unit')"
    class="mui-Wrapper"
    @click="onWrapperClick"
  >
    <div>
      <AppInput
        v-model.uppercase.noNumbers="model"
        :max-length="MAX_UNIT_LENGTH"
        :readonly="isDisabled"
        blur-on-enter
        size="xlg"
        style-type="primary"
        @blur="debouncedOnBlur"
      />

      <template v-if="isShowSuggestions">
        <AppSelect
          v-if="editInitialised"
          v-model="model"
          :offset="[0, 0]"
          :options="suggestionsAsOptions"
          :to-selector="`#mui-${id}`"
          append-to="body"
          boundary="scrollParent"
          dropdown-width="154px"
          show-on-init
          @update:model-value="debouncedOnBlur"
        />
      </template>
    </div>
  </FormFieldNext>
</template>

<script setup>
import { debounce } from 'lodash'
import { computed, ref, useId } from 'vue'
import { useI18n } from 'vue-i18n'

import { MAX_UNIT_LENGTH, useUnitSuggestions } from '@/utils/metrics-unit'
import { getResultTypeSettingsDisabledStates } from '@/utils/okr-element-values'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'MetricUnitInput'
})

const props = defineProps({
  editable: {
    type: Boolean
  },

  isEdit: {
    type: Boolean
  },

  isLockedOnPercent: {
    type: Boolean
  },

  isBinarySelectDisabled: {
    type: Boolean
  }
})

const id = useId()

const model = defineModel({
  type: String,
  required: true,
  set: value => value.trim().slice(0, 3)
})

const { t } = useI18n()

const emit = defineEmits({
  blur: null
})

const isDisabled = computed(() => {
  const { input } = getResultTypeSettingsDisabledStates({
    isEdit: props.isEdit,
    editable: props.editable,
    isLockedOnPercent: props.isLockedOnPercent,
    isBinarySelectDisabled: props.isBinarySelectDisabled,
    valuesAreReadonly: false
  })

  return input
})

const { suggestions } = useUnitSuggestions()

const suggestionsAsOptions = computed(() => {
  return suggestions.value.map(suggestion => ({
    label: suggestion,
    value: suggestion
  }))
})

const editInitialised = ref(false)

const onBlur = () => {
  emit('blur')
}

// should be debounced with a small delay to prevent 2 requests at the same time
// when user first types an new value and then clicks on the suggestion
const debouncedOnBlur = debounce(onBlur, 200)

// we show suggestions dropdown only if we have at least 2 suggestions
const isShowSuggestions = computed(() => !isDisabled.value && suggestions.value.length > 1)

const onWrapperClick = () => {
  if (isShowSuggestions.value && !editInitialised.value) {
    editInitialised.value = true
  }
}
</script>

<style lang="scss" scoped>
.mui-Wrapper {
  max-width: 71px;
  --input-font-size: #{$fs-20};
  --input-font-weight: #{fw('semi-bold')};

  --readonly-background-color: #{$grey-2-next};
  --readonly-border-color: #{$grey-2-next};
  --readonly-color: #{$dark-3};
}
</style>
